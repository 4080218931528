import { Avatar } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { IconButton, Button } from '@mui/material';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import EditIcon from '@mui/icons-material/Edit';

const ExpandingAvatar = forwardRef((props, ref) => {
	const { image_thumb, image_url, width, height, onChange } = props;
	const [isExpanded, setIsExpanded] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);
	const [imageThumb, setImageThumb] = useState(image_thumb);
	const [imageUrl, setImageUrl] = useState(image_url);

	useImperativeHandle(ref, () => ({
		handleClose() {
			setIsExpanded(false);
			setIsZoomed(false);
		},
		ImageThumbPath(image_path) {
			setImageThumb(image_path);
			setImageUrl(image_path);
			// window.location.reload(false);
		}
	}));

	const handleClose = () => {
		setIsExpanded(false);
		setIsZoomed(false);
	};

	const handleOpen = () => {
		setIsExpanded(true);
	};
	const toggleZoom = () => {
		setIsZoomed(!isZoomed);
	};

	const boxStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		padding: '40px 8px 8px 8px',
		width: isZoomed ? '90vw' : '50vw',
		minHeight: '500px',
		textAlign: 'center',
		height: isZoomed ? '90vh' : '50vh',
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	};
	const imgStyle = {
		maxHeight: 'calc(100% - 54px)',
		objectFit: 'contain',
		flex: 1
	};
	return (
		<>
			<IconButton aria-label="image" onClick={handleOpen} sx={{ padding: 0, margin: 0 }}>
				<Avatar src={imageThumb} sx={{ width: width, height: height }} />
			</IconButton>
			{isExpanded && (
				<Modal open={true} onClose={handleClose}>
					<Box sx={boxStyle}>
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								position: 'absolute',
								right: 0,
								top: 0,
								color: (theme) => theme.palette.grey[500]
							}}>
							<CloseIcon />
						</IconButton>
						<img src={imageUrl} alt="photo" style={imgStyle} />

						<Box display="flex" justifyContent="center" sx={{ maxHeight: '54px' }}>
							<IconButton
								aria-label="zoom-in"
								onClick={toggleZoom}
								sx={{
									color: (theme) => theme.palette.grey[500]
								}}>
								{isZoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
							</IconButton>
							{onChange && (
								<>
									<input
										accept="image/*"
										style={{ display: 'none' }}
										id="raised-button-file"
										multiple
										type="file"
										onChange={onChange}
									/>
									<label htmlFor="raised-button-file">
										<Button
											variant="raised"
											component="span"
											sx={{
												color: (theme) => theme.palette.grey[700]
											}}>
											<EditIcon /> Change
										</Button>
									</label>
								</>
							)}
						</Box>
					</Box>
				</Modal>
			)}
		</>
	);
});
export default ExpandingAvatar;
