import React from 'react';
import ListGrid from '../../components/customDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import ExpandingAvatar from '../../components/ExpandingAvatar';
import { useLanguage } from '../../context/LanguageProvider';
import { getListPost } from '../../services/services';

const Providers = () => {
	const navigate = useNavigate();
	const { getLangString } = useLanguage();

	const { tokenData } = useAuth();

	const editUser = (params) => () => {
		console.log(' params: params.row', params);
		navigate('/EditProvider', { state: { params: params.row } });
	};

	const columns = [
		{ field: 'doctor_id', headerName: getLangString('id'), width: 40 },
		{ field: 'doctor_clinic_id', headerName: getLangString('clinic_id'), minWidth: 90 },
		{ field: 'doctor_user_id', headerName: getLangString('user_id'), minWidth: 90 },
		{
			field: 'image',
			headerName: getLangString('profile'),
			flex: 1,
			minWidth: 80,
			renderCell: (params) => (
				<ExpandingAvatar image_thumb={params.row.profile_pic} image_url={params.row.profile_pic} />
			)
		},
		{
			field: 'doctor_email',
			headerName: getLangString('email'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'doctor_fname',
			headerName: getLangString('first_name'),
			flex: 1,
			minWidth: 110
		},
		{
			field: 'doctor_mname',
			headerName: getLangString('middle_name'),
			flex: 1,
			minWidth: 110
		},
		{
			field: 'doctor_lname',
			headerName: getLangString('last_name'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'doctor_dob',
			headerName: getLangString('birthday'),
			flex: 1,
			minWidth: 130,
			valueGetter: (params) => {
				const dateParts = params.value.split('T')[0].split('-');
				const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
				return formattedDate;
			}
		},
		{
			field: 'doctor_gender',
			headerName: getLangString('gender'),
			flex: 1,
			minWidth: 90
		},
		{
			field: 'doctor_phone',
			headerName: getLangString('phone'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'doctor_department',
			headerName: getLangString('department'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'doctor_role',
			headerName: getLangString('role'),
			flex: 1,
			minWidth: 110
		},
		{
			field: 'doctor_contact_email',
			headerName: getLangString('contact_email'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'doctor_brief',
			headerName: getLangString('note'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'doctor_address',
			headerName: getLangString('address'),
			flex: 1,
			minWidth: 150
		},
		{
			field: 'actions',
			headerName: getLangString('action'),

			type: 'actions',
			minWidth: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<ModeEditOutlineOutlinedIcon />}
					label="Edit"
					onClick={editUser(params)}
				/>,
				<GridActionsCellItem icon={<DeleteIcon />} label="Delete" />
			]
		}
	];
	const rowID = (row) => row.doctor_id;
	const urlParams = { resource: 'user', action: 'getAllDoctors' };
	const createPage = {
		buttonText: `+ ${getLangString('add_new_provider')}`,
		createPagePath: '/NewProvider'
	};
	const columnVisibilityModel = {
		doctor_id: false,
		doctor_clinic_id: false,
		doctor_user_id: false,
		doctor_mname: false,
		doctor_dob: false,
		doctor_address: false,
		doctor_contact_email: false,
		doctor_email: false,
		doctor_phone: false,
		doctor_brief: false,
		doctor_gender: false
	};
	const page = 'providerList';
	return (
		<ListGrid
			rowID={rowID}
			columns={columns}
			urlParams={urlParams}
			createPage={createPage}
			columnVisibilityModel={columnVisibilityModel}
			pageName={page}
		/>
	);
};

export default Providers;
