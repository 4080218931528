import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import { Autocomplete, Box, Divider, Link } from '@mui/material';
import {
	Grid,
	Typography,
	TextField,
	Button,
	Stack,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	InputAdornment,
	CircularProgress,
	ListItemText
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper, MenuItem, Checkbox } from '@mui/material';
import { create, getListPost } from '../../services/services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { newMedicationColumns, temperatureUnits, dailyIntakeFrequency } from './constantArrays';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useAuth } from '../../context/AuthProvider';
import { IOSSwitch, IOSSwitchColored } from '../../components/IosSwitchButton';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { surgeryType, vitalSigns, wellBeing, woundInputs } from '../../helpers/constants';
import SurgeryTemplateDialog from '../../components/dailogs/SurgeryTemplateDialog';
import { useLanguage } from '../../context/LanguageProvider';
import { styled, lighten, darken } from '@mui/system';
import AddMedicationDialog from '../../components/dailogs/addMedicationDialog';
import VitalSignsDialog from '../../components/dailogs/kpisDialog';
import { post } from '../../services/services';
import KpisDialog from '../../components/dailogs/kpisDialog';
import AddCptCodeDailog from '../../components/dailogs/addCptCodeDailog';

const NewPatientSurgery = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { getLangString } = useLanguage();
	const data = location.state?.params;
	const alertRef = useRef(null);
	const templateRef = useRef(null);

	const { tokenData, profile } = useAuth();
	const isAdmin = tokenData?.role === 'admin';
	// const isOrgAdmin = profile?.profile?.access_level === 'orgAdmin';

	const [patient_clinic_id, set_patient_clinic_id] = useState('');
	const [procedure_patient_id, set_procedure_patient_id] = useState('');
	const [procedure_model_type, set_procedure_model_type] = useState('wound');
	const [procedure_code, set_procedure_code] = useState('');
	const [procedure_code_obj, set_procedure_code_obj] = useState(null);
	const [procedure_description, set_procedure_description] = useState('');

	const [procedure_date_time, set_procedure_date_time] = useState('');
	const [procedure_type, set_procedure_type] = useState('');
	const [procedure_is_active, set_procedure_is_active] = useState(true);
	const [procedure_doctors_roles, set_procedure_doctors_roles] = useState([]);

	// const [monitor_period_from, set_monitor_period_from] = useState('');
	// const [monitor_period_to, set_monitor_period_to] = useState('');

	const [all_cpt_codes, set_all_cpt_codes] = useState([]);

	/// KPIS
	const [open_kpis_dailog, set_open_kpis_dailog] = useState(false);
	const [kpi_type, set_kpi_type] = useState('');

	const [vital_signs, set_vital_signs] = useState(vitalSigns);

	const [check_vital_signs, set_check_vital_signs] = useState(true);
	const vitalSignsEnabled = Object.entries(vital_signs).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	// wound_inputs
	const [wound_inputs, set_wound_inputs] = useState(woundInputs);
	const [check_wound_inputs, set_check_wound_inputs] = useState(true);
	const woundInputsEnabled = Object.entries(wound_inputs).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	// well_being
	const [well_being, set_well_being] = useState(wellBeing);

	const [check_well_being, set_check_well_being] = useState(true);
	const wellBeingEnabled = Object.entries(well_being).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	///////////

	const [add_medication_id, set_add_medication_id] = useState('');
	const [selected_medication_option, set_selected_medication_option] = useState(null);
	const [add_medication_name, set_add_medication_name] = useState(null);
	const [add_medication_dose, set_add_medication_dose] = useState('');
	const [add_medication_daily_intake, set_add_medication_daily_intake] = useState('');
	const [add_medication_start_date, set_add_medication_start_date] = useState('');
	const [add_medication_end_date, set_add_medication_end_date] = useState('');

	const [medication_obj, set_medication_obj] = useState([]);
	const [medications_list, set_medications_list] = useState([]);

	const [loading, set_loading] = useState(true);
	const [main_surgeon, set_main_surgeon] = useState({});
	const [selected_staff_doctors_names, set_selected_staff_doctors_names] = useState([]);

	const [surgery_templates, set_surgery_templates] = useState([]);
	const [selected_surgery_template, set_selected_surgery_template] = useState({});
	const [surgery_template_name, set_surgery_template_name] = useState('');

	const [clinics_array, set_clinics_array] = useState([]);
	const [load_clinic_Array, set_load_clinic_Array] = useState(true);
	const [clinic_surgeons_list, set_clinic_surgeons_list] = useState([]);

	const [clinic_patients_list, set_clinic_patients_list] = useState([]);

	const [openMedicationDailog, setOpenMedicationDailog] = useState(false);
	const [openCptCodeDailog, setOpenCptCodeDailog] = useState(false);
	const [update_medications, set_update_medications] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const setSurgeryTemplateName = (params) => {
		set_surgery_template_name(params);
	};

	const resetState = () => {
		set_patient_clinic_id('');
		set_procedure_patient_id('');
		set_procedure_date_time('');
		set_procedure_type('');
		set_procedure_model_type('face');
		set_procedure_code('');
		set_procedure_code_obj(null);
		set_procedure_description('');
		set_procedure_doctors_roles([]);
		// set_monitor_period_from('');
		// set_monitor_period_to('');
		set_vital_signs(vitalSigns);
		set_wound_inputs(woundInputs);
		set_well_being(wellBeing);
		set_medication_obj([]);
		set_main_surgeon({});
		set_selected_staff_doctors_names([]);
		set_selected_surgery_template({});
	};

	const removeEnableAll = (obj) => {
		const { enable_all, ...rest } = obj;
		return rest;
	};

	const values = {
		patient_clinic_id,
		procedure_patient_id,
		procedure_date_time,
		procedure_type,
		procedure_doctors_roles,
		procedure_is_active: procedure_is_active ? 1 : 0,
		procedure_treatment_plan: {
			...Object.entries({
				...(check_vital_signs ? removeEnableAll(vital_signs) : null),
				...(check_wound_inputs ? removeEnableAll(wound_inputs) : null),
				...(check_well_being ? removeEnableAll(well_being) : null)
			})
				.filter(([key, value]) => value.enable === '1')
				.reduce((obj, [key, value]) => {
					obj[key] = value;
					return obj;
				}, {}),
			medication_obj
		},
		procedure_model_type,
		procedure_code,
		procedure_description,
		// for testing
		medications_list,
		surgery_template_name
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250
			}
		}
	};

	const updateKpi = (kpi_type, key, values) => {
		kpi_type === 'vital_signs'
			? set_vital_signs((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }))
			: kpi_type === 'wound_inputs'
			? set_wound_inputs((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }))
			: set_well_being((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }));
	};

	const setNewMedication = () => {
		if (
			add_medication_id === '' ||
			add_medication_name === '' ||
			add_medication_dose === '' ||
			add_medication_daily_intake === '' ||
			add_medication_start_date === '' ||
			add_medication_end_date === ''
		) {
			alertRef.current.handleClick({
				message: 'ALL MEDICATION FIELDS ARE REQUIRED',
				severity: 'error'
			});
			return;
		}
		let medication = {
			med_id: add_medication_id,
			medication_name: add_medication_name,
			medication_dose: add_medication_dose,
			ma_standard_frequency: add_medication_daily_intake,
			ma_start_date: add_medication_start_date,
			ma_end_date: add_medication_end_date.concat('T23:59:59')
		};
		set_medication_obj((oldArray) => [...oldArray, medication]);
		set_selected_medication_option(null);
		set_add_medication_id('');
		set_add_medication_name(null);
		set_add_medication_dose('');
		set_add_medication_daily_intake('');
		set_add_medication_start_date('');
		set_add_medication_end_date('');
	};

	const deleteUser = (params) => () => {
		set_medication_obj(
			_.filter(medication_obj, function (o) {
				return o.med_id !== params.id;
			})
		);
	};

	const editUser = (params) => () => {
		set_medication_obj(
			_.filter(medication_obj, function (o) {
				return o.med_id !== params.id;
			})
		);
		set_add_medication_id(params.row.med_id);
		set_add_medication_name(params.row.medication_name);
		set_add_medication_dose(params.row.medication_dose);
		set_add_medication_daily_intake(params.row.ma_standard_frequency);
		set_add_medication_start_date(params.row.ma_start_date);
		set_add_medication_end_date(params.row.ma_end_date.split('T')[0]);
	};

	const newMedicationColumns = [
		{ field: 'ma_id', headerName: getLangString('id'), width: 40 },
		{
			field: 'medication_name',
			headerName: getLangString('medication_name'),
			flex: 1,
			minWidth: 100
		},
		{ field: 'medication_dose', headerName: getLangString('dose'), width: 130 },
		{
			field: 'ma_standard_frequency',
			headerName: getLangString('standard_frequency'),
			type: 'number',
			flex: 1,
			minWidth: 50
		},
		{
			field: 'ma_start_date',
			headerName: getLangString('start_date'),
			type: 'date',
			flex: 1,
			minWidth: 100,
			valueGetter: (params) => {
				return params?.value?.split('T')[0];
			}
		},
		{
			field: 'ma_end_date',
			headerName: getLangString('end_date'),
			type: 'date',
			flex: 1,
			minWidth: 100,
			valueGetter: (params) => {
				return params?.value?.split('T')[0];
			}
		},
		{
			field: 'actions',
			headerName: getLangString('action'),
			type: 'actions',
			minWidth: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<ModeEditOutlineOutlinedIcon />}
					label="Edit"
					onClick={editUser(params)}
				/>,
				<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteUser(params)} />
			]
		}
	];

	const GroupHeader = styled('div')(({ theme }) => ({
		position: 'sticky',
		top: '-8px',
		padding: '4px 10px',
		fontWeight: 'bold',
		fontSize: '18px',
		backgroundColor:
			theme.palette.mode === 'light'
				? lighten(theme.palette.primary.light, 1)
				: darken(theme.palette.primary.main, 1)
	}));

	const GroupItems = styled('ul')({
		padding: 0
	});

	useEffect(() => {
		if (vitalSignsEnabled > 0) set_check_vital_signs(true);
		else set_check_vital_signs(false);
	}, [vitalSignsEnabled]);

	useEffect(() => {
		if (woundInputsEnabled > 0) set_check_wound_inputs(true);
		else set_check_wound_inputs(false);
	}, [woundInputsEnabled]);

	useEffect(() => {
		if (wellBeingEnabled > 0) set_check_well_being(true);
		else set_check_well_being(false);
	}, [wellBeingEnabled]);

	const fetchCPTCodes = async (CPTCodeId) => {
		const urlParams = { resource: 'procedure', action: 'getCPTcode' };
		let data = await post(urlParams);
		set_all_cpt_codes(data.data);
		if (CPTCodeId) {
			const selected = data.data.find((c) => c.id === CPTCodeId);
			console.log('selected', selected);
			set_procedure_code_obj(selected);
			set_procedure_code(selected?.cpt_code);
			set_procedure_description(selected?.cpt_code_description);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const urlParams1 = { resource: 'procedure', action: 'getmedications' };
			const data1 = await post(urlParams1);
			set_medications_list(data1.data);
			set_update_medications(false);

			const urlParams2 = { resource: 'clinic', action: 'getclinics' };
			let data2 = await post(urlParams2);
			set_clinics_array(data2.data);

			await fetchCPTCodes();

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, [update_medications]);

	useEffect(() => {
		if (_.some(['lip', 'cleft'], (el) => _.includes(procedure_description.toLowerCase(), el)))
			set_procedure_model_type('face');
		else set_procedure_model_type('wound');
	}, [procedure_description]);

	useEffect(() => {
		const fetchData = async () => {
			set_surgery_templates([]);
			const urlParams = { resource: 'procedure', action: 'GetAllTemplates' };
			let data = await getListPost(urlParams, { clinic_id: patient_clinic_id });
			set_surgery_templates(data.data);
		};
		fetchData().catch(console.error);
	}, [patient_clinic_id]);

	useEffect(() => {
		if (selected_surgery_template.template_configuration) {
			const data = JSON.parse(selected_surgery_template?.template_configuration);
			if (data.procedure_treatment_plan.vital_signs)
				set_vital_signs(data.procedure_treatment_plan.vital_signs);
			if (data.procedure_treatment_plan.wound_inputs)
				set_wound_inputs(data.procedure_treatment_plan.wound_inputs);
			if (data.procedure_treatment_plan.well_being)
				set_well_being(data.procedure_treatment_plan.well_being);
			set_medication_obj(data.procedure_treatment_plan.medication_obj);
			set_procedure_type(data.procedure_type);
			set_procedure_model_type(data.procedure_model_type);
			set_procedure_code_obj(data.procedure_code_obj);
			set_procedure_code(data.procedure_code);
			set_procedure_description(data.procedure_description);
		}
	}, [selected_surgery_template]);

	useEffect(() => {
		if (data) {
			set_patient_clinic_id(data.clinic_id);
			set_procedure_date_time(data?.performedDateTime?.split('T')[0] || '');
		}
	}, [patient_clinic_id]);

	useEffect(() => {
		if (data) set_procedure_patient_id(data?.patient_id || '');
	}, [clinic_patients_list]);

	useEffect(() => {
		if (!isAdmin) {
			set_patient_clinic_id(tokenData.clinic_id);
			getPatientsAndDoctorsByClinic(tokenData.clinic_id);
		}
	}, []);

	useEffect(() => {
		if (isAdmin) getPatientsAndDoctorsByClinic(patient_clinic_id);
	}, [patient_clinic_id]);

	const getPatientsAndDoctorsByClinic = async (clinic_id) => {
		const urlDoctorParams = { resource: 'user', action: 'getDoctorsByClinic' };
		const doctorData = await getListPost(urlDoctorParams, { clinic_id });
		doctorData.data.sort((a, b) => a.doctor_fullName.localeCompare(b.doctor_fullName));
		set_clinic_surgeons_list(doctorData.data);

		const urlPatientParams = { resource: 'user', action: 'getPatientsInfoByClinic' };
		const patientData = await getListPost(urlPatientParams, { clinic_id });
		// console.log('patientData.data', patientData.data);
		set_clinic_patients_list(patientData.data);
		set_load_clinic_Array(false);
	};

	const checkMedicationDates = () => {
		medication_obj.map((element, index) => {
			if (element.ma_start_date === '' || element.ma_end_date === '') {
				throw new Error(`${element.medication_name} medication dates are not set`.toUpperCase());
			}
		});
		if (
			wound_inputs['image_url'] &&
			wound_inputs['image_url'].enable === '1' &&
			(wound_inputs['image_url'].start_date === '' || wound_inputs['image_url'].end_date === '')
		) {
			throw new Error(`Set Dates for 'WOUND PHOTO' `.toUpperCase());
		}
		return true;
	};

	useEffect(() => {
		let staffDoctors = selected_staff_doctors_names.map((staffDoctor) => ({
			doctor_id: staffDoctor.doctor_id,
			interaction_type: 'staff'
		}));
	
		// Combine the main surgeon and the supporting staff doctors
		set_procedure_doctors_roles(_.concat(main_surgeon, staffDoctors));
	}, [main_surgeon, selected_staff_doctors_names]);

	const onSubmitSurgery = async () => {
		try {
			checkMedicationDates();
			const urlParams = { resource: 'procedure', action: 'createProcedure' };
			const response = await create(urlParams, values);
			console.log('values', values);
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
				resetState();
			}
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const OnSubmitSurgeryTemplate = async () => {
		try {
			const urlParams = { resource: 'procedure', action: 'AddProcedureTemplate' };
			const response = await create(urlParams, {
				clinic_id: patient_clinic_id,
				template_name: surgery_template_name,
				template_configuration: {
					procedure_type,
					procedure_code_obj,
					procedure_code,
					procedure_description,
					procedure_doctors_roles,
					procedure_model_type,
					procedure_treatment_plan: {
						...(check_vital_signs ? { vital_signs } : null),
						...(check_wound_inputs ? { wound_inputs } : null),
						...(check_well_being ? { well_being } : null),
						medication_obj: medication_obj.map((element, index) => {
							element.ma_start_date = '';
							element.ma_end_date = '';
							return element;
						})
					}
				},
				created_by: isAdmin ? '0' : `${profile?.profile?.profile_id}`
			});
			if (response.status === 200 || response.status === 201)
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
			// resetState();
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const selectionRange = {
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection'
	};

	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} />
			<SurgeryTemplateDialog
				ref={templateRef}
				template_name={surgery_template_name}
				set_template_name={setSurgeryTemplateName}
				submit={OnSubmitSurgeryTemplate}
			/>
			<AddMedicationDialog
				open={openMedicationDailog}
				setOpen={setOpenMedicationDailog}
				updateMedications={set_update_medications}
			/>
			<AddCptCodeDailog
				open={openCptCodeDailog}
				setOpen={setOpenCptCodeDailog}
				fetchCPTCodes={fetchCPTCodes}
			/>
			<KpisDialog
				open={open_kpis_dailog}
				setOpen={set_open_kpis_dailog}
				kpiType={kpi_type}
				kpis={
					kpi_type === 'vital_signs'
						? vital_signs
						: kpi_type === 'wound_inputs'
						? wound_inputs
						: well_being
				}
				setKpis={
					kpi_type === 'vital_signs'
						? set_vital_signs
						: kpi_type === 'wound_inputs'
						? set_wound_inputs
						: set_well_being
				}
				updateKpis={updateKpi}
			/>
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 4 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Stack
						direction="row"
						spacing={1}
						sx={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start'
							}}>
							<Toolbar>
								<Typography variant="h6" align="left" color="#1db14c" sx={{}}>
									{getLangString('patient_surgery_setup')}
								</Typography>
							</Toolbar>
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								pr: 2
							}}>
							{/* <Stack spacing={1} direction="row" alignItems="center" sx={{ flexShrink: 0 }}>
								<Typography sx={{ fontSize: 14 }}>Deactivate</Typography>
								<FormControlLabel
									control={
										<IOSSwitchColored
											checked={procedure_is_active}
											onChange={(event) => {
												set_procedure_is_active(event.target.checked);
											}}
											inputProps={{ 'aria-label': 'ant design' }}
										/>
									}
								/>
								<Typography sx={{ fontSize: 14 }}>Activate</Typography>
							</Stack> */}
						</Box>
					</Stack>
				</Paper>
			</Container>
			{/* <form onSubmit={handleSubmit(onSubmitSurgery)}> */}
			<FormControl>
				<Container component="main" maxWidth="xl">
					<Paper
						variant="outlined"
						sx={{
							borderColor: '#1db14c',
							borderRadius: 2,
							boxShadow: 2,
							my: { xs: 3, md: 2 },
							p: { xs: 2, md: 3 }
						}}>
						<React.Fragment>
							<Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
								<Typography variant="h6" gutterBottom>
									{getLangString('surgery_details')}
								</Typography>

								{patient_clinic_id !== '' && (
									<Stack direction="row" justifyContent="flex-end" spacing={1}>
										<FormControl sx={{ minWidth: 120 }} size="small">
											<InputLabel id="demo-simple-select-helper-label">
												{getLangString('templates')}
											</InputLabel>
											<Select
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper"
												value={selected_surgery_template?.id || ''}
												label={getLangString('templates')}
												onChange={(e) => {
													set_selected_surgery_template(
														_.filter(surgery_templates, function (o) {
															return o.id === e.target.value;
														})[0]
													);
												}}>
												{surgery_templates.map((option) => (
													<MenuItem key={option.id} value={option.id}>
														{option.template_name}
													</MenuItem>
												))}
											</Select>
										</FormControl>

										<Button
											variant="contained"
											color="success"
											type="submit"
											onClick={() => {
												templateRef.current.handleClickOpen({});
											}}>
											{getLangString('save_template')}
										</Button>
									</Stack>
								)}
							</Stack>
							<Grid container spacing={3}>
								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											select
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											value={patient_clinic_id}
											fullWidth
											required
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												if (!isAdmin) return;
												set_load_clinic_Array(true);
												set_patient_clinic_id(e.target.value);
												set_main_surgeon({});
												set_selected_staff_doctors_names([]);
											}}>
											{clinics_array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}

								<Grid item xs={12} sm={4}>
									<TextField
										select
										id="mainSurgeon"
										name="mainSurgeon"
										label={getLangString('surgeon')}
										value={main_surgeon.doctor_id || ''}
										fullWidth
										required
										variant="outlined"
										disabled={load_clinic_Array}
										onChange={(e) => {
											set_main_surgeon({
												doctor_id: e.target.value,
												interaction_type: 'main'
											});
											set_selected_staff_doctors_names([]);
										}}>
										{clinic_surgeons_list.map((option) => (
											<MenuItem key={option.doctor_id} value={option.doctor_id}>
												{`${option.doctor_fullName} (${option.doctor_id})`}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								<Grid item xs={12} sm={4}>
									<FormControl fullWidth required>
										<InputLabel id="demo-multiple-checkbox-label">
											{getLangString('supporting_staff')}
										</InputLabel>
										<Select
											id="staffSurgeons"
											name="staffSurgeons"
											label={getLangString('supporting_staff')}
											variant="outlined"
											multiple
											fullWidth
											value={selected_staff_doctors_names.map((doc) => doc.doctor_id)} // Store the selected doctor_ids
											onChange={(e) => {
												const {
													target: { value }
												} = e;
												// Find the full doctor objects by their doctor_id
												const selectedDoctors = clinic_surgeons_list.filter((doctor) =>
													value.includes(doctor.doctor_id)
												);
												set_selected_staff_doctors_names(selectedDoctors);
											}}
											renderValue={(selected) =>
												selected
													.map((id) => {
														const doctor = clinic_surgeons_list.find((doc) => doc.doctor_id === id);
														return doctor ? doctor.doctor_fullName : ''; // Map doctor_id back to doctor_fullName
													})
													.join(', ')
											}
											disabled={load_clinic_Array || main_surgeon.doctor_id == null}
											MenuProps={MenuProps}>
											{_.filter(
												clinic_surgeons_list,
												(o) => o.doctor_id !== main_surgeon?.doctor_id
											).map((option) => (
												<MenuItem key={option.doctor_id} value={option.doctor_id}>
													<Checkbox
														checked={selected_staff_doctors_names.some(
															(doc) => doc.doctor_id === option.doctor_id
														)}
													/>
													<ListItemText primary={option.doctor_fullName} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={4}>
									<FormControl fullWidth required>
										<InputLabel id="demo-multiple-checkbox-label">
											{getLangString('patient')}
										</InputLabel>
										<Select
											id="patient"
											name="patient"
											label={getLangString('patient')}
											variant="outlined"
											fullWidth
											required
											disabled={load_clinic_Array}
											value={procedure_patient_id}
											onChange={(e) => {
												set_procedure_patient_id(e.target.value);
											}}>
											{clinic_patients_list.map((option) => (
												<MenuItem key={option.patient_id} value={option.patient_id}>
													{option.patient_fullName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								{/* <Grid item xs={12} sm={6}>
									<TextField
										required
										id="surgeryType"
										name="surgeryType"
										label="Surgery Type"
										value={procedure_type}
										fullWidth
										variant="outlined"
										onChange={(e) => {
											set_procedure_type(e.target.value);
										}}
									/>
								</Grid> */}

								<Grid item xs={12} sm={6}>
									<FormControl fullWidth required>
										<InputLabel id="demo-multiple-checkbox-label">
											{getLangString('surgery_type')}
										</InputLabel>
										<Select
											id="surgeryType"
											name="surgeryType"
											label={getLangString('surgery_type')}
											variant="outlined"
											fullWidth
											required
											value={procedure_type}
											onChange={(e) => {
												set_procedure_type(e.target.value);
											}}>
											{surgeryType.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										id="surgeryDate"
										name="surgeryDate"
										label={getLangString('surgery_date')}
										type="date"
										// inputFormat="yyyy-mm-dd"
										value={procedure_date_time}
										fullWidth
										variant="outlined"
										onChange={(e) => {
											set_procedure_date_time(e.target.value);
										}}
										InputLabelProps={{
											shrink: true,
											required: true
										}}
									/>
								</Grid>

								{/* <Grid item xs={12} sm={4}>
								<FormControl fullWidth>
									<InputLabel id="demo-multiple-checkbox-label">Surgery Model Type</InputLabel>
									<Select
										id="modelType"
										name="modelType"
										label="Surgery Model Type"
										variant="outlined"
										fullWidth
										required
										value={procedure_model_type}
										onChange={(e) => {
											set_procedure_model_type(e.target.value);
										}}>
										{[
											{ label: 'Face', value: 'face' },
											{ label: 'Wound', value: 'wound' }
										].map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid> */}

								<Grid item xs={12} sm={3}>
									<Autocomplete
										fullWidth
										disablePortal
										id="procedureCode"
										name="procedureCode"
										options={all_cpt_codes}
										getOptionLabel={(option) => {
											return option.cpt_code;
										}}
										renderInput={(params) => (
											<TextField fullWidth {...params} label={getLangString('surgery_code')} />
										)}
										value={procedure_code_obj}
										isOptionEqualToValue={(option, value) => {
											return option?.id === value?.id;
										}}
										onChange={(event, newValue, reason) => {
											if (reason === 'clear') {
												set_procedure_code_obj(null);
												set_procedure_code('');
												set_procedure_description('');
											} else {
												set_procedure_code_obj(newValue);
												set_procedure_code(newValue?.cpt_code);
												set_procedure_description(newValue.cpt_code_description);
											}
										}}
										noOptionsText={
											<div>
												<MenuItem disabled>
													<Typography sx={{ fontSize: '16px', color: 'black' }}>
														No matches
													</Typography>
												</MenuItem>
												<MenuItem
													onClick={() => {
														setOpenCptCodeDailog(true);
													}}>
													{/* <Button
													variant="text"
													color="secondary"
													type="button"
													// startIcon={<AddIc fontSize="small" />}
													// onClick={() => setOpenModal(true)}
												> */}
													<Typography sx={{ fontSize: '16px', color: 'black' }}>
														{`+ ${getLangString('add_new')}`}
													</Typography>
													{/* </Button> */}
												</MenuItem>
											</div>
										}
									/>
								</Grid>

								<Grid item xs={12} sm={9}>
									<TextField
										required
										id="surgeryDescription "
										name="surgeryDescription"
										label={getLangString('surgery_description')}
										value={procedure_description}
										fullWidth
										variant="outlined"
										onChange={(e) => {
											set_procedure_description(e.target.value);
										}}
									/>
								</Grid>

								{/* <Grid item xs={12} sm={4}>
									<TextField
										select
										id="staff"
										name="staff"
										label="Assign Surgeon/Staff"
										value={surgeon.value || ''}
										fullWidth
										variant="outlined"
										onChange={(e) => {
											console.log('e', e);
											set_procedure_doctors_roles((oldArray) => [
												oldArray[0],
												{
													doctor_id: e.target.value,
													interaction_type: 'staff'
												}
											]);
											set_surgeon(e.target);
										}}>
										{clinic_surgeons_list.map((option) => (
											<MenuItem key={option.doctor_id} value={option.doctor_id}>
												{option.doctor_fullName}
											</MenuItem>
										))}
									</TextField>
								</Grid> */}
							</Grid>
						</React.Fragment>
					</Paper>
				</Container>

				<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
					<Paper
						variant="outlined"
						sx={{
							borderColor: '#1db14c',
							borderRadius: 2,
							boxShadow: 2,
							// my: { xs: 3, md: 2 },
							p: { xs: 2, md: 3 }
						}}>
						<React.Fragment>
							{/* <Grid item xs={6} xl={6}> */}
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="h6" gutterBottom>
									{getLangString('monitoring_plan')}
								</Typography>
							</Stack>

							<Grid container spacing={3}>
								{/* <Grid item xs={12} sm={4}>
								<FormControl fullWidth>
									<InputLabel htmlFor="tempUnit">{getLangString('temperature_unit')}</InputLabel>
									<Select
										required
										id="tempUnit"
										name="tempUnit"
										fullWidth
										input={<OutlinedInput label={getLangString('temperature_unit')} />}
										value={temperature.unit}
										onChange={(e) => {
											set_temperature({
												...temperature,
												unit: e.target.value
											});
										}}>
										{temperatureUnits.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid> */}
								{/* <Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<TextField
										required
										id="monitoringPeriod"
										name="monitoringPeriod"
										label={getLangString('monitoring_period_from')}
										type="date"
										// inputFormat="yyyy-mm-dd"
										value={monitor_period_from}
										onChange={(event) => {
											set_monitor_period_from(event.target.value);
										}}
										fullWidth
										InputLabelProps={{
											shrink: true,
											required: true
										}}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<TextField
										required
										fullWidth
										id="monitoringPeriod"
										name="monitoringPeriod"
										label={getLangString('monitoring_period_to')}
										type="date"
										// inputFormat="yyyy-mm-dd"
										value={monitor_period_to}
										onChange={(event) => {
											set_monitor_period_to(event.target.value);
										}}
										InputLabelProps={{
											shrink: true,
											required: true
										}}
									/>
								</FormControl>
							</Grid> */}
								<Grid item container spacing={1}>
									<Grid
										item
										xs={12}
										md={2.5}
										container
										alignItems="flex-end"
										justifyContent="flex-start">
										<Stack direction="row" alignItems="center">
											<IOSSwitch
												checked={check_vital_signs}
												onChange={(event) => {
													set_check_vital_signs(event.target.checked);
													set_open_kpis_dailog(event.target.checked);
													set_kpi_type('vital_signs');
												}}
												inputProps={{ 'aria-label': 'controlled' }}
												sx={{ mr: 1 }}
											/>
											<Stack direction="column" alignItems="end">
												<Link
													component="button"
													variant="body2"
													onClick={() => {
														set_open_kpis_dailog(true);
														set_kpi_type('vital_signs');
													}}
													sx={{
														fontFamily: 'Roboto',
														fontSize: '16px',
														fontWeight: 400,
														lineHeight: '19px',
														textAlign: 'left',
														mt: 2
													}}
													noWrap>
													{getLangString('vital_signs')}
												</Link>
												{vitalSignsEnabled > 0 ? (
													<Typography
														sx={{
															width: '24px',
															height: '16px',
															fontFamily: 'Roboto',
															fontStyle: 'normal',
															fontWeight: 400,
															fontSize: '11px',
															lineHeight: '17px',
															letterSpacing: '0.106132px',
															color: 'rgba(0, 0, 0, 0.6)'
														}}>
														{/* {check_vital_signs &&
															`${vitalSignsEnabled}/${Object.values(vital_signs).length}`} */}
													</Typography>
												) : (
													<Typography
														sx={{
															width: '24px',
															height: '16px',
															fontFamily: 'Roboto',
															fontStyle: 'normal',
															fontWeight: 400,
															fontSize: '11px',
															lineHeight: '17px',
															letterSpacing: '0.106132px',
															color: 'rgba(0, 0, 0, 0.6)'
														}}>
														{` `}
													</Typography>
												)}
											</Stack>
										</Stack>
									</Grid>
									<Grid
										item
										xs={12}
										md={2.5}
										container
										alignItems="flex-end"
										justifyContent="flex-start">
										<Stack direction="row" alignItems="center">
											<IOSSwitch
												checked={check_wound_inputs}
												onChange={(event) => {
													// set_check_wound_inputs(event.target.checked);
													set_open_kpis_dailog(event.target.checked);
													set_kpi_type('wound_inputs');
												}}
												inputProps={{ 'aria-label': 'controlled' }}
												sx={{ mr: 1 }}
											/>
											<Stack direction="column" alignItems="end">
												<Link
													component="button"
													variant="body2"
													noWrap
													onClick={(event) => {
														set_open_kpis_dailog(true);
														set_kpi_type('wound_inputs');
													}}
													sx={{
														fontFamily: 'Roboto',
														fontSize: '16px',
														fontWeight: 400,
														lineHeight: '19px',
														textAlign: 'left',
														mt: 2
													}}>
													{getLangString('wound_inputs')}
												</Link>
												{woundInputsEnabled > 0 ? (
													<Typography
														sx={{
															width: '24px',
															height: '16px',
															fontFamily: 'Roboto',
															fontStyle: 'normal',
															fontWeight: 400,
															fontSize: '11px',
															lineHeight: '17px',
															letterSpacing: '0.106132px',
															color: 'rgba(0, 0, 0, 0.6)'
														}}>
														{/* {check_wound_inputs &&
															`${woundInputsEnabled}/${Object.values(wound_inputs).length}`} */}
													</Typography>
												) : (
													<Typography
														sx={{
															width: '24px',
															height: '16px',
															fontFamily: 'Roboto',
															fontStyle: 'normal',
															fontWeight: 400,
															fontSize: '11px',
															lineHeight: '17px',
															letterSpacing: '0.106132px',
															color: 'rgba(0, 0, 0, 0.6)'
														}}>
														{` `}
													</Typography>
												)}
											</Stack>
										</Stack>
									</Grid>
									<Grid
										item
										xs={12}
										md={2.5}
										container
										alignItems="flex-end"
										justifyContent="flex-start">
										<Stack direction="row" alignItems="center">
											<IOSSwitch
												checked={check_well_being}
												onChange={(event) => {
													set_check_well_being(event.target.checked);
													set_open_kpis_dailog(event.target.checked);
													set_kpi_type('well_being');
												}}
												inputProps={{ 'aria-label': 'controlled' }}
												sx={{ mr: 1 }}
											/>
											<Stack direction="column" alignItems="end">
												<Link
													component="button"
													variant="body2"
													onClick={() => {
														set_open_kpis_dailog(true);
														set_kpi_type('well_being');
													}}
													sx={{
														fontFamily: 'Roboto',
														fontSize: '16px',
														fontWeight: 400,
														lineHeight: '19px',
														textAlign: 'left',
														mt: 2
													}}>
													{getLangString('well_being')}
												</Link>
												{wellBeingEnabled > 0 ? (
													<Typography
														disabled={wellBeingEnabled > 0}
														sx={{
															width: '24px',
															height: '16px',
															fontFamily: 'Roboto',
															fontStyle: 'normal',
															fontWeight: 400,
															fontSize: '11px',
															lineHeight: '17px',
															letterSpacing: '0.106132px',
															color: 'rgba(0, 0, 0, 0.6)'
														}}>
														{/* {check_well_being &&
															`${wellBeingEnabled}/${Object.values(well_being).length}`} */}
													</Typography>
												) : (
													<Typography
														sx={{
															width: '24px',
															height: '16px',
															fontFamily: 'Roboto',
															fontStyle: 'normal',
															fontWeight: 400,
															fontSize: '11px',
															lineHeight: '17px',
															letterSpacing: '0.106132px',
															color: 'rgba(0, 0, 0, 0.6)'
														}}>
														{` `}
													</Typography>
												)}
											</Stack>
										</Stack>
									</Grid>
								</Grid>
							</Grid>

							<Divider sx={{ my: 3 }} variant="middle" />

							<Typography variant="h6" gutterBottom>
								{getLangString('prescription_medications')}
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Autocomplete
										fullWidth
										disablePortal
										id="medicationName"
										name="medicationName"
										options={medications_list?.sort((a, b) =>
											a.medication_group?.localeCompare(b.medication_group)
										)}
										groupBy={(option) => option.medication_group}
										renderGroup={(params) => (
											<li key={params.key}>
												<GroupHeader>{params.group}</GroupHeader>
												<GroupItems>{params.children}</GroupItems>
											</li>
										)}
										getOptionLabel={(option) =>
											`${option.medication_name} - ${option.medication_dose}`
										}
										renderInput={(params) => (
											<TextField fullWidth {...params} label={getLangString('medication_name')} />
										)}
										noOptionsText={
											<div>
												<MenuItem disabled>
													<Typography sx={{ fontSize: '16px', color: 'black' }}>
														No matches
													</Typography>
												</MenuItem>
												<MenuItem
													onClick={() => {
														setOpenMedicationDailog(true);
													}}>
													{/* <Button
													variant="text"
													color="secondary"
													type="button"
													// startIcon={<AddIc fontSize="small" />}
													// onClick={() => setOpenModal(true)}
												> */}
													<Typography sx={{ fontSize: '16px', color: 'black' }}>
														{`+ ${getLangString('add_new_medication')}`}
													</Typography>
													{/* </Button> */}
												</MenuItem>
											</div>
										}
										value={selected_medication_option}
										isOptionEqualToValue={(option, value) => option?.id === value?.id}
										onChange={(event, newValue, reason) => {
											if (reason === 'clear') {
												set_selected_medication_option(null);
												set_add_medication_name(null);
												set_add_medication_id('');
												set_add_medication_dose('');
											}
											if (!newValue) {
												set_add_medication_name(null);
												set_add_medication_dose('');
												return;
											}
											set_selected_medication_option(newValue);
											set_add_medication_name(newValue.medication_name);
											set_add_medication_id(newValue.medication_id);
											set_add_medication_dose(newValue.medication_dose);
										}}
									/>
									{/* <TextField
									select
									id="medicationName"
									name="medicationName"
									label={getLangString('medication_name')}
									fullWidth
									variant="outlined"
									value={add_medication_name}
									onChange={(event) => {
										set_add_medication_name(event.target.value);
										let obj = _.find(medications_list, {
											medication_name: event.target.value
										});
										set_add_medication_id(obj.medication_id);
										set_add_medication_dose(obj.medication_dose);
									}}>
									{medications_list.map((option) => {
										return (
											<MenuItem key={option.medication_id} value={option.medication_name}>
												{option.medication_name}
											</MenuItem>
										);
									})}
								</TextField> */}
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="dose"
										name="dose"
										label={getLangString('dose')}
										fullWidth
										variant="outlined"
										disabled
										value={add_medication_dose}
										onChange={(event) => {
											set_add_medication_dose(event.target.value);
										}}
										// InputProps={{
										// 	endAdornment: <InputAdornment position="start">mg</InputAdornment>
										// }}
										InputLabelProps={{
											shrink: true
										}}>
										{' '}
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										id="dailyIntakeFrequency"
										name="dailyIntakeFrequency"
										label={getLangString('medication_standard_frequency')}
										fullWidth
										value={add_medication_daily_intake}
										variant="outlined"
										onChange={(event) => {
											set_add_medication_daily_intake(event.target.value);
										}}>
										{dailyIntakeFrequency.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="startDate"
										name="startDate"
										label={getLangString('start_date')}
										type="date"
										// inputFormat="yyyy-mm-dd"
										value={add_medication_start_date}
										onChange={(event) => {
											set_add_medication_start_date(event.target.value);
										}}
										InputLabelProps={{
											shrink: true
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="endDate"
										name="endDate"
										label={getLangString('end_date')}
										type="date"
										// inputFormat="yyyy-mm-dd"
										value={add_medication_end_date}
										onChange={(event) => {
											set_add_medication_end_date(event.target.value);
										}}
										InputLabelProps={{
											shrink: true
										}}
									/>
								</Grid>
								<Grid item xs={12} xl={12}>
									<Box textAlign="left">
										<Button variant="contained" color="success" onClick={setNewMedication}>
											{getLangString('add')}
										</Button>
									</Box>
								</Grid>

								<Divider sx={{ my: 3 }} variant="middle" />

								<Grid item xs={12} xl={12}>
									<Typography variant="h6" gutterBottom>
										{getLangString('medication_plan')}
									</Typography>
								</Grid>
								<Box sx={{ mt: 4, mx: 2, height: 400, width: '100%' }}>
									<DataGrid
										columns={newMedicationColumns}
										rows={medication_obj}
										getRowId={(row) => row.med_id}
										pageSize={5}
										rowsPerPageOptions={[5]}
										checkboxSelection
										disableSelectionOnClick
									/>
								</Box>
							</Grid>

							<Grid container item xs={12} xl={12} mt={2} display="flex" spacing={0.5}>
								<Grid item sm={0} md={8.4} lg={9.6} xl={10}></Grid>

								<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
									<Box textAlign="left">
										<Button
											variant="outlined"
											color="success"
											type="button"
											onClick={() => {
												navigate(-1);
											}}>
											{getLangString('cancel')}
										</Button>
									</Box>
								</Grid>
								<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
									<Box textAlign="left">
										<Button
											variant="contained"
											color="success"
											type="submit"
											onClick={() => {
												onSubmitSurgery();
											}}>
											{getLangString('save')}
										</Button>
									</Box>
								</Grid>
							</Grid>
						</React.Fragment>
					</Paper>
				</Container>
			</FormControl>
			{/* </form> */}
		</ThemeProvider>
	);
};

export default NewPatientSurgery;
