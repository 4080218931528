import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {
	Grid,
	Typography,
	TextField,
	Button,
	MenuItem,
	OutlinedInput,
	Select,
	InputLabel,
	FormControl,
	CircularProgress
} from '@mui/material';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { create, getListPost, post } from '../../services/services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getTodayDate, isPasswordValid } from '../../helpers/customFunctions';
import { useForm } from 'react-hook-form';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useAuth } from '../../context/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { genderArray, providerRole } from '../../helpers/constants';
import _ from 'lodash';
import { useLanguage } from '../../context/LanguageProvider';
import CountrySelect from './../../components/countryCodeSelect';
import ProfileUpload from '../../components/ProfileUpload';
import axios from 'axios';
import AddressFields from '../../elements/AddressFields';
import PasswordRequirements from '../../components/passwordRequirements';

const NewProvider = () => {
	const navigate = useNavigate();
	const { getLangString } = useLanguage();
	const alertRef = useRef(null);

	const location = useLocation();
	const data = location?.state?.params;

	const { profile, tokenData } = useAuth();
	const isAdmin = tokenData?.role === 'admin';
	const isOrgAdmin = profile?.profile?.access_level === 'orgAdmin';

	const [username, set_username] = useState('');
	const [country_code, set_country_code] = useState('');
	const [isPhone, set_isPhone] = useState(false);
	const [password, set_password] = useState('');
	const [confirm_password, set_confirm_password] = useState('');
	const [fname, set_fname] = useState('');
	const [mname, set_mname] = useState('');
	const [lname, set_lname] = useState('');
	const [dob, set_dob] = useState(getTodayDate);
	const [gender, set_gender] = useState('');
	const [email, set_email] = useState('');
	const [phone, set_phone] = useState('');
	const [city, set_city] = useState('');
	const [country, set_country] = useState('');
	const [zip_code, set_zip_code] = useState('');
	const [state, set_state] = useState('');
	const [address, set_address] = useState('');
	const [address2, set_address2] = useState('');
	const [brief, set_brief] = useState('');
	const [clinic_id, set_clinic_id] = useState(isAdmin ? '1' : tokenData?.clinic_id);
	const [department, set_department] = useState('');
	const [role, set_role] = useState('');
	const [access_level, set_access_level] = useState('doctor');
	const [loading, set_loading] = useState(true);
	const [clinic_Array, set_clinic_Array] = useState([]);
	const [profile_pic, set_profile_pic] = useState(null);
	const [new_profile_pic, set_new_profile_pic] = useState(null);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const resetState = () => {
		set_username('');
		set_password('');
		set_confirm_password('');
		set_fname('');
		set_mname('');
		set_lname('');
		set_dob(getTodayDate);
		set_gender('');
		set_email('');
		set_phone('');
		set_city('');
		set_country('');
		set_zip_code('');
		set_state('');
		set_phone('');
		set_address('');
		set_address2('');
		set_clinic_id(isAdmin ? '1' : tokenData?.clinic_id);
		set_department('');
		set_role('');
		set_access_level('doctor');
		set_brief('');
	};

	const accessLevels =
		isAdmin || isOrgAdmin
			? [
					{ label: 'OrgAdmin', value: 'orgAdmin' },
					{ label: 'Doctor', value: 'doctor' }
			  ]
			: [{ label: 'Doctor', value: 'doctor' }];

	let values = {
		username: isPhone ? `${country_code}${username}` : username,
		password,
		fname,
		mname,
		lname,
		dob,
		gender,
		email,
		phone,
		city,
		country,
		zip_code,
		state,
		address,
		address2,
		access_level,
		brief,
		clinic_id,
		department,
		role,
		country_code,
		isPhone
	};

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'clinic', action: 'getclinics' };
			let data = await getListPost(urlParams);
			set_clinic_Array(data.data);
			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	// useEffect(() => {
	// 	console.log('FROM useEffects');
	// 	console.log(values);
	// });

	useEffect(() => {
		if (data) {
			set_clinic_id(isAdmin ? `${data.clinic_id}` || '1' : tokenData?.clinic_id);
			set_fname(data?.name?.given[0]);
			set_mname(data?.name?.given[1]);
			set_lname(data?.name?.family);
			set_gender(_.capitalize(data?.gender));
		}
	}, [clinic_Array]);

	const onSubmit = async () => {
		try {
			if (!isPasswordValid(password))
				throw new Error(
					`Password should contain 8 characters with at least 1 uppercase letter, 1 number, and 1 symbol`
				);
			if (password !== confirm_password) throw new Error(`Passwords did't match`);

			const urlParams = { resource: 'user', action: 'addDoctor' };
			const response = await create(urlParams, values);
			console.log('addDoctor', response);

			const response1 = await handleChangeProfilePic(response.data.data.doctor_user_id);
			console.log('handleChangeProfilePic', response1);

			if (response.status === 200 || response.status === 201)
				alertRef.current.handleClick({
					message: response.data.data.message.toUpperCase(),
					severity: 'success'
				});
			resetState();
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const handleChangeProfilePic = async (event, user_id) => {
		try {
			const urlParams = { resource: 'user', action: 'getProfileUploadUrlGeneric' };
			const res = await post(urlParams, {
				user_id
			});

			const url = res.data;
			const file = event.target.files[0];

			if (!file) return;

			var instance = axios.create();
			instance
				.put(url, file)
				.then(async function (result) {
					if (result.status === 200) {
						// alertRef.current.handleClick({
						// 	message: 'UPDATED! PAGE WILL REFRESH SOON!',
						// 	severity: 'success'
						// });
						// setTimeout(function () {
						// 	window.location.reload(false);
						// }, 10000);
					}
				})
				.catch(function (err) {
					alertRef.current.handleClick({
						message: 'PHOTO NOT UPDATED!',
						severity: 'error'
					});
					console.log('catch', err);
				});
		} catch (err) {
			console.error(err);
		}
	};

	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} />
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 2 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Toolbar>
						<Typography variant="h6" align="left" color="#1db14c">
							{getLangString('add_provider_title')}
						</Typography>
					</Toolbar>
				</Paper>
			</Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
					<Paper
						variant="outlined"
						sx={{
							my: { xs: 3, md: 2 },
							p: { xs: 2, md: 3 },
							borderRadius: 2,
							boxShadow: 2,
							borderColor: '#1db14c'
						}}>
						<React.Fragment>
							<Typography variant="h6" gutterBottom>
								{getLangString('provider_info')}
							</Typography>

							<ProfileUpload
								profile_pic={profile_pic}
								new_profile_pic={new_profile_pic}
								set_new_profile_pic={set_new_profile_pic}
							/>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<TextField
										required
										id="firstName"
										name="firstName"
										label={getLangString('first_name')}
										fullWidth
										variant="outlined"
										value={fname}
										onChange={(e) => set_fname(e.target.value)}
									/>
									{errors.fname && <p>first name is required.</p>}
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id="MiddleName"
										name="MiddleName"
										label={getLangString('middle_name')}
										fullWidth
										variant="outlined"
										value={mname}
										onChange={(e) => set_mname(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										required
										id="lastName"
										name="lastName"
										label={getLangString('last_name')}
										fullWidth
										variant="outlined"
										value={lname}
										onChange={(e) => set_lname(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											required
											id="dob"
											name="dob"
											label={getLangString('date_of_birth')}
											openTo="year"
											views={['year', 'month', 'day']}
											onChange={(newValue) => {
												set_dob(newValue);
												console.log('newValue', newValue);
											}}
											inputFormat="MM-dd-yyyy"
											value={dob}
											renderInput={(params) => (
												<TextField required {...params} sx={{ width: '100%' }} />
											)}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={12} sm={3}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="gender">{getLangString('gender')}</InputLabel>
										<Select
											id="gender"
											name="gender"
											input={<OutlinedInput label={getLangString('gender')} />}
											fullWidth
											variant="outlined"
											value={gender}
											onChange={(e) => {
												set_gender(e.target.value);
											}}>
											{genderArray.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={3}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="role">{getLangString('role')}</InputLabel>
										<Select
											id="role"
											name="role"
											input={<OutlinedInput label={getLangString('role')} />}
											fullWidth
											variant="outlined"
											value={role}
											onChange={(e) => {
												set_role(e.target.value);
											}}>
											{providerRole.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={3}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="Access Level">{getLangString('access_level')}</InputLabel>
										<Select
											id="accessLevel"
											name="accessLevel"
											input={<OutlinedInput label={getLangString('access_level')} />}
											fullWidth
											variant="outlined"
											value={access_level}
											onChange={(e) => {
												set_access_level(e.target.value);
											}}>
											{accessLevels.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={7}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="clinic">{getLangString('clinic_hospital')}</InputLabel>
										<Select
											id="clinic"
											name="clinic"
											input={<OutlinedInput label={getLangString('clinic_hospital')} />}
											fullWidth
											variant="outlined"
											value={clinic_id}
											disabled={!isAdmin}
											onChange={(e) => {
												isAdmin && set_clinic_id(e.target.value);
											}}>
											{clinic_Array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={5}>
									<TextField
										required
										id="department"
										name="department"
										label={getLangString('department')}
										fullWidth
										variant="outlined"
										value={department}
										onChange={(e) => set_department(e.target.value)}
									/>
								</Grid>

								{/* <Grid item xs={12} sm={12}>
									<Divider sx={{ my: 3 }} variant="middle" />
								</Grid> */}

								<Grid item xs={12} sm={6}>
									<TextField
										required
										id="email"
										name="email"
										label={getLangString('contact_email')}
										fullWidth
										variant="outlined"
										value={email}
										onChange={(e) => set_email(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										required
										id="phone"
										name="phone"
										label={getLangString('phone')}
										fullWidth
										variant="outlined"
										value={phone}
										onChange={(e) => set_phone(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										required
										id="address"
										name="address"
										label={getLangString('first_address')}
										fullWidth
										variant="outlined"
										value={address}
										onChange={(e) => set_address(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="address2"
										name="address2"
										label={getLangString('second_address')}
										fullWidth
										variant="outlined"
										value={address2}
										onChange={(e) => set_address2(e.target.value)}
									/>
								</Grid>

								<AddressFields
									set_country={set_country}
									set_state={set_state}
									set_city={set_city}
								/>

								<Grid item xs={12} sm={3}>
									<TextField
										required
										id="zipcode"
										name="zipcode"
										label={getLangString('zip_code')}
										fullWidth
										variant="outlined"
										value={zip_code}
										onChange={(e) => set_zip_code(e.target.value)}
									/>
								</Grid>

								<Grid item xs={12} xl={12}>
									<TextField
										id="brief"
										name="brief"
										label={getLangString('note')}
										fullWidth
										multiline
										rows={3}
										value={brief}
										onChange={(e) => set_brief(e.target.value)}
										variant="outlined"
									/>
								</Grid>
							</Grid>
						</React.Fragment>
					</Paper>
				</Container>
				<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
					<Paper
						variant="outlined"
						sx={{
							my: { xs: 3, md: 1 },
							p: { xs: 2, md: 3 },
							borderRadius: 2,
							boxShadow: 2,
							borderColor: '#1db14c'
						}}>
						<React.Fragment>
							<Typography variant="h6" gutterBottom>
								{getLangString('add_provider_account_info')}
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<FormControl fullWidth>
										<InputLabel htmlFor="Sign Up By">{getLangString('sign_up_by')}</InputLabel>
										<Select
											id="signUpBy"
											name="signUpBy"
											input={<OutlinedInput label={getLangString('sign_up_by')} />}
											fullWidth
											variant="outlined"
											value={isPhone === true ? 'Phone' : 'Email'}
											onChange={(e) => {
												if (e.target.value === 'Phone') set_isPhone(true);
												else set_isPhone(false);
												set_username('');
											}}>
											{[{ value: 'Email' }, { value: 'Phone' }].map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								{isPhone ? (
									<>
										<Grid item xs={12} sm={4}>
											<CountrySelect setCountryCode={set_country_code} fullWidth />
										</Grid>
										<Grid item xs={12} sm={8}>
											<TextField
												required
												id="username"
												name="username"
												label={getLangString('valid_phone_username')}
												fullWidth
												disabled={country_code === ''}
												variant="outlined"
												value={username}
												onChange={(e) => {
													const regex = /^[0-9\b]+$/;
													if (e.target.value === '' || regex.test(e.target.value)) {
														set_username(e.target.value);
													}
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												required
												id="password"
												name="password"
												label={getLangString('password')}
												fullWidth
												variant="outlined"
												value={password}
												onChange={(e) => set_password(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												required
												id="confirmpassword"
												name="confirmpassword"
												label={getLangString('confirm_password')}
												fullWidth
												variant="outlined"
												value={confirm_password}
												onChange={(e) => set_confirm_password(e.target.value)}
											/>
										</Grid>
									</>
								) : (
									<>
										<Grid item xs={12} sm={4}>
											<TextField
												required
												id="username"
												name="username"
												label={getLangString('valid_email_username')}
												fullWidth
												variant="outlined"
												value={username}
												onChange={(e) => set_username(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<TextField
												required
												id="password"
												name="password"
												label={getLangString('password')}
												fullWidth
												variant="outlined"
												value={password}
												onChange={(e) => set_password(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<TextField
												required
												id="confirmpassword"
												name="confirmpassword"
												label={getLangString('confirm_password')}
												fullWidth
												variant="outlined"
												value={confirm_password}
												onChange={(e) => set_confirm_password(e.target.value)}
											/>
										</Grid>

										<Grid item xs={12} sm={12}>
											<PasswordRequirements />
										</Grid>
									</>
								)}

								<Grid container item xs={12} xl={12} mt={2} display="flex" spacing={0.5}>
									<Grid item sm={0} md={8.4} lg={9.6} xl={10}></Grid>

									<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
										<Box textAlign="left">
											<Button
												variant="outlined"
												color="success"
												type="button"
												onClick={() => {
													navigate(-1);
												}}>
												{getLangString('cancel')}
											</Button>
										</Box>
									</Grid>
									<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
										<Box textAlign="left">
											<Button variant="contained" color="success" type="submit">
												{getLangString('save')}
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</React.Fragment>
					</Paper>
				</Container>
			</form>
		</ThemeProvider>
	);
};

export default NewProvider;
