import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import './messaging_init_in_sw'
import Home from './pages/Home';
import NoPage from './pages/NoPage';
import Support from './pages/Support';
import Dashboard from './layout/Dashboard';
import Clinics from './pages/clinic/clinicList';
import NewClinic from './pages/clinic/addClinicForm';
import EditClinic from './pages/clinic/editClinicForm';
import Providers from './pages/provider/providerList';
import NewProvider from './pages/provider/addProviderForm';
import EditProvider from './pages/provider/editProviderForm';
import Patients from './pages/patient/patientList';
import NewPatient from './pages/patient/addPatientForm';
import EditPatient from './pages/patient/editPatientForm';
import PatientHistory from './pages/patient/patientHistory';
import Surgery from './pages/surgery/surgeryList';
import EditSurgery from './pages/surgery/editSurgeryForm.js';
import NewPatientSurgery from './pages/surgery/addSurgeryGeneralForm';
import BulkDoctors from './pages/BulkData/BulkDoctors';
import BulkPatients from './pages/BulkData/BulkPatients';
import Terms from './pages/terms/termServices';
import { useAuth } from './context/AuthProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Epic from './pages/epic/epic';
import Integration from './pages/integrations/intergrationPage';
import FeedBack from './pages/feedback/feedback';
import SurgeryTemplate from './pages/template/list';
import Template from './pages/template/template';
import ContactUs from './pages/contactUs/ContactUs.js';
import Prediction from './pages/prediction/prediction';
import Reporting from './pages/reporting/reporting';

function App() {
	const { tokenData } = useAuth();
	const isAdmin = tokenData?.role === 'admin';

	return (
		<BrowserRouter>
			<Routes>
				{/* default page */}
				<Route exact path="/" element={<Navigate to={isAdmin ? '/Patient' : '/Patient'} />} />

				{isAdmin && (
					<>
						<Route
							exact
							path="/Clinic"
							element={
								<Dashboard>
									<Clinics />
								</Dashboard>
							}
						/>
						<Route
							exact
							path="/NewClinic"
							element={
								<Dashboard>
									<NewClinic />
								</Dashboard>
							}
						/>
						<Route
							exact
							path="/EditClinic"
							element={
								<Dashboard>
									<EditClinic />
								</Dashboard>
							}
						/>
						<Route
							exact
							path="/BulkDoctors"
							element={
								<Dashboard>
									<BulkDoctors />
								</Dashboard>
							}
						/>
						<Route
							exact
							path="/BulkPatients"
							element={
								<Dashboard>
									<BulkPatients />
								</Dashboard>
							}
						/>
					</>
				)}
				<Route
					exact
					path="/Provider"
					element={
						<Dashboard>
							<Providers />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/NewProvider"
					element={
						<Dashboard>
							<NewProvider />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/EditProvider"
					element={
						<Dashboard>
							<EditProvider />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Patient"
					element={
						<Dashboard>
							<Patients />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/NewPatient"
					element={
						<Dashboard>
							<NewPatient />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/EditPatient"
					element={
						<Dashboard>
							<EditPatient />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Surgery"
					element={
						<Dashboard>
							<Surgery />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/NewPatientSurgery" // can be reached from all accounts in surgery list page
					element={
						<Dashboard>
							<NewPatientSurgery />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/EditSurgery" // can be reached from doctor accounts only
					element={
						<Dashboard>
							<EditSurgery />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/SurgeryTemplate"
					element={
						<Dashboard>
							<SurgeryTemplate />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Template"
					element={
						<Dashboard>
							<Template />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/patientHistory/:userId"
					element={
						<Dashboard>
							<PatientHistory />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Epic"
					element={
						<Dashboard>
							<Epic />
						</Dashboard>
					}
				/>
				{isAdmin && (
					<Route
						exact
						path="/Integration"
						element={
							<Dashboard>
								<Integration />
							</Dashboard>
						}
					/>
				)}
				<Route
					exact
					path="/ContactUs"
					element={
						<Dashboard>
							<ContactUs />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Support"
					element={
						<Dashboard>
							<Support />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Terms"
					element={
						<Dashboard>
							<Terms />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/FeedBack"
					element={
						<Dashboard>
							<FeedBack />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Prediction"
					element={
						<Dashboard>
							<Prediction />
						</Dashboard>
					}
				/>
				<Route
					exact
					path="/Reporting"
					element={
						<Dashboard>
							<Reporting />
						</Dashboard>
					}
				/>
				<Route path="*" element={<NoPage />} />

				{/* Non-Protected Paths */}
				<Route path="*" element={<NoPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
