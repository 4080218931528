import React, { useState, useEffect, useMemo } from 'react';
import { nlNL, DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, Grid, Paper, CircularProgress } from '@mui/material';
import { getList } from '../services/services';
import { useNavigate } from 'react-router-dom';
import { getListPost } from './../services/services';
import { useLanguage } from '../context/LanguageProvider';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

const ListGrid = (props) => {
	const navigate = useNavigate();
	// const { lang, getLangString } = useLanguage();

	const { columns, rowID, urlParams, createPage, columnVisibilityModel, pageName } = props;
	const { buttonText, createPagePath } = createPage;

	const [locale, setLocale] = useState('enUS');
	const [loading, set_loading] = useState(true);
	const [rows, set_rows] = useState([]);
	const [pageSize, setPageSize] = useState(25);
	const [pageNumber, setPageNumber] = useState(0);

	const theme = useTheme();

	// useEffect(() => {
	// 	console.log('lang use', lang)
	// 	if (lang === 'en') setLocale('enUS');
	// 	else if (lang === 'es') setLocale('esES');
	// }, [lang]);

	// useEffect(() => {
	// 	console.log('lang use locale', locale)
	// }, [locale]);

	useEffect(() => {
		const fetchData = async () => {
			const result = await getListPost(urlParams);
			console.log('--------> DataGrid', result.data);
			set_rows(result.data);
			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	useEffect(() => {
		// set up page number in or from local storage
		const savedPageNumber = parseInt(localStorage.getItem(`${pageName}PageNumber`));
		const savedPageSize = parseInt(localStorage.getItem(`${pageName}PageSize`));

		// if (savedPageSize) setPageSize(localStorage.getItem(`${pageName}PageSize`));
		if (loading === false) {
			if (savedPageNumber && savedPageSize) {
				setPageNumber(savedPageNumber);
				setPageSize(savedPageSize);
			} else if (pageName) {
				localStorage.setItem(`${pageName}PageNumber`, pageNumber);
				localStorage.setItem(`${pageName}PageSize`, pageSize);
			}
		}
	}, [loading]);

	useEffect(() => {
		// update page number in local storage
		if (loading === false && pageName) localStorage.setItem(`${pageName}PageNumber`, pageNumber);
	}, [pageNumber]);

	useEffect(() => {
		// update page size in local storage
		if (loading === false && pageName) localStorage.setItem(`${pageName}PageSize`, pageSize);
	}, [pageSize]);

	const themeWithLocale = useMemo(() => createTheme(theme, locales[locale]), [locale, theme]);

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<Box sx={{ mt: 3, ml: 3, display: 'flex', height: '100%', width: '100%' }}>
			<ThemeProvider theme={themeWithLocale}>
				<Paper
					sx={{
						width: '100%',
						minHeight: 600,
						backgroundColor: (theme) =>
							theme.palette.mode === 'light' ? theme.palette.grey[150] : theme.palette.grey[900]
					}}>
					<Grid container spacing={3}>
						{buttonText && (
							<Grid item xs={12} sm={6} sx={{ ml: 2, mt: 2 }}>
								<Button
									variant="contained"
									color="success"
									type="submit"
									onClick={() => {
										navigate(createPagePath);
									}}>
									{buttonText}
								</Button>
							</Grid>
						)}

						<Grid item xs={12} sm={12}>
							<Box
								sx={{
									display: 'flex',
									height: '100%',
									minHeight: 500,
									width: '100%'
								}}>
								<DataGrid
									columns={columns}
									rows={rows}
									page={pageNumber}
									onPageChange={(newPage) => setPageNumber(newPage)}
									pageSize={pageSize}
									// onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
									pagination
									rowsPerPageOptions={[25]}
									autoHeight
									checkboxSelection
									disableSelectionOnClick
									getRowId={rowID}
									initialState={{
										columns: {
											columnVisibilityModel: columnVisibilityModel
										},
										pagination: {
											// page: 0,
											// pageSize: 25
										}
									}}
									components={{
										Toolbar: GridToolbar
									}}
									componentsProps={{
										toolbar: {
											showQuickFilter: true,
											quickFilterProps: { debounceMs: 500 }
										}
									}}
									// localeText={getLangString('localizedTextsMap')}
									// localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
								/>
							</Box>
						</Grid>
					</Grid>
				</Paper>
			</ThemeProvider>
		</Box>
	);
};

export default ListGrid;
