import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
	Grid,
	Typography,
	TextField,
	Button,
	Stack,
	Box,
	Select,
	InputLabel,
	FormControl,
	OutlinedInput,
	MenuItem,
	CircularProgress,
	Divider,
	AccordionActions,
	AccordionSummary,
	Accordion,
	AccordionDetails
} from '@mui/material';
import _ from 'lodash';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper, FormControlLabel, Switch } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IOSSwitch } from '../../components/IosSwitchButton';
import { create, getList } from '../../services/services';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getTodayDate } from '../../helpers/customFunctions';
import { useForm } from 'react-hook-form';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useLanguage } from '../../context/LanguageProvider';

const NewClinic = () => {
	const navigate = useNavigate();
	const { getLangString } = useLanguage();
	const alertRef = useRef(null);

	const { enqueueSnackbar } = useSnackbar();

	const [clinic_name, set_clinic_name] = useState('');
	const [clinic_license_template_id, set_clinic_license_template_id] = useState('');
	const [clinic_license_activation_time, set_clinic_license_activation_time] =
		useState(getTodayDate);
	const [clinic_license_num_users, set_clinic_license_num_users] = useState('');
	const [clinic_location, set_clinic_location] = useState('');
	const [clinic_email, set_clinic_email] = useState('');
	const [clinic_phone, set_clinic_phone] = useState('');
	const [clinic_address, set_clinic_address] = useState('');
	const [clinic_license_is_active, set_clinic_license_is_active] = useState(true);
	const [clinic_brief, set_clinic_brief] = useState('');
	const [loading, set_loading] = useState(true);
	const [licence_types, set_licence_types] = useState([]);

	// Epic Config
	const [epic_organization_name, set_epic_organization_name] = useState('');
	const [epic_base_url, set_epic_base_url] = useState('');
	const [epic_api_version, set_epic_api_version] = useState('');

	const [available_integrations, set_available_integrations] = useState([]);
	const [integration_id, set_integration_id] = useState('');

	const [expanded, setExpanded] = React.useState(true);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const resetState = () => {
		set_clinic_name('');
		set_clinic_license_template_id('');
		set_clinic_license_activation_time(getTodayDate);
		set_clinic_license_num_users('');
		set_clinic_location('');
		set_clinic_email('');
		set_clinic_phone('');
		set_clinic_address('');
		set_clinic_license_is_active(true);
		set_clinic_brief('');
		set_epic_organization_name('');
		set_epic_base_url('');
		set_epic_api_version('');
		set_integration_id('');
	};

	const values = {
		clinic_name,
		clinic_address,
		clinic_phone,
		clinic_email,
		clinic_location,
		clinic_brief,
		license_id: clinic_license_template_id,
		number_of_users: clinic_license_num_users,
		license_active: clinic_license_is_active,
		clinic_license_activation_time
	};

	useEffect(() => {
		// logging on every change
		console.log('FROM useEffect');
		console.log(values);
	});

	useEffect(() => {
		const fetchData = async () => {
			const urlParams1 = { resource: 'clinic', action: 'getLicenseTypes' };
			const clinicResult = await getList(urlParams1);
			console.log('------> clinicResult', clinicResult);
			set_licence_types(clinicResult.data);

			const urlParams2 = { resource: 'integration', action: 'getIntegrationsType' };
			const integrationResult = await getList(urlParams2);
			console.log('integrationResult', integrationResult);
			set_available_integrations(integrationResult);

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	const onSubmit = async () => {
		try {
			console.log('integration_id', integration_id);
			let data = {};
			if (
				integration_id !== '' ||
				epic_organization_name !== '' ||
				epic_base_url !== '' ||
				epic_api_version !== ''
			) {
				if (
					integration_id !== '' &&
					epic_organization_name !== '' &&
					epic_base_url !== '' &&
					epic_api_version !== ''
				)
					data = {
						...values,
						integration_id,
						clinic_epic_resource: {
							epic_organization_name,
							epic_base_url,
							epic_api_version
						}
					};
				else {
					console.log('data error', data);
					alertRef.current.handleClick({
						message: 'COMPLETE EPIC CONFIGURATION',
						severity: 'error'
					});
					return;
				}
			} else {
				data = values;
			}
			console.log('data to sent', data);
			const urlParams = { resource: 'clinic', action: 'createclinic' };
			const response = await create(urlParams, data);
			console.log(response);

			if (response.status === 200 || response.status === 201) {
				if (response.data.data.epicResult && response.data.data.epicResult.err)
					enqueueSnackbar(response.data.data.epicResult.err.toUpperCase(), {
						variant: 'error'
					});
				else if (response.data.data.epicResult)
					enqueueSnackbar(response.data.data.epicResult.toUpperCase(), {
						variant: 'success'
					});
				enqueueSnackbar(response.data.data.clinicResult.toUpperCase(), { variant: 'success' });
				resetState();
			}
		} catch (err) {
			console.log('err', err);
			enqueueSnackbar(err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed', {
				variant: 'error'
			});
		}
	};

	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} />
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 2 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Toolbar>
						<Typography variant="h6" align="left" color="#1db14c">
							{getLangString('add_clinic_title')}
						</Typography>
					</Toolbar>
				</Paper>
			</Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
					<Box sx={{ mt: 1 }}>
						<Paper
							variant="outlined"
							sx={{
								borderRadius: 2,
								boxShadow: 2,
								borderColor: '#1db14c',
								my: { xs: 3, md: 2 },
								p: { xs: 2, md: 2 }
							}}>
							<React.Fragment>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12}>
										<Typography variant="h6" gutterBottom>
											{getLangString('clinic_info')}
										</Typography>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											fullWidth
											variant="outlined"
											value={clinic_name}
											onChange={(e) => set_clinic_name(e.target.value)}
										/>
										{errors.clinic_name && <p>Clinic name is required.</p>}
									</Grid>
									{/* <Grid item xs={12} sm={4}>
										<TextField
											required
											id="clinic_license_template_id"
											name="clinic_license_template_id"
											label="Licence Type"
											fullWidth
											value={clinic_license_template_id}
											variant="outlined"
											onChange={(e) => set_clinic_license_template_id(e.target.value)}
										/>
									</Grid> */}
									<Grid item xs={12} sm={4}>
										<FormControl fullWidth>
											<InputLabel htmlFor="licenceType">
												{getLangString('clinic_licence_type')}
											</InputLabel>
											<Select
												id="clinic_license_template_id"
												name="clinic_license_template_id"
												input={<OutlinedInput label="Licence Type" />}
												fullWidth
												variant="outlined"
												value={clinic_license_template_id}
												onChange={(e) => {
													console.log('e.target', e.target);
													set_clinic_license_template_id(e.target.value);
													let obj = _.find(licence_types, {
														license_id: e.target.value
													});
													set_clinic_license_num_users(obj.license_num_users);
												}}>
												{licence_types.map((option) => (
													<MenuItem key={option.license_id} value={option.license_id}>
														{option.license_name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={4}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												id="licenceActiveDate"
												name="licenceActiveDate"
												label={getLangString('clinic_licence_active_date')}
												openTo="year"
												views={['year', 'month', 'day']}
												onChange={(newValue) => {
													set_clinic_license_activation_time(newValue);
												}}
												inputFormat="MM-dd-yyyy"
												value={clinic_license_activation_time}
												renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="numberOfUsers"
											name="numberOfUsers"
											label={getLangString('clinic_licence_users_nb')}
											fullWidth
											variant="outlined"
											value={clinic_license_num_users}
											onChange={(e) => set_clinic_license_num_users(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="location"
											name="location"
											label={getLangString('location')}
											fullWidth
											variant="outlined"
											value={clinic_location}
											onChange={(e) => set_clinic_location(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											id="email"
											name="email"
											label={getLangString('address')}
											fullWidth
											variant="outlined"
											value={clinic_email}
											onChange={(e) => set_clinic_email(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="phone"
											name="phone"
											label={getLangString('phone')}
											fullWidth
											variant="outlined"
											value={clinic_phone}
											onChange={(e) => set_clinic_phone(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="address"
											name="address"
											label={getLangString('address')}
											fullWidth
											variant="outlined"
											value={clinic_address}
											onChange={(e) => set_clinic_address(e.target.value)}
										/>
									</Grid>
									<Grid item sx={{}} xs={12} sm={4}>
										<Stack spacing={1} alignItems="center">
											<Typography>{getLangString('active_deactivate')}</Typography>
											<FormControlLabel
												control={
													<IOSSwitch
														checked={clinic_license_is_active}
														onChange={(event) => {
															set_clinic_license_is_active(event.target.checked);
														}}
														inputProps={{ 'aria-label': 'ant design' }}
													/>
												}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} xl={12}>
										<TextField
											id="brief"
											name="brief"
											label={getLangString('brief')}
											fullWidth
											multiline
											rows={3}
											variant="outlined"
											value={clinic_brief}
											onChange={(e) => set_clinic_brief(e.target.value)}
										/>
									</Grid>

									{/* <Grid item xs={12} xl={12}>
										<Divider sx={{ my: 4 }} variant="middle" />
									</Grid> */}

									{/* <Grid item xs={12} xl={12}>
										<Accordion
											expanded={expanded === 'panel1'}
											onChange={handleChange('panel1')}
											TransitionProps={{ unmountOnExit: true }}>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1bh-content"
												id="panel1bh-header">
												<Typography sx={{ width: '33%', flexShrink: 0 }}>
													Epic Configuration
												</Typography>
												<Typography sx={{ color: 'text.secondary' }}>
													Set Epic Configuration for Clinic
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<AccordionActions>
													<Grid container spacing={2} sx={{ my: 2 }}>
														<Grid item xs={12} sm={4}>
															<TextField
																key="1"
																id="organizationName"
																name="organizationName"
																label="Organization Name"
																fullWidth
																variant="outlined"
																value={epic_organization_name}
																onChange={(e) => set_epic_organization_name(e.target.value)}
															/>
														</Grid>

														<Grid item xs={12} sm={4}>
															<TextField
																key="2"
																id="epicBaseURL"
																name="epicBaseURL"
																label="Production FHIR Base URL"
																fullWidth
																variant="outlined"
																value={epic_base_url}
																onChange={(e) => set_epic_base_url(e.target.value)}
															/>
														</Grid>

														<Grid item xs={12} sm={4}>
															<FormControl fullWidth>
																<InputLabel htmlFor="epicApiVersion">FHIR API Version</InputLabel>
																<Select
																	key="3"
																	id="epicApiVersion"
																	name="epicApiVersion"
																	input={<OutlinedInput label="FHIR API Version<" />}
																	fullWidth
																	variant="outlined"
																	value={epic_api_version}
																	onChange={(e) => {
																		set_epic_api_version(e.target.value);
																	}}>
																	{[].map((option) => (
																		<MenuItem key={option.license_id} value={option.license_id}>
																			{option.license_name}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</Grid>
													</Grid>
												</AccordionActions>
											</AccordionDetails>
										</Accordion>
									</Grid> */}

									<Grid item xs={12} sm={12}>
										<Divider sx={{ my: 3 }} variant="middle" />
									</Grid>

									<Grid item xs={12} sm={12}>
										<Typography variant="h6" gutterBottom>
											{getLangString('epic_title')}
										</Typography>
									</Grid>

									<Grid item xs={12} sm={12}>
										<FormControl fullWidth>
											<InputLabel htmlFor="integrationName">
												{getLangString('integreation_type')}
											</InputLabel>
											<Select
												id="integrationName"
												name="integrationName"
												input={<OutlinedInput label="Integration Name" />}
												fullWidth
												variant="outlined"
												value={integration_id}
												onChange={(e) => {
													set_integration_id(e.target.value);
												}}>
												{available_integrations.map((option) => (
													<MenuItem key={option.emr_type_id} value={option.emr_type_id}>
														{option.emr_type_name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											id="organizationName"
											name="organizationName"
											label={getLangString('organization_name')}
											fullWidth
											variant="outlined"
											value={epic_organization_name}
											onChange={(e) => set_epic_organization_name(e.target.value)}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											id="epicBaseURL"
											name="epicBaseURL"
											label={getLangString('base_url')}
											fullWidth
											variant="outlined"
											value={epic_base_url}
											onChange={(e) => set_epic_base_url(e.target.value)}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<FormControl fullWidth>
											<InputLabel htmlFor="epicApiVersion">
												{getLangString('api_version')}
											</InputLabel>
											<Select
												id="epicApiVersion"
												name="epicApiVersion"
												input={<OutlinedInput label="FHIR API Version<" />}
												fullWidth
												variant="outlined"
												value={epic_api_version}
												onChange={(e) => {
													set_epic_api_version(e.target.value);
												}}>
												{[
													{ label: 'DSTU2', value: 'api/FHIR/DSTU2' },
													{ label: 'STU3', value: 'api/FHIR/STU3' },
													{ label: 'R4', value: 'api/FHIR/R4' }
												].map((option) => (
													<MenuItem key={option.label} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid container item xs={12} xl={12} mt={2} display="flex" spacing={0.5}>
										<Grid item sm={0} md={8.4} lg={9.6} xl={10}></Grid>

										<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
											<Box textAlign="left">
												<Button
													variant="outlined"
													color="success"
													type="button"
													onClick={() => {
														navigate(-1);
													}}>
													{getLangString('cancel')}
												</Button>
											</Box>
										</Grid>
										<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
											<Box textAlign="left">
												<Button variant="contained" color="success" type="submit">
													{getLangString('save')}
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
						</Paper>
					</Box>
				</Container>
			</form>
		</ThemeProvider>
	);
};

export default NewClinic;
