import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLanguage } from '../../context/LanguageProvider';
import { InputAdornment, MenuItem, Select } from '@mui/material';
import { create } from '../../services/services';
import { useForm } from 'react-hook-form';
import CustomizedAlertBar from '../customAlertBar';
import { COLORS } from './../../context/colors';

const AddMedicationDialog = ({ open, setOpen, updateMedications }) => {
	const [medication_name, set_medication_name] = useState('');
	const [medication_dose, set_medication_dose] = useState('');
	const [medication_group, set_medication_group] = useState('');

	const alertRef = useRef(null);

	const { getLangString } = useLanguage();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	// const [open, setOpen] = useState(false);

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = () => {
		set_medication_name('');
		set_medication_dose('');
		set_medication_group('');
		setOpen(false);
	};

	const onSubmit = async () => {
		try {
			const urlParams = { resource: 'procedure', action: 'addNewMedication' };
			const response = await create(urlParams, {
				medication_name,
				medication_dose: `${medication_dose}mg`,
				medication_group
			});

			if (response.status === 200 || response.status === 201) {
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
				handleClose();
				updateMedications(true);
			}
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	return (
		<>
			<CustomizedAlertBar ref={alertRef} />
			{/* //{' '} */}
			{/* <form onSubmit={handleSubmit(onSubmit)}> */}
			<Dialog open={open} onClose={handleClose} fullWidth fullScreen={fullScreen}>
				<DialogTitle>{getLangString('add_new_medication')}</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>Add new medicine</DialogContentText> */}
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label={getLangString('medication_name')}
						type="text"
						fullWidth
						required
						value={medication_name}
						onChange={(event) => {
							set_medication_name(event.target.value);
						}}
						variant="outlined"
						sx={{ mb: 3 }}
					/>
					<TextField
						id="dose"
						name="dose"
						label={getLangString('dose')}
						fullWidth
						required
						variant="outlined"
						value={medication_dose}
						onChange={(event) => {
							set_medication_dose(event.target.value);
						}}
						sx={{ mb: 3 }}
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="start">mg</InputAdornment>
						}}
						InputLabelProps={{
							shrink: true
						}}
					/>
					<TextField
						id="medicationGroup"
						name="medicationGroup"
						label={getLangString('medication_group')}
						variant="outlined"
						select
						required
						fullWidth
						value={medication_group}
						onChange={(e) => {
							console.log('e --->', e.target.value, medication_name, medication_dose);
							set_medication_group(e.target.value);
						}}>
						{['Antibiotic', 'Painkiller', 'Other'].map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{getLangString('cancel')}</Button>
					<Button
						onClick={onSubmit}
						variant="contained"
						sx={{ backgroundColor: COLORS.RaisinPurple }}>
						{getLangString('add')}
					</Button>
				</DialogActions>
			</Dialog>
			{/* //{' ' */}
			{/* </form> */}
		</>
	);
};

export default AddMedicationDialog;
