export const COLORS = {
	RaisinPurple: '#B21E8C',
	Firebrick: '#D62C36',
	Red: '#D62C3626',
	Green: '#1db14c',
	Green_100: '#1DB14C',
	Green_200: '#00B04D',
	Emerald: '#3ABA72',
	EarthYellow: '#D99E46',
	Gray: '#1A1F36',
	Gray_600: '#515669',
	Gray_400: '#9EA0AA',
	Blue_100: '#A4B1F4'
};