import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import { Grid, Typography, TextField, Button, Stack, CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper, MenuItem } from '@mui/material';
import { getListPost } from '../../services/services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useAuth } from '../../context/AuthProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getTodayDate } from '../../helpers/customFunctions';
import PatientCard from '../../components/epic/patientCard';
import { post } from './../../services/services';
import { useNavigate } from 'react-router-dom';
import DoctorCard from './../../components/epic/doctorCard';
import ProcedureCard from './../../components/epic/procedureCard';
import PatientTab from '../../components/epic/UsersTab';
import UsersTab from './../../components/epic/UsersTab';
import ProceduresTab from './../../components/epic/ProceduresTab';
import { useLanguage } from '../../context/LanguageProvider';

const Epic = (props) => {
	const alertRef = useRef(null);
	const navigate = useNavigate();
	const { getLangString } = useLanguage();

	const { tokenData } = useAuth();
	const isAdmin = tokenData?.role === 'admin';

	const [clinic_id_user_search, set_clinic_id_user_search] = useState('');
	const [clinic_id_user_get, set_clinic_id_user_get] = useState('');
	const [clinic_id_procedure_search, set_clinic_id_procedure_search] = useState('');
	const [clinic_id_procedure_get, set_clinic_id_procedure_get] = useState('');

	const [procedure_id, set_procedure_id] = useState('');

	const [fname, set_fname] = useState('');
	const [mname, set_mname] = useState('');
	const [lname, set_lname] = useState('');
	const [dob, set_dob] = useState(getTodayDate);

	const [clinics_array, set_clinics_array] = useState([]);

	const [epic_search_user_result, set_epic_search_user_result] = useState({});
	const [search_user_type, set_search_user_type] = useState('');

	const [epic_get_user_result, set_epic_get_user_result] = useState({});
	const [get_user_type, set_get_user_type] = useState('');

	const [epic_get_procedure_result, set_epic_get_procedure_result] = useState({});

	const [epic_search_procedure_result, set_epic_search_procedure_result] = useState({});

	const [epic_user_id, set_epic_user_id] = useState('');

	const [patient_id, set_patient_id] = useState('');

	const [loading, set_loading] = useState(true);
	const [loaded, set_loaded] = useState(false);

	const [user_type, set_user_type] = useState('');

	const users = [{ value: 'Doctor' }, { value: 'Patient' }];

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	useEffect(() => {
		if (!isAdmin) {
			set_clinic_id_user_get(tokenData.clinic_id);
			set_clinic_id_user_search(tokenData.clinic_id);
		}
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const urlParams2 = { resource: 'clinic', action: 'getClinicsByIntegrationType' };
			let data2 = await getListPost(urlParams2, { integration_name: 'epic' });
			console.log('data2', data2);
			set_clinics_array(data2.data);

			set_loading(false);
			set_loaded(true);
		};
		if (!loaded) fetchData().catch(console.error);
	}, []);

	const formatDob = (dob) => {
		let month = dob.getMonth() + 1;
		let formatMonth = month < 10 ? `0${month}` : month;
		let day = dob.getDate();
		let formatDay = day < 10 ? `0${day}` : day;
		return `${dob.getFullYear()}-${formatMonth}-${formatDay}`;
	};

	const searchUsers = async () => {
		try {
			let urlParams, response;
			if (search_user_type === 'Doctor') {
				urlParams = { resource: 'epic', action: 'searchDoctors' };
				response = await post(urlParams, {
					given: fname,
					family: lname,
					clinic_id: clinic_id_user_search
				});
			} else {
				urlParams = { resource: 'epic', action: 'searchPatients' };
				response = await post(urlParams, {
					given: fname,
					family: lname,
					birthdate: formatDob(dob),
					clinic_id: clinic_id_user_search
				});
			}

			console.log('response from epic', response);

			if (response.data.entry[0].resource.active) {
				set_epic_search_user_result(response.data.entry);
			} else {
				throw response.data.entry[0].resource.issue[0].details.text;
			}
		} catch (err) {
			alertRef.current.handleClick({
				// message: err !== '' ? err.toString().toUpperCase() : 'Failed',
				message: 'User Not Found',
				severity: 'error'
			});
		}
	};

	const getUser = async () => {
		try {
			let urlParams;
			if (get_user_type === 'Doctor') urlParams = { resource: 'epic', action: 'getDoctor' };
			else urlParams = { resource: 'epic', action: 'getPatient' };
			const response = await post(urlParams, {
				user_id: epic_user_id,
				clinic_id: clinic_id_user_get
			});
			console.log('response from epic getUser', response);
			if (response?.data.active) {
				set_epic_get_user_result(response.data);
			} else {
				throw response.fail;
			}
		} catch (err) {
			alertRef.current.handleClick({
				message: 'User Not Found',
				severity: 'error'
			});
		}
	};

	const getProcedure = async () => {
		try {
			let urlParams = { resource: 'epic', action: 'getProcedure' };
			const response = await post(urlParams, {
				procedure_id,
				clinic_id: clinic_id_procedure_get
			});
			console.log('response getProcedure', response);
			if (response?.data) {
				set_epic_get_procedure_result(response.data);
			} else {
				throw response.fail;
			}
		} catch (err) {
			alertRef.current.handleClick({
				message: 'Procedure Not Found',
				severity: 'error'
			});
		}
	};

	const searchProcedure = async () => {
		try {
			let urlParams = { resource: 'epic', action: 'searchProcedure' };
			const response = await post(urlParams, {
				patient_id,
				clinic_id: clinic_id_procedure_search
			});
			console.log('response searchProcedure', response);
			if (response?.data.entry) {
				set_epic_search_procedure_result(response.data.entry);
			} else {
				throw response.fail;
			}
		} catch (err) {
			console.log('err', err);
			alertRef.current.handleClick({
				message: 'Procedure Not Found',
				severity: 'error'
			});
		}
	};

	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} />
			<CssBaseline />
			<Container component="main" maxWidth="xl" sx={{ mt: 4 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Stack
						direction="row"
						spacing={1}
						sx={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start'
							}}>
							<Toolbar>
								<Typography variant="h6" align="left" color="#1db14c" sx={{}}>
									{getLangString('epic')}
								</Typography>
							</Toolbar>
						</Box>
					</Stack>
				</Paper>
			</Container>

			{/* GET PATIENT/DOCTOR */}
			<Container component="main" maxWidth="xl">
				<form onSubmit={handleSubmit(getUser)}>
					<Paper
						variant="outlined"
						sx={{
							borderColor: '#1db14c',
							borderRadius: 2,
							boxShadow: 2,
							my: { xs: 3, md: 2 },
							p: { xs: 2, md: 3 }
						}}>
						<React.Fragment>
							<Typography variant="h6" gutterBottom>
								{getLangString('get_user_by_id')}
							</Typography>

							<Grid container spacing={3}>
								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											required
											select
											id="userType"
											name="userType"
											label={getLangString('user_type')}
											value={get_user_type}
											fullWidth
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												set_get_user_type(e.target.value);
												set_clinic_id_user_get('');
												set_epic_user_id('');
												set_epic_get_user_result({});
											}}>
											{users.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.value}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}

								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											required
											select
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											value={clinic_id_user_get}
											fullWidth
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												if (!isAdmin) return;
												set_clinic_id_user_get(e.target.value);
												set_epic_get_user_result({});
											}}>
											{clinics_array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}
								<Grid item xs={12} sm={12}>
									<TextField
										required
										id="userID"
										name="userID"
										label={getLangString('user_id')}
										fullWidth
										variant="outlined"
										value={epic_user_id}
										onChange={(e) => set_epic_user_id(e.target.value)}
									/>
									{errors.epic_user_id && <p>first name is required.</p>}
								</Grid>
							</Grid>

							<Grid item xs={12} xl={12} mt={4}>
								<Box textAlign="center">
									<Button
										variant="contained"
										color="success"
										type="submit"
										onClick={() => {
											set_epic_get_user_result({});
										}}>
										{getLangString('get')}
									</Button>
								</Box>
							</Grid>

							<Grid item xs={12} xl={12} mt={4}>
								{_.isEmpty(epic_get_user_result) ? (
									<></>
								) : get_user_type === 'Doctor' ? (
									<>
										<DoctorCard doctor_details={epic_get_user_result} />
										<Box textAlign="center" sx={{ mt: 3 }}>
											<Button
												variant="contained"
												color="success"
												type="submit"
												onClick={() => {
													navigate('/NewProvider', {
														state: {
															params: { ...epic_get_user_result, clinic_id: clinic_id_user_get }
														}
													});
												}}>
												{getLangString('import_doctor_to_myHealthPal')}
											</Button>
										</Box>
									</>
								) : (
									<>
										<PatientCard patient_details={epic_get_user_result} />{' '}
										<Box textAlign="center" sx={{ mt: 3 }}>
											<Button
												variant="contained"
												color="success"
												type="submit"
												onClick={() => {
													navigate('/NewPatient', {
														state: {
															params: { ...epic_get_user_result, clinic_id: clinic_id_user_get }
														}
													});
												}}>
												Import Patient to MyHealthPal
											</Button>
										</Box>
									</>
								)}
							</Grid>
						</React.Fragment>
					</Paper>
				</form>
			</Container>

			{/* SEARCH PATIENT/DOCTOR */}
			<Container component="main" maxWidth="xl">
				<Paper
					variant="outlined"
					sx={{
						borderColor: '#1db14c',
						borderRadius: 2,
						boxShadow: 2,
						p: { xs: 2, md: 3 }
					}}>
					<React.Fragment>
						<Typography variant="h6" gutterBottom>
							{getLangString('search_doctor_patient')}
						</Typography>

						<form onSubmit={handleSubmit(searchUsers)}>
							<Grid container spacing={3}>
								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											required
											select
											id="userType"
											name="userType"
											label={getLangString('user_type')}
											value={search_user_type}
											fullWidth
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												set_search_user_type(e.target.value);
												set_clinic_id_user_search('');
												set_fname('');
												set_lname('');
												set_dob(getTodayDate);
												set_epic_search_user_result({});
											}}>
											{users.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.value}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}

								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											required
											select
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											value={clinic_id_user_search}
											fullWidth
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												if (!isAdmin) return;
												set_clinic_id_user_search(e.target.value);
												set_fname('');
												set_lname('');
												set_dob(getTodayDate);
												set_epic_search_user_result({});
											}}>
											{clinics_array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}

								<Grid item xs={12} sm={4}>
									<TextField
										required
										id="firstName"
										name="firstName"
										label={getLangString('first_name')}
										fullWidth
										variant="outlined"
										value={fname}
										onChange={(e) => set_fname(e.target.value)}
									/>
									{errors.fname && <p>first name is required.</p>}
								</Grid>
								{/* <Grid item xs={12} sm={3}>
									<TextField
										id="MiddleName"
										name="MiddleName"
										label="Middle Name"
										fullWidth
										variant="outlined"
										value={mname}
										onChange={(e) => set_mname(e.target.value)}
									/>
								</Grid> */}
								<Grid item xs={12} sm={4}>
									<TextField
										required
										id="lastName"
										name="lastName"
										label={getLangString('last_name')}
										fullWidth
										variant="outlined"
										value={lname}
										onChange={(e) => set_lname(e.target.value)}
									/>
								</Grid>
								{search_user_type === 'Patient' ? (
									<Grid item xs={12} sm={4}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												required
												id="dob"
												name="dob"
												label={getLangString('date_of_birth')}
												openTo="year"
												views={['year', 'month', 'day']}
												onChange={(newValue) => {
													set_dob(newValue);
													console.log('newValue', newValue);
												}}
												inputFormat="MM-dd-yyyy"
												value={dob}
												renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
											/>
										</LocalizationProvider>
									</Grid>
								) : (
									<></>
								)}
							</Grid>

							<Grid item xs={12} xl={12} mt={4}>
								<Box textAlign="center">
									<Button
										variant="contained"
										color="success"
										type="submit"
										onClick={() => {
											set_epic_search_user_result({});
										}}>
										{getLangString('search')}
									</Button>
								</Box>
							</Grid>
						</form>
						<Grid item xs={12} xl={12} mt={4}>
							{_.isEmpty(epic_search_user_result) ? (
								<></>
							) : search_user_type === 'Doctor' ? (
								<>
									<Grid item xs={12} xl={12}>
										<UsersTab
											users={epic_search_user_result}
											user_type={'Doctor'}
											clinic_id={clinic_id_user_search}
										/>
									</Grid>
								</>
							) : (
								<>
									<Grid item xs={12} xl={12}>
										<UsersTab
											users={epic_search_user_result}
											user_type={'Patient'}
											clinic_id={clinic_id_user_search}
										/>
									</Grid>
								</>
							)}
						</Grid>
					</React.Fragment>
				</Paper>
			</Container>

			{/* GET Procedure */}
			<Container component="main" maxWidth="xl">
				<Paper
					variant="outlined"
					sx={{
						borderColor: '#1db14c',
						borderRadius: 2,
						boxShadow: 2,
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 3 }
					}}>
					<React.Fragment>
						<Typography variant="h6" gutterBottom>
							{getLangString('get_procedure')}
						</Typography>
						<form onSubmit={handleSubmit(getProcedure)}>
							<Grid container spacing={3}>
								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											required
											select
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											value={clinic_id_procedure_get}
											fullWidth
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												if (!isAdmin) return;
												set_clinic_id_procedure_get(e.target.value);
												set_epic_get_user_result({});
											}}>
											{clinics_array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}
								<Grid item xs={12} sm={12}>
									<TextField
										required
										id="procedureId"
										name="procedureId"
										label={getLangString('procedure_id')}
										fullWidth
										variant="outlined"
										value={procedure_id}
										onChange={(e) => set_procedure_id(e.target.value)}
									/>
									{errors.epic_user_id && <p>first name is required.</p>}
								</Grid>
							</Grid>

							<Grid item xs={12} xl={12} mt={4}>
								<Box textAlign="center">
									<Button
										variant="contained"
										color="success"
										type="submit"
										onClick={() => set_epic_get_procedure_result({})}>
										{getLangString('get')}
									</Button>
								</Box>
							</Grid>
						</form>

						<Grid item xs={12} xl={12} mt={4}>
							{_.isEmpty(epic_get_procedure_result) ? (
								<></>
							) : (
								<>
									<ProcedureCard procedure_details={epic_get_procedure_result} />
									<Box textAlign="center" sx={{ mt: 3 }}>
										<Button
											variant="contained"
											color="success"
											type="submit"
											onClick={() => {
												navigate('/NewPatientSurgery', {
													state: {
														params: {
															...epic_get_procedure_result,
															clinic_id: clinic_id_procedure_get
														}
													}
												});
											}}>
											{getLangString('import_procedure_to_myHealthPal')}
										</Button>
									</Box>
								</>
							)}
						</Grid>
					</React.Fragment>
				</Paper>
			</Container>

			{/* SEARCH Procedure */}
			<Container component="main" maxWidth="xl" sx={{ mt: 2 }}>
				<Paper
					variant="outlined"
					sx={{
						borderColor: '#1db14c',
						borderRadius: 2,
						boxShadow: 2,
						p: { xs: 2, md: 3 }
					}}>
					<React.Fragment>
						<Typography variant="h6" gutterBottom>
							{getLangString('search_procedure')}
						</Typography>

						<form onSubmit={handleSubmit(searchProcedure)}>
							<Grid container spacing={3}>
								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											required
											select
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											value={clinic_id_procedure_search}
											fullWidth
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												if (!isAdmin) return;
												set_clinic_id_procedure_search(e.target.value);
											}}>
											{clinics_array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}

								<Grid item xs={12} sm={12}>
									<TextField
										required
										id="patientID"
										name="patientID"
										label={getLangString('patient_id')}
										fullWidth
										variant="outlined"
										value={patient_id}
										onChange={(e) => set_patient_id(e.target.value)}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} xl={12} mt={4}>
								<Box textAlign="center">
									<Button
										variant="contained"
										color="success"
										type="submit"
										onClick={() => {
											set_epic_search_procedure_result({});
										}}>
										{getLangString('search')}
									</Button>
								</Box>
							</Grid>
						</form>
						<Grid item xs={12} xl={12} mt={4}>
							{_.isEmpty(epic_search_procedure_result) ? (
								<></>
							) : (
								<>
									<Grid item xs={12} xl={12}>
										<ProceduresTab
											procedures={epic_search_procedure_result}
											clinic_id={clinic_id_procedure_search}
										/>
									</Grid>
									{/* <ProcedureCard procedure_details={epic_search_procedure_result} />{' '}
									<Box textAlign="center" sx={{ mt: 3 }}>
										<Button
											variant="contained"
											color="success"
											type="submit"
											onClick={() => {
												navigate('/NewPatientSurgery', {
													state: {
														params: {
															...epic_search_procedure_result,
															clinic_id: clinic_id_user_search
														}
													}
												});
											}}>
											Import Doctor to MyHealthPal
										</Button>
									</Box> */}
								</>
							)}
						</Grid>
					</React.Fragment>
				</Paper>
			</Container>
		</ThemeProvider>
	);
};

export default Epic;
