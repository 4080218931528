import React from 'react';
import Paper from '@mui/material/Paper';
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Container,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Popper,
	Select,
	Slider,
	Stack,
	TextField,
	darken,
	lighten
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLanguage } from '../../context/LanguageProvider';
import { useEffect } from 'react';
import { COLORS } from '../../context/colors';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import ClearIcon from '@mui/icons-material/Clear';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react';
import { get, post } from '../../services/services';
import { useAuth } from '../../context/AuthProvider';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { genderArray, kpis, operators, surgeryType } from '../../helpers/constants';

const drawerWidth = 500;

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 0),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end'
}));

function isOverflown(element) {
	return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
	const { width, value } = props;
	const wrapper = React.useRef(null);
	const cellDiv = React.useRef(null);
	const cellValue = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showFullCell, setShowFullCell] = React.useState(false);
	const [showPopper, setShowPopper] = React.useState(false);

	const handleMouseEnter = () => {
		const isCurrentlyOverflown = isOverflown(cellValue.current);
		setShowPopper(isCurrentlyOverflown);
		setAnchorEl(cellDiv.current);
		setShowFullCell(true);
	};

	const handleMouseLeave = () => {
		setShowFullCell(false);
	};

	React.useEffect(() => {
		if (!showFullCell) {
			return undefined;
		}

		function handleKeyDown(nativeEvent) {
			// IE11, Edge (prior to using Bink?) use 'Esc'
			if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
				setShowFullCell(false);
			}
		}

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [setShowFullCell, showFullCell]);

	return (
		<Box
			ref={wrapper}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			sx={{
				alignItems: 'center',
				lineHeight: '24px',
				width: '100%',
				height: '100%',
				position: 'relative',
				display: 'flex'
			}}>
			<Box
				ref={cellDiv}
				sx={{
					height: '100%',
					width,
					display: 'block',
					position: 'absolute',
					top: 0
				}}
			/>
			<Box
				ref={cellValue}
				sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
				{value}
			</Box>
			{showPopper && (
				<Popper
					open={showFullCell && anchorEl !== null}
					anchorEl={anchorEl}
					style={{ width, paddingLeft: -17 }}>
					<Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
						<Typography variant="body2" style={{ padding: 8 }}>
							{value}
						</Typography>
					</Paper>
				</Popper>
			)}
		</Box>
	);
});

const Reporting = () => {
	const { tokenData } = useAuth();
	const isAdmin = tokenData?.role === 'admin';

	const { getLangString } = useLanguage();
	const [open, setOpen] = useState(false);
	// const [all_surgeries, set_all_surgeries] = useState([]);

	const [loading, set_loading] = useState(true);

	const [clinics, set_clinics] = useState([]);
	const [doctors, set_doctors] = useState([]);
	const [medications, set_medications] = useState([]);
	const [surgeries, set_surgeries] = useState([]);

	const [clinic_id, set_clinic_id] = useState(isAdmin ? '1' : tokenData?.clinic_id);
	const [doctor_id, set_doctor_id] = useState('');
	const [surgery_id, set_surgery_id] = useState('');
	const [all_cpt_codes, set_all_cpt_codes] = useState([]);
	const [procedure_code_obj, set_procedure_code_obj] = useState(null);
	const [selected_kpis, set_selected_kpis] = useState([]);

	// filters

	const [report, set_report] = useState([]);

	const [surgery_type, set_surgery_type] = useState('');
	const [procedure_code, set_procedure_code] = useState('');
	const [procedure_date, set_procedure_date] = useState([]);
	// format(subWeeks(new Date(), 1), 'yyyy-MM-dd'),
	// 	format(new Date(), 'yyyy-MM-dd')
	const [gender, set_gender] = useState('');
	const [age_range, set_age_range] = useState([0, 100]);
	const [zip_code, set_zip_code] = useState('');
	const [city, set_city] = useState('');
	const [state, set_state] = useState('');
	const [country, set_country] = useState('');
	const [address, set_address] = useState('');
	const [input_values, set_input_values] = useState([]);
	const [assigned_doctors, set_assigned_doctors] = useState([]);
	const [perscriptions, set_perscriptions] = useState([]);
	const [search_string, set_search_string] = useState('');

	const [rows, set_rows] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [pageSize, setPageSize] = useState(25);

	const convertAgeRangeToEpoch = (ageRange) => {
		const currentYear = new Date().getFullYear();
		const [lowAge, highAge] = ageRange;

		const lowAgeEpoch = Math.floor(new Date(currentYear - lowAge, 0, 1).getTime() / 1000);
		const highAgeEpoch = Math.floor(new Date(currentYear - highAge - 1, 11, 31).getTime() / 1000);

		return [lowAgeEpoch, highAgeEpoch];
	};

	const convertDateRangeToEpoch = (dateRange) => {
		return dateRange.map((date) => Math.floor(Date.parse(date) / 1000));
	};

	useEffect(() => {
		set_input_values(() => {
			const newInputValues = [];

			selected_kpis.forEach((value) => {
				if (value === 'temperature') newInputValues.push([`${value}`, '=', '36']);
				else newInputValues.push([`${value}`, '=', '1']);
			});

			return newInputValues;
		});
	}, [selected_kpis]);

	let filters = {
		clinic_id,
		...(search_string !== '' && { searchString: search_string }),
		...(surgery_type !== '' && { procedureType: surgery_type }),
		...(procedure_code !== '' && { procedureCode: procedure_code }),
		...(gender !== '' && { gender }),
		...(JSON.stringify(age_range) !== JSON.stringify([0, 100]) && {
			ageRange: JSON.stringify(convertAgeRangeToEpoch(age_range))
		}),
		...(zip_code !== '' && { zipCode: zip_code }),
		...(country !== '' && { country }),
		...(city !== '' && { city }),
		...(state !== '' && { state }),
		...(address !== '' && { address }),
		...(perscriptions.length !== 0 && { perscriptions: JSON.stringify(perscriptions) }),
		...(input_values.length !== 0 && { inputValues: JSON.stringify(input_values) }),
		...(procedure_date.length !== 0 && {
			procedureDate: JSON.stringify(convertDateRangeToEpoch(procedure_date))
		}),
		...(assigned_doctors.length !== 0 && { assignedDoctors: JSON.stringify(assigned_doctors) })
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleApplyFilter = async () => {
		const urlParams = { resource: 'user', action: 'searchByPatientSurgeryInfo' };
		let data = await get(urlParams, filters);
		set_rows(data.data);
		setOpen(false);
	};

	useEffect(() => {
		// on mount get data
		const fetchData = async () => {
			const urlParams = { resource: 'user', action: 'searchByPatientSurgeryInfo' };
			let data = await get(urlParams, {
				clinic_id
			});
			set_rows(data.data);

			const urlParams1 = { resource: 'user', action: 'getDoctorsByClinic' };
			let data1 = await post(urlParams1, { clinic_id, profile_pic: false });
			set_doctors(data1.data);

			const urlParams2 = { resource: 'procedure', action: 'getmedications' };
			const data2 = await post(urlParams2);
			set_medications(data2.data);

			const urlParams3 = { resource: 'clinic', action: 'getclinics' };
			let data3 = await post(urlParams3);
			set_clinics(data3.data);

			const urlParams4 = { resource: 'procedure', action: 'getCPTcode' };
			let data4 = await post(urlParams4);
			set_all_cpt_codes(data4.data);

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	useEffect(() => {
		// get doctors and procedures based on clinic id
		const fetchData = async () => {
			const urlParams = { resource: 'user', action: 'getDoctorsByClinic' };
			let data = await post(urlParams, { clinic_id, profile_pic: false });
			set_doctors(data.data);

			const urlParams2 = { resource: 'procedure', action: 'getProceduresByClinic' };
			let data2 = await get(urlParams2, {
				clinic_id
			});
			set_surgeries(data2.data);
		};
		fetchData().catch(console.error);
	}, [clinic_id]);

	function renderCellExpand(params) {
		return <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />;
	}

	let columns = [
		{ field: 'patientID', headerName: getLangString('patient_id'), width: 40 },
		{
			field: 'patientFname',
			headerName: getLangString('first_name'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'patientMname',
			headerName: getLangString('middle_name'),
			flex: 1,
			minWidth: 150
		},
		{
			field: 'patientLname',
			headerName: getLangString('last_name'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'patientDOB',
			headerName: getLangString('date_of_birth'),
			flex: 1,
			minWidth: 130,
			valueGetter: (params) => {
				const dateParts = params.value.split('T')[0].split('-');
				const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
				return formattedDate;
			}
		},
		{
			field: 'patientPhone',
			headerName: getLangString('phone'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'patientContactEmail',
			headerName: getLangString('contact_email'),
			flex: 1,
			minWidth: 130,
			renderCell: renderCellExpand
		},
		{
			field: 'patientCountry',
			headerName: getLangString('country'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'patientState',
			headerName: getLangString('state'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'patientCity',
			headerName: getLangString('city'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'patientZipCode',
			headerName: getLangString('zip_code'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'patientAddress',
			headerName: getLangString('address'),
			flex: 1,
			minWidth: 130,
			renderCell: renderCellExpand
		},
		{
			field: 'procedureId',
			headerName: getLangString('procedure_id'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'procedureTime',
			headerName: getLangString('surgery_date'),
			flex: 1,
			minWidth: 130,
			valueGetter: (params) => {
				const dateParts = params.value.split('T')[0].split('-');
				const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
				return formattedDate;
			}
		},
		{
			field: 'procedureType',
			headerName: getLangString('surgery_type'),
			flex: 1,
			minWidth: 130,
			renderCell: renderCellExpand
		},
		{
			field: 'procedureCode',
			headerName: getLangString('surgery_code'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'procedureDescription',
			headerName: getLangString('surgery_description'),
			flex: 1,
			renderCell: renderCellExpand,
			minWidth: 180
		},
		{
			field: 'procedureIsActive',
			headerName: getLangString('active'),
			flex: 1,
			minWidth: 130,
			valueGetter: (params) => {
				if (params.value === 1) return 'Yes';
				else return 'No';
			}
		},
		{
			field: 'medication_ids',
			headerName: getLangString('medications'),
			flex: 1,
			minWidth: 200,
			renderCell: renderCellExpand,
			valueGetter: (params) => {
				const medicationsIds = params.row.medicationIds.split(',').map(Number);
				const assignedMedications = medications.filter((med) =>
					medicationsIds.includes(med.medication_id)
				);
				const medicationsNames = assignedMedications.map((med) => med.medication_name);
				return medicationsNames.join(', ');
			}
		},
		{
			field: 'assignedDoctors',
			headerName: getLangString('assigned_doctors'),
			flex: 1,
			minWidth: 200,
			renderCell: renderCellExpand,
			valueGetter: (params) => {
				const assignedDoctorIds = params.row.assignedDoctors.split(',').map(Number);
				const assignedDoctors = doctors.filter((doctor) =>
					assignedDoctorIds.includes(doctor.doctor_id)
				);
				const doctorNames = assignedDoctors.map((doctor) => doctor.doctor_fullName);
				return doctorNames.join(', ');
			}
		}
	];
	let rowID = (row) => row.procedureId;
	let columnVisibilityModel = {
		patientID: false,
		procedureId: false,
		patientPhone: false,
		patientContactEmail: false,
		medication_ids: false,
		patientAddress: false,
		patientDOB: false,
		patientMname: false,
		procedureDescription: false
	};

	const GroupHeader = styled('div')(({ theme }) => ({
		position: 'sticky',
		top: '-8px',
		padding: '4px 10px',
		fontWeight: 'bold',
		fontSize: '18px',
		backgroundColor:
			theme.palette.mode === 'light'
				? lighten(theme.palette.primary.light, 1)
				: darken(theme.palette.primary.main, 1)
	}));

	const GroupItems = styled('ul')({
		padding: 0
	});

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				width: '100%',
				my: '30%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Container
				component="main"
				maxWidth="xl"
				sx={{
					mt: 2,
					height: '86.6vh',
					display: 'flex',
					flexDirection: 'column',
					position: 'relative'
				}}>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth
						}
					}}
					variant="persistent"
					anchor="right"
					open={open}>
					<DrawerHeader
						sx={{
							mt: 8
						}}>
						<Button
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '43px',
								width: '62px',
								padding: '0 10px',
								backgroundColor: COLORS.Green_100
							}}
							onClick={handleDrawerClose}>
							<CloseOutlinedIcon style={{ color: 'white' }} />
						</Button>
					</DrawerHeader>
					<Box
						sx={{
							m: 2,
							mt: 5,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignContent: 'center',
							gap: 5
						}}>
						{loading ? (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									height: '100%',
									width: '100%'
								}}>
								<CircularProgress />
							</Box>
						) : (
							<>
								{isAdmin && (
									<TextField
										select
										id="clinic"
										name="clinic"
										label={getLangString('clinic')}
										fullWidth
										variant="outlined"
										value={clinic_id}
										onChange={(e) => {
											set_clinic_id(e.target.value);
											set_doctor_id('');
										}}>
										{clinics.map((clinic) => (
											<MenuItem key={clinic.clinic_id} value={clinic.clinic_id}>
												<ListItemText primary={clinic.clinic_name} />
											</MenuItem>
										))}
									</TextField>
								)}
								<FormControl>
									<InputLabel id="demo-multiple-name-label">
										{getLangString('surgery_type')}
									</InputLabel>
									<Select
										id="doctor"
										name="doctor"
										label={getLangString('surgery_type')}
										fullWidth
										variant="outlined"
										value={surgery_type}
										onChange={(e) => {
											set_surgery_type(e.target.value);
										}}
										endAdornment={
											surgery_type ? (
												<IconButton
													sx={{ display: surgery_type ? '' : 'none', mr: 3 }}
													onClick={() => set_surgery_type('')}>
													<ClearIcon />
												</IconButton>
											) : null
										}>
										{surgeryType.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{/* <FormControl>
									<InputLabel id="demo-multiple-name-label">{getLangString('surgery')}</InputLabel>
									<Select
										id="surgery"
										name="surgery"
										label={getLangString('surgery')}
										fullWidth
										variant="outlined"
										value={surgery_id}
										onChange={(e) => set_surgery_id(e.target.value)}
										endAdornment={
											surgery_id ? (
												<IconButton
													sx={{ display: surgery_id ? '' : 'none', mr: 3 }}
													onClick={handleClearSurgeryClick}>
													<ClearIcon />
												</IconButton>
											) : null
										}>
										{surgeries.length === 0 ? (
											<MenuItem disabled>{getLangString('no_options_available')}</MenuItem>
										) : (
											surgeries.map((surgery) => (
												<MenuItem key={surgery.procedure_id} value={surgery.procedure_id}>
													<ListItemText
														primary={
															surgery.procedure_id +
															' - ' +
															surgery.procedure_type +
															' - ' +
															surgery.patient_full_name
														}
													/>
												</MenuItem>
											))
										)}
									</Select>
								</FormControl> */}
								<Autocomplete
									multiple
									disableCloseOnSelect
									limitTags={2}
									options={doctors}
									getOptionLabel={(doctor) => doctor.doctor_fullName}
									value={doctors.filter((doctor) => assigned_doctors.includes(doctor.doctor_id))}
									onChange={(event, values, reason) => {
										if (reason === 'clear') {
											set_assigned_doctors([]);
										} else {
											const selectedDoctorIds = values.map((doctor) => doctor.doctor_id);
											set_assigned_doctors(selectedDoctorIds);
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label={getLangString('assigned_doctors')}
											placeholder="Search doctors"
										/>
									)}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.doctor_id}>
												{option.doctor_fullName}
											</li>
										);
									}}
								/>
								<Autocomplete
									fullWidth
									disablePortal
									id="procedureCode"
									name="procedureCode"
									options={all_cpt_codes}
									getOptionLabel={(option) => {
										return option?.cpt_code;
									}}
									renderInput={(params) => (
										<TextField fullWidth {...params} label={getLangString('surgery_code')} />
									)}
									value={procedure_code_obj}
									isOptionEqualToValue={(option, value) => {
										return option?.id === value?.id;
									}}
									onChange={(event, newValue, reason) => {
										if (reason === 'clear') {
											set_procedure_code_obj(null);
											set_procedure_code('');
										} else {
											set_procedure_code_obj(newValue);
											set_procedure_code(newValue?.cpt_code);
										}
									}}
								/>
								<TextField
									id="searchString"
									name="searchString"
									label={getLangString('search_by_name_or_description')}
									fullWidth
									variant="outlined"
									value={search_string}
									onChange={(e) => set_search_string(e.target.value)}
								/>
								<FormControl>
									<InputLabel htmlFor="Select Gender">{getLangString('gender')}</InputLabel>
									<Select
										id="gender"
										name="gender"
										input={<OutlinedInput label={getLangString('gender')} />}
										fullWidth
										variant="outlined"
										value={gender}
										onChange={(e) => {
											set_gender(e.target.value);
										}}
										endAdornment={
											gender !== '' ? (
												<IconButton
													sx={{ display: gender ? '' : 'none', mr: 3 }}
													onClick={() => {
														set_gender('');
													}}>
													<ClearIcon />
												</IconButton>
											) : null
										}>
										{genderArray.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<Box sx={{ mx: 2 }}>
									<Typography id="input-slider" gutterBottom>
										{getLangString('age_range')}
									</Typography>
									<Slider
										// valueLabelDisplay="on"
										getAriaLabel={() => {
											getLangString('age_range');
										}}
										value={age_range}
										onChange={(event, newValue) => {
											set_age_range(newValue);
										}}
										valueLabelDisplay="auto"
										getAriaValueText={() => getLangString('age_range')}
									/>
								</Box>
								<TextField
									id="address"
									name="address"
									label={getLangString('address')}
									fullWidth
									variant="outlined"
									value={address}
									onChange={(e) => set_address(e.target.value)}
								/>
								<TextField
									id="city"
									name="city"
									label={getLangString('city')}
									fullWidth
									variant="outlined"
									value={city}
									onChange={(e) => set_city(e.target.value)}
								/>
								<TextField
									id="state"
									name="state"
									label={getLangString('state')}
									fullWidth
									variant="outlined"
									value={state}
									onChange={(e) => set_state(e.target.value)}
								/>
								<TextField
									id="zipcode"
									name="zipcode"
									label={getLangString('zip_code')}
									fullWidth
									variant="outlined"
									value={zip_code}
									onChange={(e) => set_zip_code(e.target.value)}
								/>
								<TextField
									id="country"
									name="country"
									label={getLangString('country')}
									fullWidth
									variant="outlined"
									value={country}
									onChange={(e) => set_country(e.target.value)}
								/>

								<Stack spacing={2}>
									<Box
										sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
										<Typography>{getLangString('procedure_date')}</Typography>
										<IconButton
											aria-label="Clear"
											color="success"
											onClick={() => set_procedure_date([])}>
											<ClearIcon color="success" />
										</IconButton>
									</Box>
									<Stack direction="row" spacing={2}>
										<FormControl fullWidth>
											<TextField
												id="monitoringPeriod"
												name="monitoringPeriod"
												label={getLangString('from')}
												type="date"
												fullWidth
												// inputFormat="yyyy-mm-dd"
												value={procedure_date[0] ?? ''}
												onChange={(event) => {
													const newFromDate = event.target.value;
													const toDate = procedure_date[1];
													set_procedure_date([newFromDate, toDate]);
												}}
												InputLabelProps={{
													shrink: true
												}}
											/>
										</FormControl>
										<FormControl fullWidth>
											<TextField
												id="monitoringPeriod"
												name="monitoringPeriod"
												label={getLangString('to')}
												type="date"
												fullWidth
												value={procedure_date[1] ?? ''}
												onChange={(event) => {
													const fromDate = procedure_date[0];
													const newToDate = event.target.value;
													set_procedure_date([fromDate, newToDate]);
												}}
												InputLabelProps={{
													shrink: true
												}}
											/>
										</FormControl>
									</Stack>
								</Stack>

								{/* <Autocomplete
									multiple
									disableCloseOnSelect
									options={medications}
									getOptionLabel={(med) => `${med.medication_name} - ${med.medication_dose}`}
									value={medications.filter((med) => perscriptions.includes(med.medication_id))}
									onChange={(event, values) => {
										const selectedMedIds = values.map((med) => med.medication_id);
										set_perscriptions(selectedMedIds);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label={getLangString('prescription_medications')}
											placeholder="Search Medications"
										/>
									)}
								/> */}
								<Autocomplete
									fullWidth
									multiple
									disableCloseOnSelect
									limitTags={2}
									id="medicationName"
									name="medicationName"
									options={medications?.sort((a, b) =>
										a.medication_group?.localeCompare(b.medication_group)
									)}
									groupBy={(option) => option.medication_group}
									renderGroup={(params) => (
										<li key={params.key}>
											<GroupHeader>{params.group}</GroupHeader>
											<GroupItems>{params.children}</GroupItems>
										</li>
									)}
									getOptionLabel={(option) =>
										`${option.medication_name} - ${option.medication_dose}`
									}
									renderInput={(params) => (
										<TextField
											fullWidth
											{...params}
											label={getLangString('prescription_medications')}
											placeholder="Search Medications"
										/>
									)}
									noOptionsText={
										<MenuItem disabled>
											<Typography sx={{ fontSize: '16px', color: 'black' }}>
												{getLangString('no_options_available')}
											</Typography>
										</MenuItem>
									}
									value={medications.filter((med) => perscriptions.includes(med.medication_id))}
									isOptionEqualToValue={(option, value) =>
										option?.medication_id === value?.medication_id
									}
									onChange={(event, values) => {
										const selectedMedIds = values.map((med) => med.medication_id);
										set_perscriptions(selectedMedIds);
									}}
								/>

								<Autocomplete
									fullWidth
									multiple
									disableCloseOnSelect
									limitTags={2}
									id="kpis"
									name="kpis"
									options={kpis?.sort((a, b) => a.type?.localeCompare(b.type)).reverse()}
									groupBy={(option) => option.type}
									renderGroup={(params) => (
										<li key={params.key}>
											<GroupHeader>{getLangString(params.group)}</GroupHeader>
											<GroupItems>{params.children}</GroupItems>
										</li>
									)}
									getOptionLabel={(option) => getLangString(option.kpi)}
									renderInput={(params) => (
										<TextField fullWidth {...params} label={getLangString('patient_inputs')} />
									)}
									noOptionsText={
										<MenuItem disabled>
											<Typography sx={{ fontSize: '16px', color: 'black' }}>
												{getLangString('no_options_available')}
											</Typography>
										</MenuItem>
									}
									value={kpis.filter((kpi) => selected_kpis.includes(kpi.kpi))}
									isOptionEqualToValue={(option, value) => {
										return option?.kpi === value?.kpi;
									}}
									onChange={(event, values) => {
										const selectedKPIs = values.map((option) => option.kpi);
										set_selected_kpis(selectedKPIs);
									}}
								/>
								{input_values.length > 0
									? input_values.map((item, index) => (
										<Stack key={index} direction="row" spacing={1}>
											<TextField
												id="kpiField"
												name="kpiField"
												label={getLangString('field')}
												fullWidth
												variant="outlined"
												value={getLangString(item[0])}
												InputProps={{
													readOnly: true
												}}
											/>
											<TextField
												select
												id="operator"
												name="operator"
												label={getLangString('operator')}
												fullWidth
												variant="outlined"
												value={item[1] === '' ? '=' : item[1]}
												onChange={(e) => {
													const updatedInputValues = [...input_values];
													updatedInputValues[index][1] = e.target.value;
													set_input_values(updatedInputValues);
												}}>
												{operators.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</TextField>
											{item[0] === 'temperature' ? (
												<TextField
													select
													id="value"
													name="value"
													label={getLangString('value')}
													fullWidth
													variant="outlined"
													value={item[2] === '' ? 36 : item[2]}
													onChange={(e) => {
														const updatedInputValues = [...input_values];
														updatedInputValues[index][2] = e.target.value;
														set_input_values(updatedInputValues);
													}}>
													{[35, 36, 37, 38, 39, 40, 41, 42].map((option) => (
														<MenuItem key={option} value={option}>
															{option}
														</MenuItem>
													))}
												</TextField>
											) : (
												<TextField
													select
													id="value"
													name="value"
													label={getLangString('value')}
													fullWidth
													variant="outlined"
													value={item[2] === '' ? 1 : item[2]}
													onChange={(e) => {
														const updatedInputValues = [...input_values];
														updatedInputValues[index][2] = e.target.value;
														set_input_values(updatedInputValues);
													}}>
													{[0, 1].map((option) => (
														<MenuItem key={option} value={option}>
															{option}
														</MenuItem>
													))}
												</TextField>
											)}
										</Stack>
									))
									: null}
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignContent: 'center'
									}}>
									<Button
										onClick={handleDrawerClose}
										sx={{
											width: '54px',
											height: '45px',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 600,
											fontSize: '16px',
											lineHeight: '19px',
											color: '#9BB5CE',
											borderRadius: '4px',
											mr: '28px',
											mb: '50px'
										}}>
										{getLangString('cancel')}
									</Button>
									<Button
										sx={{
											// position: 'absolute',
											width: '142px',
											height: '40.37px',
											background: COLORS.RaisinPurple,
											borderRadius: '4px',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 600,
											fontSize: '16px',
											lineHeight: '19px'
										}}
										onClick={handleApplyFilter}
										variant="contained">
										{getLangString('apply')}
									</Button>
								</Box>
							</>
						)}
					</Box>
				</Drawer>
				<Paper
					sx={{
						p: 4,
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1
						// overflow: 'hidden'
					}}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							sx={{
								fontFamily: 'Inter',
								fontStyle: 'normal',
								fontWeight: 600,
								fontSize: '26px',
								lineHeight: '31px',
								flexGrow: 1
							}}>
							{getLangString('reporting')}
						</Typography>

						<Button
							variant="contained"
							color="success"
							type="submit"
							sx={{ width: '109px', height: '43px', backgroundColor: COLORS.Green_100 }}
							onClick={handleDrawerOpen}>
							{getLangString('filter')}
						</Button>
					</Box>

					<Box
						sx={{
							display: 'flex',
							height: '100%',
							// minHeight: 500,
							width: '100%',
							mt: 2
						}}>
						<DataGrid
							columns={columns}
							rows={rows}
							page={pageNumber}
							onPageChange={(newPage) => setPageNumber(newPage)}
							pageSize={pageSize}
							// onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							pagination
							rowsPerPageOptions={[25]}
							autoHeight
							checkboxSelection
							disableSelectionOnClick
							getRowId={rowID}
							initialState={{
								columns: {
									columnVisibilityModel: columnVisibilityModel
								},
								pagination: {
									// page: 0,
									// pageSize: 25
								}
							}}
							components={{
								Toolbar: GridToolbar
							}}
							componentsProps={{
								toolbar: {
									showQuickFilter: true,
									quickFilterProps: { debounceMs: 500 }
								}
							}}
						// localeText={getLangString('localizedTextsMap')}
						// localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
						/>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default Reporting;
