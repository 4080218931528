import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Container, Paper } from '@mui/material';
import { getListPost } from '../../services/services';
import FileHandlerForm from '../../components/CsvFileHandler';
import { doctor_columns } from './BulkDataArrays';

const BulkDoctors = () => {
	const [loading, set_loading] = useState(true);
	const [clinics_Array, set_clinics_Array] = useState([]);

	const rowID_doctor = (row) => row.doctor_username;
	const rowID_doctor_result = (row) => row.doctor_email;

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'clinic', action: 'getclinics' };
			let data = await getListPost(urlParams);
			set_clinics_Array(data.data);
			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c',
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 3 }
					}}>
					<FileHandlerForm
						title={'Add Bulk Doctors'}
						columns={doctor_columns}
						urlParams={{ resource: 'user', action: 'addBulkDoctors' }}
						csv_columns={[
							'doctor_username_isPhone',
							'doctor_username', // username
							'doctor_password',
							'doctor_fname',
							'doctor_mname',
							'doctor_lname',
							'doctor_dob',
							'doctor_gender',
							'doctor_phone',
							'doctor_department',
							'doctor_role',
							'doctor_contact_email',
							'doctor_city',
							'doctor_state',
							'doctor_country',
							'doctor_zip_code',
							'doctor_address2',
							'doctor_brief'
						]}
						tb_user_columns={doctor_columns}
						clinics_Array={clinics_Array}
						rowID={rowID_doctor}
						tb_result_columns={[
							{
								field: 'doctor_username',
								headerName: 'Username',
								width: 220
							},
							{ field: 'created', headerName: 'Created', type: 'boolean', width: 120 },
							{
								field: 'message',
								headerName: 'Message',
								flex: 1,
								minWidth: 200
							}
						]}
					/>
				</Paper>
			</Container>
		</>
	);
};

export default BulkDoctors;
