import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import CustomizedAlertBar from '../../components/customAlertBar';
import {
	CssBaseline,
	Container,
	Toolbar,
	Paper,
	Stack,
	TextField,
	Button,
	MenuItem,
	Grid
} from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { useRef } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { post } from '../../services/services';
import ListGrid from '../../components/customDataGrid';
import { useLanguage } from '../../context/LanguageProvider';

const Integration = (params) => {
	const alertRef = useRef(null);
	const { getLangString } = useLanguage();


	const { tokenData } = useAuth();
	const isAdmin = tokenData?.role === 'admin';

	const [integration_name, set_integration_name] = useState('');

	const [fname, set_fname] = useState('');

	const [loading, set_loading] = useState(true);
	const [loaded, set_loaded] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const addIntegration = async () => {
		try {
			const urlParams = { resource: 'integration', action: 'addIntegration' };
			const response = await post(urlParams, {
				integration_name
			});

			console.log('response', response);

			alertRef.current.handleClick({
				message: response.toString().toUpperCase(),
				severity: 'success'
			});
		} catch (err) {
			console.log('err', err);
			alertRef.current.handleClick({
				message: err.status !== 404 ? err.toString() : 'Failed!',
				severity: 'error'
			});
		}
	};

	return (
		<>
			<CustomizedAlertBar ref={alertRef} />
			<CssBaseline />
			<Container component="main" maxWidth="xl" sx={{ mt: 4 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Stack
						direction="row"
						spacing={1}
						sx={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start'
							}}>
							<Toolbar>
								<Typography variant="h6" align="left" color="#1db14c" sx={{}}>
								{getLangString('integration')}
								</Typography>
							</Toolbar>
						</Box>
					</Stack>
				</Paper>
			</Container>
			<Container component="main" maxWidth="xl">
				<Paper
					variant="outlined"
					sx={{
						borderColor: '#1db14c',
						borderRadius: 2,
						boxShadow: 2,
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 3 }
					}}>
					<React.Fragment>
						<Typography variant="h6" gutterBottom>
						{getLangString('add_integration')}
						</Typography>

						<form onSubmit={handleSubmit(addIntegration)}>
							<Grid container spacing={3}>
								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											required
											id="integrationName"
											name="integrationName"
											label={getLangString('integration_name')}
											value={integration_name}
											fullWidth
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												set_integration_name(e.target.value);
											}}
										/>
									</Grid>
								)}
							</Grid>

							<Grid item xs={12} xl={12} mt={4}>
								<Box textAlign="center">
									<Button variant="contained" color="success" type="submit">
									{getLangString('add')}
									</Button>
								</Box>
							</Grid>
						</form>
					</React.Fragment>
				</Paper>
			</Container>
		</>
	);
};

export default Integration;
