import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import {
	Grid,
	Container,
	Paper,
	Toolbar,
	Typography,
	TextField,
	Stack,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	MenuItem,
	Button,
	IconButton,
	Box,
	CircularProgress,
	Divider
} from '@mui/material';
import { useAuth } from '../context/AuthProvider';
import { create } from '../services/services';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { parse } from 'csv-parse/browser/esm/sync';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import assert from 'assert';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const FileHandlerForm = (props) => {
	const {
		title,
		csv_columns,
		tb_user_columns,
		tb_result_columns,
		urlParams,
		clinics_Array,
		rowID
	} = props;

	const alertRef = useRef(null);
	const inputRef = useRef(null);

	const { tokenData } = useAuth();
	const isAdmin = tokenData?.role === 'admin';

	const [user_type, set_user_type] = useState('Doctor');

	const [clinic_id, set_clinic_id] = useState('1');

	const [csvData, setCsvData] = useState([]);
	const [filename, setFilename] = useState('');

	const [tb_result_rows, set_tb_result_rows] = useState([]);

	const handleFileUpload = (e) => {
		if (!e.target.files) {
			return;
		}
		console.log('e.target.files', e.target.files);
		const file = e.target.files[0];
		const { name } = file;
		setFilename(name);

		const reader = new FileReader();
		reader.onload = (evt) => {
			if (!evt?.target?.result) {
				return;
			}
			console.log('!evt?.target?.result', !evt);
			const { result } = evt.target;
			console.log(result);
			const records = parse(
				result,
				{
					columns: csv_columns,
					delimiter: ',',
					trim: true,
					fromLine: 2,
					skip_empty_lines: true,
					skip_records_with_error: true
				},
				function (err, data) {
					console.log('first', data);

					assert.deepStrictEqual(records, []);
				}
			);
			setCsvData(records);
		};
		reader.readAsBinaryString(file);
	};

	const handleRemoveFile = () => {
		setFilename('');
		setCsvData([]);
		set_clinic_id('1');
		set_tb_result_rows([]);
		inputRef.current.value = null;
	};

	const handleSubmit = async () => {
		try {
			const response = await create(urlParams, { clinic_id, data: csvData });
			console.log(response);

			// let added_users = response.data.data.created_users;
			let added_users = response.data.data.created_users.map((e) => {
				// console.log('response.data.data.created_users[e]', e);
				e.message = 'User Created Successfully';
				e.created = true;
				return e;
			});

			let failed_users = response.data.data.failed_users.map((e) => {
				e.message = e.err.message
					? `User Not Created - ${e.err.message}`
					: `User Not Created - ${e.err}`;
				delete e.err;
				e.created = false;
				return e;
			});

			console.log('added_users', added_users);
			console.log('failed_users', failed_users);

			set_tb_result_rows(added_users.concat(failed_users));

			// if (response.status === 200 || response.status === 201)
			// 	alertRef.current.handleClick({
			// 		message: response.data.data.toUpperCase(),
			// 		severity: 'success'
			// 	});
			// resetState();
		} catch (err) {
			// alertRef.current.handleClick({
			// 	message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
			// 	severity: 'error'
			// });
			console.log('err', err);
		}
	};

	useEffect(() => {
		console.log('CsvData', csvData);
		console.log('tb_result_rows', tb_result_rows);
	}, [csvData, tb_result_rows]);

	return (
		<>
			<Toolbar>
				<Typography variant="h6" align="left" color="#1db14c">
					{title}
				</Typography>
				<IconButton aria-label="Template" alt="Download Template File">
					<AddCircleOutlineOutlinedIcon color="success" />
				</IconButton>
			</Toolbar>
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={4}>
						<FormControl fullWidth>
							<InputLabel htmlFor="clinic">Hospital / Clinic</InputLabel>
							<Select
								id="clinic"
								name="clinic"
								input={<OutlinedInput label="Hospital / Clinic" />}
								fullWidth
								variant="outlined"
								value={clinic_id}
								disabled={!isAdmin}
								onChange={(e) => {
									isAdmin && set_clinic_id(e.target.value);
								}}>
								{clinics_Array.map((option) => (
									<MenuItem key={option.clinic_id} value={option.clinic_id}>
										{option.clinic_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justifyContent="center">
						<Button
							variant="contained"
							component="label"
							endIcon={<FileUploadOutlinedIcon />}
							color="success">
							Upload CSV
							{/* <input hidden accept="image/*" multiple type="file" /> */}
							<input ref={inputRef} type="file" accept=".csv" hidden onChange={handleFileUpload} />
						</Button>
						<Box>
							{filename !== '' ? (
								<Stack direction="row" alignItems="center" justifyContent="center">
									{filename}
									<IconButton aria-label="Remove" onClick={handleRemoveFile}>
										<HighlightOffOutlinedIcon />
									</IconButton>
								</Stack>
							) : (
								<></>
							)}
						</Box>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography variant="h6" align="left" color="#1db14c">
							File Data
						</Typography>
						<div style={{ maxHeight: 500, width: '100%', overflow: 'auto' }}>
							<DataGrid
								autoHeight
								rows={csvData}
								columns={tb_user_columns}
								getRowId={rowID}
								pageSize={5}
								rowsPerPageOptions={[5]}
								checkboxSelection={true}
								// hideFooter
								// disableSelectionOnClick
								sx={{ mt: 1 }}
							/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Divider sx={{ my: 3 }} variant="middle" />
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography variant="h6" align="left" color="#1db14c">
							RESULT
						</Typography>
						<Box sx={{ maxHeight: 500, width: '100%', overflow: 'auto' }}>
							<DataGrid
								autoHeight
								rows={tb_result_rows}
								columns={tb_result_columns}
								pageSize={5}
								rowsPerPageOptions={[5]}
								// hideFooter
								disableSelectionOnClick
								getRowId={rowID}
								sx={{ mt: 1 }}
								components={{ Toolbar: GridToolbar }}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} xl={12}>
						<Box textAlign="right">
							<Button variant="contained" color="success" type="submit" onClick={handleSubmit}>
								Submit
							</Button>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		</>
	);
};

export default FileHandlerForm;
