const doctor_columns = [
	{
		field: 'doctor_username_isPhone',
		headerName: 'Is Phone',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_username',
		headerName: 'Username',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_password',
		headerName: 'Password',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_fname',
		headerName: 'First Name',
		flex: 1,
		minWidth: 110,
		editable: true
	},
	{
		field: 'doctor_mname',
		headerName: 'Middle Name',
		flex: 1,
		minWidth: 110,
		editable: true
	},
	{
		field: 'doctor_lname',
		headerName: 'Last Name',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_dob',
		headerName: 'Birthday',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_gender',
		headerName: 'Gender',
		flex: 1,
		minWidth: 90,
		editable: true
	},
	{
		field: 'doctor_phone',
		headerName: 'Phone',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_department',
		headerName: 'Department',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_role',
		headerName: 'Role',
		flex: 1,
		minWidth: 110,
		editable: true
	},
	{
		field: 'doctor_contact_email',
		headerName: 'Contact Email',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'doctor_city',
		headerName: 'City',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'doctor_country',
		headerName: 'Country',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'doctor_state',
		headerName: 'State',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'doctor_zip_code',
		headerName: 'Zip Code',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'doctor_address2',
		headerName: 'Second Address',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'doctor_brief',
		headerName: 'Brief',
		flex: 1,
		minWidth: 130,
		editable: true
	}
];

const patient_columns = [
	{
		field: 'patient_username_isPhone',
		headerName: 'Is Phone',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'patient_username',
		headerName: 'Username',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'patient_password',
		headerName: 'Password',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'patient_fname',
		headerName: 'First Name',
		flex: 1,
		minWidth: 110,
		editable: true
	},
	{
		field: 'patient_mname',
		headerName: 'Middle Name',
		flex: 1,
		minWidth: 110,
		editable: true
	},
	{
		field: 'patient_lname',
		headerName: 'Last Name',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'patient_dob',
		headerName: 'Birthday',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'patient_gender',
		headerName: 'Gender',
		flex: 1,
		minWidth: 90,
		editable: true
	},
	{
		field: 'patient_phone',
		headerName: 'Phone',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'patient_contact_email',
		headerName: 'Contact Email',
		flex: 1,
		minWidth: 130,
		editable: true
	},
	{
		field: 'patient_city',
		headerName: 'City',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'patient_country',
		headerName: 'Country',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'patient_state',
		headerName: 'State',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'patient_zip_code',
		headerName: 'Zip Code',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'patient_address2',
		headerName: 'Second Address',
		flex: 1,
		minWidth: 150,
		editable: true
	},
	{
		field: 'patient_brief',
		headerName: 'Brief',
		flex: 1,
		minWidth: 130,
		editable: true
	}
];

export { doctor_columns, patient_columns };
