import React from 'react';
import { Typography, Box } from '@mui/material';
import _ from 'lodash';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import FeedBack from '../feedback/feedback';
import Support from '../Support';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export default function ContactUs() {
	return (
		<>
			<Support />
			<FeedBack />

			<Container component="main" maxWidth="xl" sx={{ my: 1, height: 80 }}>
				<Box
					component={Paper}
					sx={{
						display: 'flex',
						borderRadius: 2,
						justifyContent: 'center',
						alignItems: 'center',
						height: 80,
						gap: 3
					}}>
					<Box sx={{ display: 'flex', gap: 1 }}>
						<LocalPhoneOutlinedIcon sx={{ color: '#E56CC6' }} />
						<Typography>+1 081-1236-4568</Typography>
					</Box>
					<Box sx={{ display: 'flex', gap: 1 }}>
						<EmailOutlinedIcon sx={{ color: '#E56CC6' }} />
						<Typography>info@predictivecare.ai</Typography>
					</Box>
				</Box>
			</Container>
		</>
	);
}
