export let API_ENDPOINT = 'https://1ssy7a8pr8.execute-api.us-east-1.amazonaws.com/prod';


// 'https://api-dev.predictivecare.ai';
// export let API_ENDPOINT = 'http://localhost:3000';

console.log('-----> API_ENDPOINT', API_ENDPOINT);
console.log('-----> process.env.ENV', process.env);

// if (process.env.ENV === 'local') {
// 	API_ENDPOINT = 'http://localhost:3000';
// https://1ssy7a8pr8.execute-api.us-east-1.amazonaws.com/prod
// } else if (process.env.ENV === 'development') {
// 	API_ENDPOINT = 'https://api-dev.predictivecare.ai';
// } else if (process.env.ENV === 'stagging') {
// 	API_ENDPOINT = '';
// } else if (process.env.ENV === 'production') {
// 	API_ENDPOINT = '';
// }

// http://localhost:3000
// https://api-dev.predictivecare.ai

export const TermsOfServiceURL =
	'https://apkbucket.s3.amazonaws.com/PHC++Clinician+Terms+of+Use.pdf';

export const genderArray = [
	{
		value: 'Male',
		label: 'Male'
	},
	{
		value: 'Female',
		label: 'Female'
	}
	// {
	// 	value: 'Transgender',
	// 	label: 'Transgender'
	// },
	// {
	// 	value: 'Client Doesn’t Know',
	// 	label: 'Client Doesn’t Know'
	// },
	// {
	// 	value: 'Questioning',
	// 	label: 'Questioning'
	// },
	// {
	// 	value: 'Culturally Specific Gender',
	// 	label: 'Culturally Specific Gender'
	// },
	// {
	// 	value: 'Client Refused',
	// 	label: 'Client Refused'
	// },
	// {
	// 	value: 'Data Not Collected',
	// 	label: 'Data Not Collected'
	// },

	// {
	// 	value: 'Non-binary',
	// 	label: 'Non-binary'
	// },
	// {
	// 	value: 'Genderfluid',
	// 	label: 'Genderfluid'
	// },
	// {
	// 	value: 'Agender',
	// 	label: 'Agender'
	// }
];

export const providerRole = [
	{
		value: 'Assistant Surgeon',
		label: 'Assistant Surgeon'
	},
	{
		value: 'Anesthesiologist',
		label: 'Anesthesiologist'
	},
	{
		value: 'CRNA',
		label: 'CRNA'
	},
	{
		value: 'Medical Student',
		label: 'Medical Student'
	},
	{
		value: 'Principle Investigator',
		label: 'Principle Investigator'
	},
	{
		value: 'Clinical Research Coordinator',
		label: 'Clinical Research Coordinator'
	},
	{
		value: 'Nurse Practitioner',
		label: 'Nurse Practitioner'
	},
	{
		value: 'OR Nurse',
		label: 'OR Nurse'
	},

	{
		value: 'Physician Assistant',
		label: 'Physician Assistant'
	},
	{
		value: 'Radiology Tech',
		label: 'Radiology Tech'
	},

	{
		value: 'Resident',
		label: 'Resident'
	},
	{
		value: 'RN',
		label: 'RN'
	},
	{
		value: 'Resident Surgeon',
		label: 'Resident Surgeon'
	},

	{
		value: 'Surgeon',
		label: 'Surgeon'
	},

	{
		value: 'Surgical Tech',
		label: 'Surgical Tech'
	}
];

export const vitalSigns = {
	temperature: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	pulse: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	oxygen_saturation: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	respiratory_rate: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	blood_pressure: { enable: '0', frequency: '0', start_date: '', end_date: '' }
};

export const woundInputs = {
	image_url: { enable: '1', frequency: '1', start_date: '', end_date: '' },
	pain: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	odor: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	wound_temperature: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	wound_swelling: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	bleeding: { enable: '0', frequency: '0', start_date: '', end_date: '' }
};

export const wellBeing = {
	sleep_issues: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	breathing_issues: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	dizziness: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	balance: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	nausea: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	vomiting: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	chills: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	appetite: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	urination: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	defecation: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	physical_activity: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	weight_changes: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	irritability: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	anxiety: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	depression: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	fatigue: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	restlessness: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	memory_problems: { enable: '0', frequency: '0', start_date: '', end_date: '' },
	mental_fog: { enable: '0', frequency: '0', start_date: '', end_date: '' }
};

export const surgeryType = [
	{ label: 'Bariatric Surgery', value: 'Bariatric Surgery' },
	{ label: 'Breast Surgery', value: 'Breast Surgery' },
	{ label: 'Colon & Rectal Surgery', value: 'Colon & Rectal Surgery' },
	{ label: 'Endocrine Surgery', value: 'Endocrine Surgery' },
	{ label: 'General Surgery', value: 'General Surgery' },
	{ label: 'Gynecological Surgery', value: 'Gynecological Surgery' },
	{ label: 'Hand Surgery', value: 'Hand Surgery' },
	{ label: 'Head & Neck Surgery', value: 'Head & Neck Surgery' },
	{ label: 'Hernia Surgery', value: 'Hernia Surgery' },
	{ label: 'Neurosurgery', value: 'Neurosurgery' },
	{ label: 'Orthopedic Surgery', value: 'Orthopedic Surgery' },
	{ label: 'Ophthalmological Surgery', value: 'Ophthalmological Surgery' },
	{ label: 'Plastic & Reconstructive Surgery', value: 'Plastic & Reconstructive Surgery' },
	{ label: 'Thoracic Surgery', value: 'Thoracic Surgery' },
	{ label: 'Trauma Surgery', value: 'Trauma Surgery' },
	{ label: 'Urologic Surgery', value: 'Urologic Surgery' },
	{ label: 'Vascular Surgery', value: 'Vascular Surgery' }
];

export const kpis = [
	{ kpi: 'temperature', type: 'vital_signs' },
	{ kpi: 'pulse', type: 'vital_signs' },
	{ kpi: 'blood_pressure', type: 'vital_signs' },
	{ kpi: 'respiratory_rate', type: 'vital_signs' },
	{ kpi: 'oxygen_saturation', type: 'vital_signs' },

	{ kpi: 'pain', type: 'wound_inputs' },
	{ kpi: 'odor', type: 'wound_inputs' },
	{ kpi: 'wound_temperature', type: 'wound_inputs' },
	{ kpi: 'wound_swelling', type: 'wound_inputs' },
	{ kpi: 'bleeding', type: 'wound_inputs' },
	// { kpi: 'wound_photo', type: 'wound_inputs' },

	{ kpi: 'sleep_issues', type: 'well_being' },
	{ kpi: 'breathing_issues', type: 'well_being' },
	{ kpi: 'dizziness', type: 'well_being' },
	{ kpi: 'balance', type: 'well_being' },
	{ kpi: 'nausea', type: 'well_being' },
	{ kpi: 'vomiting', type: 'well_being' },
	{ kpi: 'appetite', type: 'well_being' },
	{ kpi: 'chills', type: 'well_being' },
	{ kpi: 'defecation', type: 'well_being' },
	{ kpi: 'urination', type: 'well_being' },
	{ kpi: 'physical_activity', type: 'well_being' },
	{ kpi: 'weight_changes', type: 'well_being' },
	{ kpi: 'irritability', type: 'well_being' },
	{ kpi: 'anxiety', type: 'well_being' },
	{ kpi: 'depression', type: 'well_being' },
	{ kpi: 'fatigue', type: 'well_being' },
	{ kpi: 'restlessness', type: 'well_being' },
	{ kpi: 'memory_problems', type: 'well_being' },
	{ kpi: 'mental_fog', type: 'well_being' }
];

export const kpi_list = {
	vital_signs: {
		temperature: 'temperature',
		pulse: 'pulse',
		blood_pressure: 'blood_pressure',
		respiratory_rate: 'respiratory_rate',
		oxygen_saturation: 'oxygen_saturation'
	},
	wound_inputs: {
		pain: 'pain',
		odor: 'odor',
		wound_temperature: 'wound_temperature',
		wound_swelling: 'wound_swelling',
		bleeding: 'bleeding',
		wound_photo: 'image_url'
	},
	well_being: {
		sleep_issues: 'sleep_issues',
		breathing_issues: 'breathing_issues',
		dizziness: 'dizziness',
		balance: 'balance',
		nausea: 'nausea',
		vomiting: 'vomiting',
		chills: 'chills',
		appetite: 'appetite',
		urination: 'urination',
		defecation: 'defecation',
		physical_activity: 'physical_activity',
		weight_changes: 'weight_changes',
		irritability: 'irritability',
		anxiety: 'anxiety',
		depression: 'depression',
		fatigue: 'fatigue',
		restlessness: 'restlessness',
		memory_problems: 'memory_problems',
		mental_fog: 'mental_fog'
	}
};

export const operators = [
	{ value: '=', label: 'Equal' },
	{ value: '>', label: 'Greater Than' },
	{ value: '>=', label: 'Greater Than or Equal' },
	{ value: '<', label: 'Less Than' },
	{ value: '<=', label: 'Less Than or Equal' }
];
