import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { LanguageProvider } from './context/LanguageProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<LanguageProvider>
		<AuthProvider>
			<SnackbarProvider
				maxSnack={3}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<App />
			</SnackbarProvider>
		</AuthProvider>
	</LanguageProvider>
	// </React.StrictMode>
);
