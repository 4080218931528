import { Box, Typography } from '@mui/material';
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function PasswordRequirements() {
	return (
		<Box sx={{ color: '#515669' }}>
			<Typography sx={{ fontSize: 14 }}>Password Requirements:</Typography>
			<Typography sx={{ fontSize: 14 }}>
				<FiberManualRecordIcon sx={{ fontSize: 8, color: '#515669', mt: 0.5, mr: 2 }} />
				Minimum 8 characters long - the more, the better
			</Typography>
			<Typography sx={{ fontSize: 14 }}>
				<FiberManualRecordIcon sx={{ fontSize: 8, color: '#515669', mt: 0.5, mr: 2 }} />
				At least one lowercase character
			</Typography>
			<Typography sx={{ fontSize: 14 }}>
				<FiberManualRecordIcon sx={{ fontSize: 8, color: '#515669', mt: 0.5, mr: 2 }} />
				At least one number, symbol, or whitespace character
			</Typography>
		</Box>
	);
}
