import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Paper, CircularProgress } from '@mui/material';
import ListGrid from '../../components/customDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { getList } from '../../services/services';
import { StatisticsPaper, StatisticsPaper2 } from '../../components/customStatisticsPaper';
import { useLanguage } from '../../context/LanguageProvider';

const Clinics = () => {
	const navigate = useNavigate();
	const [data, set_data] = useState({});
	const [loaded, set_loaded] = useState(false);
	const [loading, set_loading] = useState(true);
	const { getLangString } = useLanguage();

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'user', action: 'getStatistics' };
			const result = await getList(urlParams);
			console.log('--------> Statistics', result.data);
			set_data(result.data);
			set_loaded(true);
			console.log('-------> data', data);
			set_loading(false);
		};
		if (!loaded) fetchData().catch(console.error);
	}, [data]);

	const editUser = (params) => () => {
		navigate('/EditClinic', { state: { params: params.row } });
	};

	// const deleteUser = React.useCallback(
	// 	(id) => () => {
	// 		setTimeout(() => {
	// 			setRows((prevRows) => prevRows.filter((row) => row.id !== id));
	// 		});
	// 	},
	// 	[]
	// );

	// const toggleAdmin = React.useCallback(
	// 	(id) => () => {
	// 		setRows((prevRows) =>
	// 			prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
	// 		);
	// 	},
	// 	[]
	// );

	// const duplicateUser = React.useCallback(
	// 	(id) => () => {
	// 		setRows((prevRows) => {
	// 			const rowToDuplicate = prevRows.find((row) => row.id === id);
	// 			return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
	// 		});
	// 	},
	// 	[]
	// );

	let columns = [
		{ field: 'clinic_id', headerName: getLangString('id'), width: 40 },
		{
			field: 'clinic_name',
			headerName: getLangString('clinic'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'clinic_address',
			headerName: getLangString('address'),
			flex: 1,
			minWidth: 150
		},
		{
			field: 'clinic_phone',
			headerName: getLangString('phone'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'clinic_email',
			headerName: getLangString('email'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'clinic_location',
			headerName: getLangString('location'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'clinic_brief',
			headerName: getLangString('brief'),
			flex: 1,
			minWidth: 130
		},
		{
			field: 'actions',
			headerName: getLangString('action'),

			type: 'actions',
			minWidth: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<ModeEditOutlineOutlinedIcon />}
					label={getLangString('edit')}
					onClick={editUser(params)}
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label={getLangString('delete')}
					// onClick={deleteUser(params.id)}
				/>,
				// <GridActionsCellItem
				// 	icon={<SecurityIcon />}
				// 	label="Toggle Admin"
				// 	// onClick={toggleAdmin(params.id)}
				// 	showInMenu
				// />,
				<GridActionsCellItem
					icon={<FileCopyIcon />}
					label={getLangString('duplicate_user')}
					// onClick={duplicateUser(params.id)}
					showInMenu
				/>
			]
		}
	];
	let rowID = (row) => row.clinic_id;
	let urlParams = { resource: 'clinic', action: 'getclinics' };
	let createPage = {
		buttonText: `+ ${getLangString('add_new_clinic')}`,
		createPagePath: '/NewClinic'
	};
	let columnVisibilityModel = {
		patient_clinic_id: false,
		patient_email: false,
		clinic_brief: false,
		clinic_address: false
	};
	const page = 'clinicList';
	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Box sx={{ mt: 3, ml: 3, display: 'flex', height: '100%', width: '100%' }}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={4}>
						<StatisticsPaper
							name={getLangString('stat_total_clinic')}
							value={data.clinics[0].clinics_total}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<StatisticsPaper
							name={getLangString('stat_total_providers')}
							value={data.doctors[0].doctors_total}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<StatisticsPaper
							name={getLangString('stat_total_patients')}
							value={data.patients[0].patients_total}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<StatisticsPaper2
							name={getLangString('stat_active_clinic')}
							value={data.active_clinics[0].active_clinics_total}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<StatisticsPaper2
							name={getLangString('stat_active_doctors')}
							value={data.active_doctors[0].active_doctors_total}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<StatisticsPaper2
							name={getLangString('stat_active_patients')}
							value={data.active_patients[0].active_patients_total}
						/>
					</Grid>
				</Grid>
			</Box>

			<ListGrid
				rowID={rowID}
				columns={columns}
				urlParams={urlParams}
				createPage={createPage}
				columnVisibilityModel={columnVisibilityModel}
				pageName={page}
			/>
		</>
	);
};

export default Clinics;
