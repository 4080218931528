import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { COLORS } from '../../context/colors';
import { useLanguage } from '../../context/LanguageProvider';

const PopoverPopupState = ({
	images,
	checked,
	handleToggle,
	selectedImages,
	setSelectedImages
}) => {
	const { getLangString } = useLanguage();
	useEffect(() => {
		console.log('selectedImages', selectedImages);
	}, [selectedImages]);

	return (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<>
					<Button
						variant="contained"
						sx={{ backgroundColor: COLORS.RaisinPurple }}
						{...bindTrigger(popupState)}>
						{getLangString('select_dates')}
					</Button>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}>
						<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
							{images.map((image) => {
								const labelId = `checkbox-list-label-${image.pl_id}`;

								return (
									<ListItem key={image.pl_id} disablePadding>
										<ListItemButton role={undefined} onClick={handleToggle(image)} dense>
											<ListItemIcon>
												<Checkbox
													edge="start"
													checked={checked.indexOf(image.pl_id) !== -1}
													tabIndex={-1}
													disableRipple
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											</ListItemIcon>
											<ListItemText id={labelId} primary={`${image.pl_update_ts.split('T')[0]}`} />
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>
					</Popover>
				</>
			)}
		</PopupState>
	);
};

export default PopoverPopupState;
