import React from 'react';
import { Typography, Avatar, Stack, Paper } from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

export const StatisticsPaper = (props) => {
	const { name, value } = props;

	return (
		<Paper variant="outlined" sx={{ mt: { xs: 3, md: 3 }, p: { xs: 1, md: 1 } }}>
			<Stack direction="row" spacing={2}>
				<Avatar>
					<GroupOutlinedIcon />
				</Avatar>
				<Stack>
					<Typography
						variant="subtitle1"
						align="left"
						color="black"
						sx={{ fontWeight: 300, fontSize: 14 }}>
						{name}
					</Typography>
					<Typography
						variant="s2"
						align="left"
						color="#1db14c"
						sx={{ fontWeight: 600, fontSize: 14 }}>
						{value}
					</Typography>
				</Stack>
			</Stack>
		</Paper>
	);
};

export const StatisticsPaper2 = (props) => {
	const { name, value } = props;

	return (
		<Paper variant="outlined" sx={{ p: { xs: 1, md: 1 } }}>
			<Stack direction="row" spacing={2}>
				<Avatar>
					<GroupOutlinedIcon />
				</Avatar>
				<Stack>
					<Typography
						variant="subtitle1"
						align="left"
						color="black"
						sx={{ fontWeight: 300, fontSize: 14 }}>
						{name}
					</Typography>
					<Typography
						variant="s2"
						align="left"
						color="#1db14c"
						sx={{ fontWeight: 600, fontSize: 14 }}>
						{value}
					</Typography>
				</Stack>
			</Stack>
		</Paper>
	);
};
