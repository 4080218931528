import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import FileUploadButton from './FileUploadButton';

export default function ProfileUpload({ profile_pic, new_profile_pic, set_new_profile_pic }) {
	const handleReset = (e) => {
		e.preventDefault();
		e.stopPropagation();
		set_new_profile_pic(null);
	};

	return (
		<Box sx={{ display: 'flex', gap: 2, my: 4 }}>
			<Avatar
				variant="square"
				alt="Profile Pic"
				src={new_profile_pic ? URL?.createObjectURL(new_profile_pic) : profile_pic} // new_profile_pic ? URL?.createObjectURL(new_profile_pic) : profile_pic || ''
				sx={{ width: 100, height: 100 }}
			/>
			<Box>
				<Box sx={{ display: 'flex', gap: 2 }}>
					{/* <ButtonSubmit text={'Upload new photo'} /> */}
					<FileUploadButton
						text={'Upload new photo'}
						color="success"
						variant="outlined"
						onChange={(file) => {
							set_new_profile_pic(file);
						}}
					/>
					<Button variant="outlined" color="success" onClick={handleReset} sx={{ width: 100 }}>
						Reset
					</Button>
				</Box>

				<Typography sx={{ mt: 2 }}>Allowed JPG, GIF or PNG. Max size of 800K</Typography>
			</Box>
		</Box>
	);
}
