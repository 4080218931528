import React, { useState, useRef } from 'react';
import {
	Grid,
	Container,
	Paper,
	Typography,
	Toolbar,
	TextField,
	Box,
	Button,
	Link,
	AccordionDetails,
	AccordionSummary,
	Accordion
} from '@mui/material';
import { post } from './../services/services';
import CustomizedAlertBar from './../components/customAlertBar';
import { useLanguage } from '../context/LanguageProvider';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Support = () => {
	const alertRef = useRef(null);
	const { getLangString } = useLanguage();

	const [isLoading, setIsLoading] = useState(false);
	const [ticket, set_ticket] = useState('');

	const onSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const urlParams = { resource: 'log', action: 'addCustomerSupport' };
			const response = await post(urlParams, { support: ticket });

			if (response.result)
				alertRef.current.handleClick({
					message: 'Ticket Submitted Successfully',
					severity: 'success'
				});

			set_ticket('');
		} catch (err) {
			console.log('err', err);
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
		setIsLoading(false);
	};

	return (
		<>
			<CustomizedAlertBar ref={alertRef} />
			<Container component="main" maxWidth="xl" sx={{ my: 1 }}>
				<Accordion
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 2 }
					}}>
					<AccordionSummary
						expandIcon={<ArrowDownwardIcon />}
						aria-controls="panel1-content"
						id="panel1-header">
						<Typography variant="h6" align="left">
							{getLangString('technical_support')}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2}>
							{/* <Grid item xs={12} sm={12}>
								<Typography
									variant="subtitle1"
									color="black"
									sx={{ fontWeight: 'bold' }}
									gutterBottom>
									{getLangString('support_description')}
								</Typography>
								<Typography
									variant="subtitle1"
									color="black"
									sx={{ fontWeight: 'bold' }}
									gutterBottom>
									{getLangString('phone')}:<Link> +1 (617) 460-6000</Link>
								</Typography>

								<Grid
									container
									item
									xs={12}
									sm={12}
									justifyContent="space-between"
									alignItems="center">
									<Typography
										variant="subtitle1"
										color="black"
										sx={{ fontWeight: 'bold' }}
										gutterBottom>
										{getLangString('email')}:<Link> support@predictivecare.ai</Link>
									</Typography>

									<Typography
										variant="subtitle2"
										color="black"
										sx={{ fontWeight: 'bold' }}
										gutterBottom>
										<Link
											href="https://apkbucket.s3.amazonaws.com/Clinician+Guide+English.pdf"
											target="_blank">
											{getLangString('download_user_guide')}
										</Link>
									</Typography>
								</Grid>
							</Grid> */}
							<Grid item xs={12} xl={12} id="supportForm" component="form" onSubmit={onSubmit}>
								<TextField
									required
									id="submitTicket"
									name="submitTicket"
									label={getLangString('message')}
									placeholder="Submit a ticket.."
									fullWidth
									multiline
									rows={8}
									variant="outlined"
									value={ticket}
									onChange={(e) => set_ticket(e.target.value)}
								/>
							</Grid>

							<Grid item xs={12} sm={12}>
								<Box textAlign="left" flex={1}>
									<Button
										variant="contained"
										color="success"
										type="submit"
										form="supportForm"
										disabled={isLoading}
										sx={{ borderRadius: 8, width: 170 }}>
										{getLangString('submit')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Container>
		</>
	);
};

export default Support;
