import axios from 'axios';
import LocalStorageService from './LocalStorageService';
// import router from "./router/router";
// import { router } from "react-router-dom";
import { API_ENDPOINT } from './constants';

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
const setTokenHeader = () => {
	axios.interceptors.request.use(
		(config) => {
			const token = localStorageService.getAccessToken();
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token;
			}
			// config.headers['Content-Type'] = 'application/json';
			return config;
		},
		(error) => {
			Promise.reject(error);
		}
	);
};
setTokenHeader();

//Add a response interceptor

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		if (
			error.response &&
			error.response.status === 401 &&
			originalRequest.url === `${API_ENDPOINT}/api/user/refresh`
		) {
			// router.push("/login");
			return Promise.reject(error);
		}

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			await requestRefreshToken();
			return axios(originalRequest);
		}
		return Promise.reject(error);
	}
);

export const requestRefreshToken = async () => {
	try {
		const refreshToken = localStorageService.getRefreshToken();
		const email = localStorageService.getEmail();
		const res = await axios.post(`${API_ENDPOINT}/api/user/refresh`, {
			RefreshToken: refreshToken,
			email: email
		});
		if (res.status === 200) {
			localStorageService.setToken({
				access_token: res.data.data.accessToken,
				refresh_token: refreshToken
			});
			setTokenHeader();
		}
	} catch (e) {
		console.error('refresh failed ', e);
	}
};

export default axios;

export const checkToken = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_ENDPOINT}/api/user/check`)
			.then((result) => {
				resolve(result.data.data);
			})
			.catch((err) => {
				if (err.response?.data) reject(err.response.data);
				else reject('something wrong happened' + err);
			});
	});
};

export const getProfile = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_ENDPOINT}/api/user/profile`)
			.then((result) => {
				resolve(result.data.data);
			})
			.catch((err) => {
				console.log(err);
				if (err.response?.data) reject(err.response.data);
				else reject('something wrong happened' + err);
			});
	});
};
