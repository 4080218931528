import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLanguage } from '../../context/LanguageProvider';
import {
	Box,
	CircularProgress,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography
} from '@mui/material';
import { create } from '../../services/services';
import { useForm } from 'react-hook-form';
import CustomizedAlertBar from '../customAlertBar';
import { COLORS } from '../../context/colors';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import PopoverPopupState from './popOverDates';
import { styled } from '@mui/material/styles';
import { Stack } from 'immutable';
import CloseIcon from '@mui/icons-material/Close';

const ImageGallery = ({ open, setOpen, images, surgeryInfo }) => {
	const [selected_images, set_selected_images] = useState([]); //images.slice(0, 2)
	const [images_per_row, set_images_per_row] = useState(3);
	// const [open_dialog, set_open_dialog] = useState(open);
	// const [loading, set_loading] = useState(true);
	const [checked, set_checked] = useState([]);

	const { getLangString } = useLanguage();

	const handleClose = () => {
		// set_selected_images(images?.slice(0, 2) ?? []);
		// set_open_dialog(false);
		// set_selected_images([]);
		setOpen(false);
	};

	const removeImage = (id) => () => {
		const newImages = selected_images.filter((item) => item.pl_id !== id);
		const newChecked = checked.filter((item) => item !== id);
		set_selected_images(newImages);
		set_checked(newChecked);
	};

	const handleToggle = (image) => () => {
		const currentIndex = checked.indexOf(image.pl_id);

		const newChecked = [...checked];
		const newImages = [...selected_images];

		if (currentIndex === -1) {
			newChecked.push(image.pl_id);
			newImages.push(image);
		} else {
			newChecked.splice(currentIndex, 1);
			newImages.splice(selected_images.indexOf(image), 1);
		}

		const sortNewImages = newImages.sort(
			(a, b) => new Date(b.pl_update_ts) - new Date(a.pl_update_ts)
		);

		set_checked(newChecked);
		set_selected_images(sortNewImages);
	};

	useEffect(() => {
		// Sort the images by date in descending order
		const recetImages = images.sort((a, b) => new Date(b.pl_update_ts) - new Date(a.pl_update_ts)).slice(0, 3);

		// Return the first three images
		set_checked(recetImages.map((img) => img.pl_id));
		set_selected_images(recetImages.slice(0, 3));
	}, [images]);

	return (
		<>
			<Dialog
				scroll={'body'}
				open={open}
				onClose={handleClose}
				fullWidth={true}
				maxWidth={'xl'}
				sx={{
					'& .MuiDialog-paper': {
						borderColor: '#1db14c',
						borderRadius: 3,
						boxShadow: 5,
						p: 1
					}
				}}>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={() => {}}
					date={surgeryInfo.surgery_date}>
					{surgeryInfo.surgery_name}
				</BootstrapDialogTitle>

				{selected_images.length === 0 ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							height: 'auto',
							minHeight: 400
						}}>
						<Typography> No Images Selected </Typography>
					</Box>
				) : (
					<>
						<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
							{/* <DialogContentText>Add new medicine</DialogContentText> */}

							<ImageList
								cols={images_per_row}
								rowHeight={450}
								gap={8}
								sx={{ width: '100%', height: 'auto', minHeight: 400, justifyContent: 'center' }}>
								{selected_images.map((item) => {
									return (
										<ImageListItem key={item.image_url}>
											<img
												id={item.pl_id}
												src={`${item.image_url}`}
												srcSet={`${item.image_url}`}
												alt={item.pl_update_ts}
												loading="lazy"
												style={{ width: '100%', height: '90%', objectFit: 'contain' }}
											/>
											<IconButton
												id={item.pl_id}
												value={item.pl_id}
												aria-label="close"
												onClick={removeImage(item.pl_id)}
												sx={{
													position: 'absolute',
													right: 2,
													top: 2,
													color: (theme) => theme.palette.grey[500]
												}}>
												<CloseIcon />
											</IconButton>

											<ImageListItemBar
												title={item.pl_update_ts.split('T')[0]}
												// subtitle={<span>by: {item.author}</span>}
												position="below"
												sx={{ textAlign: 'center' }}
											/>
										</ImageListItem>
									);
								})}
							</ImageList>
						</DialogContent>
					</>
				)}
				<DialogActions sx={{ display: 'flex', alignItems: 'center' }}>
					<FormControl sx={{ marginRight: 'auto' }}>
						<InputLabel id="demo-simple-select-label">{getLangString('images_per_row')}</InputLabel>
						<Select
							sx={{ width: 120 }}
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={images_per_row}
							label={getLangString('images_per_row')}
							onChange={(e) => {
								set_images_per_row(e.target.value);
							}}>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
						</Select>
					</FormControl>
					<Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
						<Button onClick={handleClose}>{getLangString('cancel')}</Button>
						<PopoverPopupState
							images={images}
							checked={checked}
							handleToggle={handleToggle}
							selectedImages={selected_images}
							setSelectedImages={set_selected_images}
						/>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};

const BootstrapDialogTitle = (props) => {
	const { children, onClose, date, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			<Typography
				sx={{
					position: 'absolute',
					right: 20,
					top: 30,
					color: (theme) => theme.palette.grey[500]
				}}>
				{date}
			</Typography>
			{/* {onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500]
					}}>
					<CloseIcon />
				</IconButton>
			) : null} */}
		</DialogTitle>
	);
};

export default ImageGallery;
