// to disable langae selection and use english default, uncomment first line, comment second
export const supportedLangauges = ['en']; //, 'es', 'ar'];
// export const supportedLangauges = ['en', 'es', 'ar'];

const locale = {
	en: {
		sign_in_title: 'Sign In To Your Account By',
		forgot_password: 'Forgot password',
		remember_me: 'Remember Me',
		sign_in: 'Sign In',
		select_language: 'Select language',
		menu_list: {
			clinic: 'Clinic',
			provider: 'Provider',
			patient: 'Patient',
			surgery: 'Surgery',
			surgery_template: 'Surgery Template',
			add_bulk_doctors: 'Add Bulk Doctors',
			add_bulk_patients: 'Add Bulk Patients',
			emr: 'EMR',
			reporting: 'Reporting',
			integration: 'Integration',
			feedBack: 'Feedback',
			technical_support: 'Support',
			terms_of_use: 'Terms of Use',
			contact_us: 'Contact Us',
			prediction: 'Prediction'
		},

		// clinic
		add_new_clinic: 'Add New Clinic',
		stat_total_clinic: 'Total Clinics/Hospitals',
		stat_total_providers: 'Total Providers',
		stat_total_patients: 'Total Patients',
		stat_total_procedures: 'Total Procedures',
		stat_active_clinic: 'Active Clinics/Hospitals',
		stat_active_doctors: 'Active Doctors',
		stat_active_patients: 'Active Patients',
		stat_active_procedures: 'Active Procedures',

		// clinic
		add_clinic_title: 'Add Clinic/Hospital',
		clinic_info: 'Clinic Info',
		clinic_name: 'Clinic/Hospital Name',
		clinic_licence_type: 'Licence Type',
		clinic_licence_active_date: 'Licence Active Date',
		clinic_licence_users_nb: 'Number of Users',
		active_deactivate: 'Activate/Deactivate',

		epic_title: 'Epic Integration Configuration',
		integreation_type: 'Integration Type',
		organization_name: 'Organization Name',
		base_url: 'Production FHIR Base URL',
		api_version: 'FHIR API Version',

		edit_clinic_title: 'Edit Clinic/Hospital',

		// actions
		cancel: 'Cancel',
		save: 'Save',
		update: 'Update',
		delete: 'Delete',
		action: 'Actions',
		edit: 'Edit',
		add: 'Add',
		search: 'Search',
		get: 'Get',
		submit: 'Submit',
		sign_up_by: 'Sign Up By',

		// provider
		add_new_provider: 'Add New Provider',
		add_provider_title: 'Add Provider',
		provider_info: 'Provider Info',
		add_provider_account_info: 'Provider Account Info',

		// patient
		add_new_patient: 'Add New Patient',
		add_patient: 'Add Patient',
		edit_patient: 'Edit Patient',
		add_patient_title: 'Add Patient',
		patient_info: 'Patient Info',
		active_surgery: 'Active Surgery',
		last_prediction: 'Last Prediction',
		patient_account_info: 'Patient Account Info',

		surgery_history: 'Patient Data',

		// procedure
		activate: 'Activate',
		deactivate: 'Deactivate',
		add_new_procedure: 'Add New Procedure',

		patient_name: 'Patient Name',
		procedure_type: 'Procedure Type',
		model_type: 'Model Type',
		procedure_date: 'Procedure Date',
		procedure_creation: 'Procedure Creation',
		patient_surgery_setup: 'Patient Surgery Setup',
		surgery_details: 'Surgery Details',
		save_template: 'Save Template',
		templates: 'Templates',
		surgeon: 'Surgeon',
		supporting_staff: 'Supporting Staff',
		patient: 'Patient',
		surgery_type: 'Surgery Type',
		surgery_description: 'Surgery Description',
		surgery_code: 'CPT code',
		code_category: 'Code Category',
		cpt_code: 'CPT code',
		cpt_code_description: 'Description',
		surgery_date: 'Surgery Date',
		treatment_plan: 'Treatment Plan',
		monitoring_plan: 'Monitoring Plan',
		temperature_unit: 'Temperature Unit',
		monitoring_period_from: 'Monitoring Period - From',
		monitoring_period_to: 'Monitoring Period - To',
		pain_level: 'Pain Level',
		wound_pain: 'Wound Pain',
		temperature: 'Temperature',
		drainage: 'Drainage',
		wound_drainage: 'Wound Drainage',
		wound_temperature: 'Wound Temperature',
		wound_odor: 'Wound Odor',
		prescription_medications: 'Prescription Medications',
		pervious_medications_treatment_plan: 'Pervious Medication Treatment Plan',
		new_medications_treatment_plan: 'New Medication Treatment Plan',
		medication_plan: 'Medication Plan',
		enable_disable: 'Enable/Disable',
		status: 'Status',
		medication_group: 'Medication Group',
		add_new_medication: 'Add New Medication',
		add_new: 'Add New',
		add_new_cpt_code: 'Add New CPT Code',
		edit_provider: 'Edit Provider',
		images_per_row: 'Images per Row',
		select_dates: 'Select Dates',
		from: 'From',
		to: 'To',
		done: 'Done',

		// KPIS
		enable_all: 'Enable All',
		kpi: 'KPI',
		kpi_abbreviation: 'Key Performance Indicator',
		patient_inputs: 'Patient Inputs',

		// Vital Signs KPIS
		vital_signs: 'Vital Signs',
		temperature: 'Temperature',
		pulse: 'Pulse',
		blood_pressure: 'Blood Pressure',
		respiratory_rate: 'Respiratory Rate',
		oxygen_saturation: 'Oxygen Saturation',

		// Wound Inputs KPIS
		wound_inputs: 'Wound Inputs',
		pain: 'Wound Pain',
		odor: 'Wound Odor',
		wound_temperature: 'Wound Temperature',
		wound_swelling: 'Wound Swelling',
		bleeding: 'Wound Drainage',
		image_url: 'Wound Photo',
		wound_photo: 'Wound Photo',

		// Well-Being KPIS
		well_being: 'Well-Being',
		sleep_issues: 'Sleep Issues',
		breathing_issues: 'Breathing Issues',
		dizziness: 'Dizziness',
		balance: 'Balance',
		nausea: 'Nausea',
		vomiting: 'Vomiting',
		chills: 'Chills',
		appetite: 'Appetite',
		urination: 'Urination',
		defecation: 'Defecation',
		physical_activity: 'Physical Activity',
		weight_changes: 'Weight Changes',
		irritability: 'Irritability',
		anxiety: 'Anxiety',
		depression: 'Depression',
		fatigue: 'Fatigue',
		restlessness: 'Restlessness',
		memory_problems: 'Memory Problems',
		mental_fog: 'Mental Fog',

		// tables and users forms
		id: 'ID',
		clinic: 'Clinic',
		clinic_hospital: 'Hospital / Clinic',
		clinic_id: 'Clinic ID',
		user_id: 'User ID',
		profile: 'Profile',
		first_name: 'First Name',
		middle_name: 'Middle Name',
		last_name: 'Last Name',
		birthday: 'Birthday',
		date_of_birth: 'Date of Birth',
		gender: 'Sex',
		department: 'Department',
		role: 'Role',
		access_level: 'Access Level',
		contact_email: 'Contact Email',
		city: 'City',
		country: 'Country',
		zip_code: 'Zip Code',
		state: 'State',
		first_address: 'Address 1',
		second_address: 'Address 2',
		address: 'Address',
		phone: 'Phone',
		email: 'Email',
		location: 'Locaton',
		brief: 'Brief',
		note: 'Note',
		username: 'Username',
		valid_email_username: 'Enter Valid Email as Username',
		valid_phone_username: 'Enter Valid Phone Number as Username',
		password: 'Password',
		confirm_password: 'Confirm Password',
		toggle_admin: 'Toggle Admin',
		duplicate_user: 'Duplicate User',
		view: 'View',
		procedure_time: 'Procedrue Time',

		medication_name: 'Medication Name',
		dose: 'Dose',
		daily_frequency: 'Daily Intake Frequency',
		medication_standard_frequency: 'Dose Frequency',
		standard_frequency: 'Dose Frequency',
		start_date: 'Start Date',
		end_date: 'End Date',

		// Template
		template_name: 'Template Name',
		add_new_template: 'Add New Template',
		edit_new_template: 'Edit New Template',
		template_setup: 'Template Setup',
		update_template: 'Update Template',

		// terms
		terms_of_use: 'Terms of Use',
		no_online_pdf_viewer:
			'No online PDF viewer installed. Download the Terms of service file using this Link',
		link: 'Link',

		// Integration
		integration: 'Integration',
		add_integration: 'Add Integration',
		integration_name: 'Integration Name',

		// Feedback
		feedback: 'Feedback',
		feedback_description: 'We would love to hear from you!',
		feedback_description_2:
			'Tell us what you love about MyHealthPal, or what could be doing better.',

		// Technical Support
		technical_support: 'Technical Support',
		support_description:
			'If you would like to contact technical support, please call, email, or submit a ticket below.',
		submit_ticket: 'Submit ticket',
		download_user_guide: 'Download User Guide',

		// Notifications
		notifications: 'Notifications',
		no_notifications: 'No Notifications',
		all: 'All',
		read: 'Read',
		unseen: 'Unseen',
		mark_all_as_read: 'Mark all as read',
		today: 'Today',
		yesterday: 'Yesterday',
		high: 'High',

		// Epic
		epic: 'Epic',
		get_user_by_id: 'Get Doctor/Patient By ID',
		user_type: 'User Type',
		import_doctor_to_myHealthPal: 'Import Doctor to MyHealthPal',
		import_patient_to_myHealthPal: 'Import Patient to MyHealthPal',
		import_procedure_to_myHealthPal: 'Import Procedure to MyHealthPal',
		search_doctor_patient: 'Search Doctor/Patient',
		get_procedure: 'Get Procedure',
		procedure_id: 'Procedure Id',
		search_procedure: 'Search Procedure',
		patient_id: 'Patient ID',

		//prediction
		prediction: 'Surgical Site Infection Risk Prediction',
		doctor: 'Doctor',
		surgery: 'Surgery',
		apply: 'Apply',
		filter: 'Filter',
		doctors_feedback: 'Provider feedback',
		positive: 'Positive',
		negative: 'Negative',
		agree: 'Agree',
		disagree: 'Disagree',
		elevated_risk: 'Elevated Risk',
		standard_risk: 'Standard Risk',
		phc_predictions: 'MYHEALTHPAL PREDICTION',
		frequency: 'Frequency',

		//reporting
		reporting: 'Reporting',
		create_date: 'Create Date',
		assigned_doctors: 'Assigned Doctors',
		assigned_kpis: 'Assigned Kpis',
		name: 'Name',
		dob: 'DOB',
		age_range: 'Age Range',
		search_by_name_or_description: 'Procedure Name',
		field: 'Field',
		operator: 'Operator',
		value: 'Value',
		medications: 'Medications',
		active: 'Active',

		no_options_available: 'No options available',
		contact_us: 'Contact Us',
		password_policy:
			'Password should contain 8 characters with at least 1 uppercase letter, 1 number, and 1 symbol',

		genderArray: [
			{
				value: 'Male',
				label: 'Male'
			},
			{
				value: 'Female',
				label: 'Female'
			}
			// {
			// 	value: 'Transgender',
			// 	label: 'Transgender'
			// },
			// {
			// 	value: 'Client Doesn’t Know',
			// 	label: 'Client Doesn’t Know'
			// },
			// {
			// 	value: 'Questioning',
			// 	label: 'Questioning'
			// },
			// {
			// 	value: 'Culturally Specific Gender',
			// 	label: 'Culturally Specific Gender'
			// },
			// {
			// 	value: 'Client Refused',
			// 	label: 'Client Refused'
			// },
			// {
			// 	value: 'Data Not Collected',
			// 	label: 'Data Not Collected'
			// },

			// {
			// 	value: 'Non-binary',
			// 	label: 'Non-binary'
			// },
			// {
			// 	value: 'Genderfluid',
			// 	label: 'Genderfluid'
			// },
			// {
			// 	value: 'Agender',
			// 	label: 'Agender'
			// }
		],

		providerRole: [
			{
				value: 'Assistant Surgeon',
				label: 'Assistant Surgeon'
			},
			{
				value: 'Anesthesiologist',
				label: 'Anesthesiologist'
			},
			{
				value: 'CRNA',
				label: 'CRNA'
			},
			{
				value: 'Medical Student',
				label: 'Medical Student'
			},
			{
				value: 'Principle Investigator',
				label: 'Principle Investigator'
			},
			{
				value: 'Clinical Research Coordinator',
				label: 'Clinical Research Coordinator'
			},

			{
				value: 'Nurse Practitioner',
				label: 'Nurse Practitioner'
			},
			{
				value: 'OR Nurse',
				label: 'OR Nurse'
			},

			{
				value: 'Physician Assistant',
				label: 'Physician Assistant'
			},
			{
				value: 'Radiology Tech',
				label: 'Radiology Tech'
			},

			{
				value: 'Resident',
				label: 'Resident'
			},
			{
				value: 'RN',
				label: 'RN'
			},
			{
				value: 'Resident Surgeon',
				label: 'Resident Surgeon'
			},

			{
				value: 'Surgeon',
				label: 'Surgeon'
			},

			{
				value: 'Surgical Tech',
				label: 'Surgical Tech'
			}
		],

		surgeryType: [
			{ value: 'Bariatric Surgery' },
			{ value: 'Breast Surgery' },
			{ value: 'Colon & Rectal Surgery' },
			{ value: 'Endocrine Surgery' },
			{ value: 'General Surgery' },
			{ value: 'Gynecological Surgery' },
			{ value: 'Hand Surgery' },
			{ value: 'Head & Neck Surgery' },
			{ value: 'Hernia Surgery' },
			{ value: 'Neurosurgery' },
			{ value: 'Orthopedic Surgery' },
			{ value: 'Ophthalmological Surgery' },
			{ value: 'Plastic & Reconstructive Surgery' },
			{ value: 'Thoracic Surgery' },
			{ value: 'Trauma Surgery' },
			{ value: 'Urologic Surgery' },
			{ value: 'Vascular Surgery' }
		]
	},
	es: {
		sign_in_title: 'Iniciar sesión en su cuenta',
		select_language: 'Seleccione el idioma',
		add_new_patient: 'Añadir nuevo paciente',
		menu_list: {
			clinic: 'Clinic-es',
			provider: 'Provider',
			patient: 'Patient',
			surgery: 'Surgery-es',
			surgery_template: 'Surgery Template',
			add_bulk_doctors: 'Add Bulk Doctors',
			add_bulk_patients: 'Add Bulk Patients',
			emr: 'EMR',
			integration: 'Integration-es',
			feedBack: 'FeedBack',
			technical_support: 'Technical Support-es',
			terms_of_use: 'TermsOfUse'
		},

		language: 'esES',

		// clinic
		add_new_clinic: 'Add New Clinic - es',
		stat_total_clinic: 'Total Clinics/Hospitals',
		stat_total_providers: 'Total Providers - es',
		stat_total_patients: 'Total Patients',
		stat_active_clinic: 'Active Clinics/Hospitals - es',
		stat_active_doctors: 'Active Doctors',
		stat_active_patients: 'Active Patients',

		add_clinic_title: 'Add Clinic/Hospital',

		// tables
		tbl_id: 'ID',
		tbl_clinic: 'Clinic - es',
		tbl_address: 'Address - es',
		tbl_phone: 'Phone',
		tbl_email: 'Email',
		tbl_location: 'Locaton',
		tbl_note: 'Note',
		tbl_action: 'Actions',
		tbl_edit: 'Edit',
		tbl_delete: 'Delete',
		tbl_toggle_admin: 'Toggle Admin',
		tbl_duplicate_user: 'Duplicate User'
	},
	ar: {
		sign_in_title: 'Sign in to your account',
		select_language: 'اختار اللغة',
		add_new_patient: 'Add new patient'
	}
};
export default locale;
