import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { useLanguage } from '../../context/LanguageProvider';
import {
	Box,
	FormControl,
	FormControlLabel,
	ListItemText,
	MenuItem,
	Stack,
	Typography
} from '@mui/material';
import { create } from '../../services/services';
import { useForm } from 'react-hook-form';
import { COLORS } from '../../context/colors';
import { IOSSwitchColored } from '../IosSwitchButton';
import { IOSSwitch } from '../IosSwitchButton';
import CustomizedAlertBar from '../customAlertBar';

const KpisDialog = ({ open, setOpen, kpiType, initialKpis, kpis, setKpis, updateKpis }) => {
	const { getLangString } = useLanguage();
	const alertRef = useRef(null);

	const [enable_all_kpis, set_enable_all_kpis] = useState({
		vital_signs: {
			enable: '0',
			frequency: '0',
			start_date: '',
			end_date: ''
		},
		wound_inputs: {
			enable: '0',
			frequency: '0',
			start_date: '',
			end_date: ''
		},
		well_being: {
			enable: '0',
			frequency: '0',
			start_date: '',
			end_date: ''
		}
	});

	// Function to update the state for a specific key
	const updateEnableAllKpis = (key, newState) => {
		set_enable_all_kpis((prevState) => ({
			...prevState,
			[key]: {
				...prevState[key],
				...newState
			}
		}));
	};

	useEffect(() => {
		Object.entries(kpis).find(([key, obj]) => {
			if (obj.enable === '0') {
				updateEnableAllKpis(kpiType, { enable: '0', frequency: '0' });
			}
		});
	}, [kpis]);

	const setAllOptions = (option, newValue) => {
		Object.entries(kpis).map(([key, obj]) => {
			if (key === 'image_url') return;
			updateKpis(kpiType, key, {
				[option]: newValue
			});
		});
	};

	
	const handleClose = (event, reason) => {
		if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

		const noDate = Object.entries(kpis).find(([key, obj]) => {
			if (obj.enable === '1' && (obj.start_date === '' || obj.end_date === '')) {
				alertRef.current.handleClick({
					message: `Set Dates for '${key.replace('_', ' ')}' `.toUpperCase(),
					severity: 'error'
				});
				return { key, ...obj };
			}
		});

		if (noDate) return;

		setOpen(false);
	};

	const handleCancel = () => {
		Object.entries(kpis).map(([key, obj]) => {
			if (
				kpis['image_url'] &&
				kpis['image_url'].enable === '1' &&
				(kpis['image_url'].start_date === '' || kpis['image_url'].end_date === '')
			)
				return;
			if (obj.enable === '1' && (obj.start_date === '' || obj.end_date === '')) {
				updateKpis(kpiType, key, {
					enable: '0',
					frequency: '0',
					start_date: '',
					end_date: ''
				});
				return { key, ...obj };
			}
		});

		setOpen(false);
	};

	const nameStyles = {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '19px',
		textAlign: 'left'
	};

	const frequencies = [
		{
			value: '1',
			label: 'Once every day'
		},
		{
			value: '2',
			label: 'Once every two days'
		},
		{
			value: '3',
			label: 'Once every three days'
		},
		{
			value: '4',
			label: 'Once every four days'
		}
	];

	return (
		<>
			<CustomizedAlertBar ref={alertRef} />
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={false}
				fullWidth={true}
				sx={{
					width: 776,
					marginLeft: 'auto',
					marginRight: 'auto',
					overflowX: 'hidden'
				}}
				PaperProps={{ sx: { borderRadius: '25px' } }}>
				<Box sx={{ width: 712, pl: '70px' }}>
					<DialogTitle
						sx={{
							mt: '58px',
							fontFamily: 'Inter',
							fontSize: '21px',
							fontWeight: 700,
							lineHeight: '25px',
							textAlign: 'left'
						}}>
						{getLangString(kpiType)}
					</DialogTitle>
					<DialogContent>
						{/* <DialogContentText>Add new medicine</DialogContentText> */}

						{/* temperature */}
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={2}>
							<React.Fragment key={'enable_all'}>
								<FormControlLabel
									label={
										<Typography sx={{ ...nameStyles }}>{getLangString('enable_all')}</Typography>
									}
									control={
										<IOSSwitch
											checked={enable_all_kpis[kpiType]?.enable === '1' ? true : false}
											onChange={(event) => {
												updateEnableAllKpis(kpiType, {
													enable: event.target.checked ? '1' : '0',
													frequency: event.target.checked ? '1' : '0'
												});
												setAllOptions('enable', event.target.checked ? '1' : '0');
												setAllOptions('frequency', event.target.checked ? '1' : '0');
											}}
											inputProps={{ 'aria-label': 'controlled' }}
											sx={{ mr: 1 }}
										/>
									}
									sx={{ ml: 0.1 }}
								/>

								{enable_all_kpis[kpiType]?.enable === '1' ? (
									<Stack
										direction="row"
										justifyContent="flex-start"
										alignItems="flex-start"
										spacing={2}>
										<FormControl>
											<TextField
												id="monitoringPeriod"
												name="monitoringPeriod"
												label={getLangString('from')}
												type="date"
												// inputFormat="yyyy-mm-dd"
												value={enable_all_kpis[kpiType]['start_date']}
												onChange={(event) => {
													updateEnableAllKpis(kpiType, {
														start_date: event.target.value
													});
													setAllOptions('start_date', event.target.value);
												}}
												InputLabelProps={{
													shrink: true
												}}
											/>
										</FormControl>
										<FormControl>
											<TextField
												id="monitoringPeriod"
												name="monitoringPeriod"
												label={getLangString('to')}
												type="date"
												value={enable_all_kpis[kpiType]['end_date']}
												onChange={(event) => {
													updateEnableAllKpis(kpiType, {
														end_date: event.target.value
													});
													setAllOptions('end_date', event.target.value);
												}}
												InputLabelProps={{
													shrink: true
												}}
											/>
										</FormControl>
										<FormControl>
											<TextField
												id="frequency"
												name="frequency"
												label={getLangString('frequency')}
												select
												value={enable_all_kpis[kpiType]['frequency']}
												onChange={(event) => {
													updateEnableAllKpis(kpiType, {
														frequency: event.target.value
													});
													setAllOptions('frequency', event.target.value);
												}}
												InputLabelProps={{
													shrink: true
												}}
												sx={{
													'& .MuiInputBase-root': {
														height: '56px'
													}
												}}>
												{frequencies.map((item) => (
													<MenuItem key={item.value} value={item.value}>
														<ListItemText primary={item.label} />
													</MenuItem>
												))}
											</TextField>
										</FormControl>
									</Stack>
								) : (
									<></>
								)}
							</React.Fragment>
							{Object.entries({
								...kpis
							}).map(([key, kpi]) => {
								return (
									<React.Fragment key={key}>
										<FormControlLabel
											label={<Typography sx={{ ...nameStyles }}>{getLangString(key)}</Typography>}
											control={
												<IOSSwitch
													checked={kpis[key]['enable'] === '1' ? true : false}
													onChange={(event) => {
														key !== 'image_url' &&
															updateKpis(kpiType, key, {
																enable: event.target.checked ? '1' : '0',
																frequency: event.target.checked ? '1' : '0'
															});
													}}
													// disabled={key === 'image_url' ? true : false}
													inputProps={{ 'aria-label': 'controlled' }}
													sx={{ mr: 1 }}
												/>
											}
											sx={{ ml: 0.1 }}
										/>

										{kpis[key]['enable'] === '1' ? (
											<Stack
												direction="row"
												justifyContent="flex-start"
												alignItems="flex-start"
												spacing={2}>
												<FormControl>
													<TextField
														id="monitoringPeriod"
														name="monitoringPeriod"
														label={getLangString('from')}
														type="date"
														// size='large'
														inputFormat="MM-dd-yyyy"
														value={kpis[key]['start_date']}
														onChange={(event) => {
															updateKpis(kpiType, key, {
																start_date: event.target.value
															});
														}}
														InputLabelProps={{
															shrink: true
														}}
													/>
												</FormControl>
												<FormControl>
													<TextField
														id="monitoringPeriod"
														name="monitoringPeriod"
														label={getLangString('to')}
														type="date"
														inputFormat="MM-dd-yyyy"
														value={kpis[key]['end_date']}
														onChange={(event) => {
															updateKpis(kpiType, key, {
																end_date: event.target.value
															});
														}}
														InputLabelProps={{
															shrink: true
														}}
													/>
												</FormControl>
												<FormControl size="small">
													<TextField
														id="frequency"
														name="frequency"
														label={getLangString('frequency')}
														select
														value={kpis[key]['frequency']}
														onChange={(event) => {
															updateKpis(kpiType, key, {
																frequency: event.target.value
															});
														}}
														InputLabelProps={{
															shrink: true
														}}
														sx={{
															height: '20px', // Set the desired height value
															'& .MuiInputBase-root': {
																height: '56px'
															}
														}}>
														{frequencies.map((item) => (
															<MenuItem key={item.value} value={item.value}>
																<ListItemText primary={item.label} />
															</MenuItem>
														))}
													</TextField>
												</FormControl>
											</Stack>
										) : (
											<></>
										)}
									</React.Fragment>
								);
							})}
						</Stack>
					</DialogContent>
					<DialogActions sx={{ mt: 4 }}>
						<Button
							onClick={handleCancel}
							sx={{
								width: '54px',
								height: '23.8px',
								fontFamily: 'Inter',
								fontStyle: 'normal',
								fontWeight: 600,
								fontSize: '16px',
								lineHeight: '19px',
								color: '#9BB5CE',
								borderRadius: '4px',
								mr: '28px',
								mb: '50px'
							}}>
							{getLangString('cancel')}
						</Button>
						<Button
							sx={{
								// position: 'absolute',
								width: '142px',
								height: '40.37px',
								background: COLORS.RaisinPurple,
								borderRadius: '4px',
								mr: '71px',
								mb: '46px',
								fontFamily: 'Inter',
								fontStyle: 'normal',
								fontWeight: 600,
								fontSize: '16px',
								lineHeight: '19px'
							}}
							onClick={handleClose}
							variant="contained">
							{getLangString('done')}
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
			{/* //{' ' */}
			{/* </form> */}
		</>
	);
};

export default KpisDialog;
