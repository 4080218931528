import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const PatientCard = (params) => {
	// const patient_details = params.patient_details[0].resource;
	const { patient_details } = params;
	console.log('patient_details', patient_details);
	return (
		<Box sx={{ minWidth: 275 }}>
			<Card
				variant="outlined"
				sx={{
					borderColor: '#1db14c',
					borderRadius: 2,
					boxShadow: 2
				}}>
				<CardContent>
					<Typography variant="body1" component="div" color="#1db14c">
						<b>Patient Id</b>: {patient_details.id}
					</Typography>
					<Typography variant="body1" component="div">
						<b>First Name</b>: {patient_details?.name[0]?.given[0]}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Middle Name</b>: {patient_details?.name[0]?.given[1]}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Family Name</b>: {patient_details?.name[0]?.family}
					</Typography>
					<Typography variant="body1" component="div">
						<b>BirthDate</b>: {patient_details?.birthDate}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Phone</b>: {patient_details?.telecom[0] ? patient_details?.telecom[0]?.value : ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Email</b>: {patient_details?.telecom[2] ? patient_details?.telecom[2]?.value : ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Care Provider</b>:
						{patient_details?.careProvider ? patient_details?.careProvider[0]?.display : ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Gender</b>: {patient_details?.gender}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Marital Status</b>: {patient_details?.maritalStatus?.text}
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
};

export default PatientCard;
