import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

export default function FileUploadButton({ sx = {}, onChange, text, ...props }) {
	const handleFileChange = (event) => {
		event.preventDefault();
		const file = event.target.files[0];
		onChange(file);
	};

	return (
		<Button component="label" {...props} sx={sx}>
			<input
				type="file"
				accept="image/*"
				id="upload-photo"
				style={{ display: 'none' }}
				onChange={handleFileChange}
			/>
			{text}
		</Button>
	);
}
