import React, { useEffect, useState } from 'react';
import {
	Paper,
	Container,
	Box,
	Toolbar,
	Typography,
	TextField,
	Button
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import { TermsOfServiceURL } from '../../helpers/constants';
import { useLanguage } from '../../context/LanguageProvider';

const myTheme = createTheme({
	// Set up your custom MUI theme here
});

const Terms = () => {
	const { getLangString } = useLanguage();


	const [title, set_title] = useState('');
	const [description, set_description] = useState('');
	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty()
	);
	useEffect(() => {
		console.log('------------> title', title);
		console.log('------------> description', description);
		console.log('------------> editorState', editorState);
	});
	return (
		<>
			<ThemeProvider theme={myTheme}>
				<Container
					maxWidth="xl"
					sx={{ boxShadow: 1, mx: 3, mt: 4, backgroundColor: 'white' }}>
					<Toolbar>
						<Typography variant="h6" align="left" color="#1db14c">
						{getLangString('terms_of_use')}
						</Typography>
					</Toolbar>
				</Container>
				<Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
					<object data={TermsOfServiceURL} type="application/pdf" width={"100%"} height={"700px"}>
						<div>{getLangString('no_online_pdf_viewer')}<a href={TermsOfServiceURL}>{getLangString('link')}</a></div>
					</object>
				</Container>
			</ThemeProvider>
		</>
	);
};

export default Terms;
