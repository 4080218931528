import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
	Grid,
	Typography,
	TextField,
	Button,
	Box,
	CircularProgress,
	Divider,
	Accordion,
	AccordionSummary,
	AccordionDetails
} from '@mui/material';
import _ from 'lodash';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useNavigate } from 'react-router-dom';
import { post } from './../../services/services';
import { useLanguage } from '../../context/LanguageProvider';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const FeedBack = () => {
	const navigate = useNavigate();
	const { getLangString } = useLanguage();
	const alertRef = useRef(null);

	const [isLoading, setIsLoading] = useState(false);
	const [feedback, set_feedback] = useState('');
	const [last_feedback, set_last_feedback] = useState('');
	const [loading, set_loading] = useState(true);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'log', action: 'getCustomerFeedback' };
			const feedBackResult = await post(urlParams);
			set_last_feedback(feedBackResult.data[0]?.feedback);

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	const onSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const urlParams = { resource: 'log', action: 'addCustomerFeedback' };
			const response = await post(urlParams, { feedback });

			if (response.result)
				alertRef.current.handleClick({
					message: 'FeedBack Submitted Successfully',
					severity: 'success'
				});

			set_feedback('');
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
		setIsLoading(false);
	};
	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} />
			<CssBaseline />
			<Container component="main" maxWidth="xl">
				<Accordion
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 2 }
					}}>
					<AccordionSummary
						expandIcon={<ArrowDownwardIcon />}
						aria-controls="panel1-content"
						id="panel1-header">
						<Typography variant="h6" align="left">
							{getLangString('feedback')}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12}>
								<Typography variant="subtitle1">{getLangString('feedback_description')}</Typography>
								<Typography variant="subtitle1">
									{getLangString('feedback_description_2')}
								</Typography>
							</Grid>
							<Grid item xs={12} xl={12} id="submitForm" component="form" onSubmit={onSubmit}>
								<TextField
									required
									id="feedback"
									name="feedback"
									label={getLangString('feedback')}
									fullWidth
									multiline
									rows={8}
									variant="outlined"
									value={feedback}
									onChange={(e) => set_feedback(e.target.value)}
								/>
							</Grid>

							<Grid item xs={12} sm={12}>
								<Box textAlign="left" flex={1}>
									<Button
										variant="contained"
										color="success"
										type="submit"
										form="submitForm"
										disabled={isLoading}
										sx={{ borderRadius: 8, width: 170 }}>
										{getLangString('submit')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Container>
		</ThemeProvider>
	);
};

export default FeedBack;
