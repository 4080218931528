// LocalStorageService.js
const LocalStorageService = (function () {
	var _service;
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	function _setToken(tokenObj) {
		localStorage.setItem('access_token', tokenObj.access_token);
		localStorage.setItem('refresh_token', tokenObj.refresh_token);
	}
	function _getAccessToken() {
		return localStorage.getItem('access_token');
	}
	function _getRefreshToken() {
		return localStorage.getItem('refresh_token');
	}
	function _clearToken() {
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
	}
	function _clearAll() {
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('profile');
		localStorage.removeItem('email');
	}
	function _setEmail(email) {
		localStorage.setItem('email', email);
	}
	function _getEmail() {
		return localStorage.getItem('email');
	}

	return {
		getService: _getService,
		setToken: _setToken,
		getAccessToken: _getAccessToken,
		getRefreshToken: _getRefreshToken,
		clearToken: _clearToken,
		clearAll: _clearAll,
		setEmail: _setEmail,
		getEmail: _getEmail
	};
})();
export default LocalStorageService;
