import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const DoctorCard = (params) => {
	const { doctor_details } = params;
	console.log('doctor_details', doctor_details);
	return (
		<Box sx={{ minWidth: 300 }}>
			<Card
				variant="outlined"
				sx={{
					borderColor: '#1db14c',
					borderRadius: 1,
					boxShadow: 2
				}}>
				<CardContent>
					<Typography variant="body1" component="div" color="#1db14c">
						Practitioner Id: {doctor_details.id}
					</Typography>
					<Typography variant="body1" component="div">
						<b>First Name:</b>
						{doctor_details?.name[0]
							? doctor_details?.name[0]?.given[0]
							: doctor_details?.name?.given[0]}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Middle Name:</b>
						{doctor_details?.name[0]
							? doctor_details?.name[0]?.given[1]
							: doctor_details?.name?.given[1]}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Family Name:</b>
						{doctor_details?.name[0]
							? doctor_details?.name[0]?.family
							: doctor_details?.name?.family}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Gender:</b> {doctor_details?.gender || ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>qualification:</b>
						{doctor_details?.qualification ? doctor_details?.qualification[0]?.code?.text : ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>PractitionerRole/Specialty:</b>
						{doctor_details?.practitionerRole
							? doctor_details?.practitionerRole[0]?.specialty[0]?.text
							: ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>PractitionerRole/Role:</b>
						{doctor_details?.practitionerRole
							? doctor_details?.practitionerRole[1]?.role?.text
							: ''}
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
};

export default DoctorCard;
