import React, { useEffect, useState, useRef, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {
	Grid,
	Typography,
	TextField,
	Button,
	MenuItem,
	OutlinedInput,
	Select,
	InputLabel,
	FormControl,
	CircularProgress
} from '@mui/material';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { post, update } from '../../services/services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomizedAlertBar from '../../components/customAlertBar';
import { getListPost } from './../../services/services';
import { useAuth } from '../../context/AuthProvider';
import { genderArray, providerRole } from '../../helpers/constants';
import { useLanguage } from '../../context/LanguageProvider';
import ProfileUpload from '../../components/ProfileUpload';
import axios from 'axios';
import { City, Country, State } from 'country-state-city';
import InputAutoComplete from '../../elements/InputAutoComplete';
import AddressFields from '../../elements/AddressFields';

const EditProvider = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { getLangString } = useLanguage();
	// const [user, setUser] = useState({});

	const data = useMemo(() => {
		return location.state.params.isEditMyProfile && location.state.params.isEditMyProfile === true
			? {
					...JSON.parse(localStorage.getItem('profile')),
					profile_pic: location.state.params.profile_pic
			  }
			: location.state.params;
	}, [location.state.params]);

	const alertRef = useRef(null);

	const { profile, tokenData } = useAuth();
	// console.log('data', data, profile);

	const isAdmin = tokenData?.role === 'admin';
	const isOrgAdmin = profile?.profile?.access_level === 'orgAdmin';
	const isDoctor = profile?.profile?.access_level === 'doctor';

	const [doctor_id, set_doctor_id] = useState(data.doctor_id ?? data.profile_id);
	const [fname, set_fname] = useState(data.doctor_fname ?? data.fname);
	const [mname, set_mname] = useState(data.doctor_mname ?? data.mname);
	const [lname, set_lname] = useState(data.doctor_lname ?? data.lname);
	const [dob, set_dob] = useState(data.doctor_dob ?? data.dob);
	const [gender, set_gender] = useState(data.doctor_gender ?? data.gender);
	const [email, set_email] = useState(data.doctor_contact_email ?? data.email);
	const [phone, set_phone] = useState(data.doctor_phone ?? data.phone);
	const [city, set_city] = useState(data.doctor_city ?? data.city);
	const [country, set_country] = useState(data.doctor_country ?? data.country);
	const [zip_code, set_zip_code] = useState(data.doctor_zip_code ?? data.zip_code);
	const [state, set_state] = useState(data.doctor_state ?? data.state);
	const [address, set_address] = useState(data.doctor_address ?? data.address);
	const [address2, set_address2] = useState(data.doctor_address2 ?? data.address2);
	const [brief, set_brief] = useState(data.doctor_brief ?? data.brief);
	const [clinic_id, set_clinic_id] = useState(data.doctor_clinic_id ?? data.clinic_id);
	const [department, set_department] = useState(data.doctor_department ?? data.department);
	const [role, set_role] = useState(
		providerRole.find((item) => item.label === data.doctor_role ?? data.role)
			? data.doctor_role ?? data.role
			: 'Surgeon'
	);
	const [access_level, set_access_level] = useState(data.doctor_access_level ?? data.access_level);
	const [loading, set_loading] = useState(true);
	const [clinic_Array, set_clinic_Array] = useState([]);
	const [loaded, set_loaded] = useState(false);
	const [profile_pic, set_profile_pic] = useState(data.profile_pic);
	const [new_profile_pic, set_new_profile_pic] = useState(null);

	const [countries, set_countries] = useState(Country.getAllCountries());
	const [states, set_states] = useState([]);
	const [cities, set_cities] = useState([]);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	let values = {
		doctor_id,
		fname,
		mname,
		lname,
		dob,
		gender,
		email,
		phone,
		city,
		country,
		zip_code,
		state,
		address,
		address2,
		brief,
		clinic_id,
		department,
		role,
		access_level,
		profile_pic
	};

	const accessLevels = [
		{ label: 'OrgAdmin', value: 'orgAdmin' },
		{ label: 'Doctor', value: 'doctor' }
	];
	// const accessLevels = isAdmin || isOrgAdmin
	// 	? [
	// 			{ label: 'OrgAdmin', value: 'orgAdmin' },
	// 			{ label: 'Doctor', value: 'doctor' }
	// 	  ]
	// 	: [{ label: 'Doctor', value: 'doctor' }];

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'clinic', action: 'getclinics' };
			let data = await getListPost(urlParams);
			set_clinic_Array(data.data);
			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	useEffect(() => {
		// logging on every change
		console.log('FROM useEffects');
		console.log(values);
	});

	useEffect(() => {
		set_doctor_id(data.doctor_id ?? data.profile_id);
		set_fname(data.doctor_fname ?? data.fname);
		set_mname(data.doctor_mname ?? data.mname);
		set_lname(data.doctor_lname ?? data.lname);
		set_dob(data.doctor_dob ?? data.dob);
		set_gender(data.doctor_gender ?? data.gender);
		set_email(data.doctor_contact_email ?? data.email);
		set_phone(data.doctor_phone ?? data.phone);
		set_city(data.doctor_city ?? data.city);
		set_country(data.doctor_country ?? data.country);
		set_zip_code(data.doctor_zip_code ?? data.zip_code);
		set_state(data.doctor_state ?? data.state);
		set_phone(data.doctor_phone ?? data.phone);
		set_address(data.doctor_address ?? data.address);
		set_address2(data.doctor_address2 ?? data.address2);
		set_clinic_id(data.doctor_clinic_id ?? data.clinic_id);
		set_department(data.doctor_department ?? data.department);
		set_role(
			providerRole.find((item) => item.label === data.doctor_role ?? data.role)
				? data.doctor_role ?? data.role
				: 'Surgeon'
		);
		set_access_level(data.doctor_access_level ?? data.access_level);
		set_brief(data.doctor_brief ?? data.brief);
	}, [data]);

	const onSubmit = async () => {
		try {
			const urlParams = { resource: 'user', action: 'updateProvider' };
			const response = await update(urlParams, values);
			console.log('updateProvider', response);
			const response1 = await handleChangeProfilePic(data.doctor_user_id, new_profile_pic);
			console.log('handleChangeProfilePic', response1);

			if (response.status === 200)
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const handleChangeProfilePic = async (user_id, new_pic) => {
		try {
			if (!new_profile_pic) return;

			const urlParams = { resource: 'user', action: 'getProfileUploadUrlGeneric' };
			const res = await post(urlParams, {
				user_id
			});

			const url = res.data;
			// const file = event.target.files[0];

			var instance = axios.create();
			instance
				.put(url, new_pic)
				.then(async function (result) {
					if (result.status === 200) {
						// alertRef.current.handleClick({
						// 	message: 'UPDATED! PAGE WILL REFRESH SOON!',
						// 	severity: 'success'
						// });
					}
				})
				.catch(function (err) {
					alertRef.current.handleClick({
						message: 'PHOTO NOT UPDATED!',
						severity: 'error'
					});
					console.log('catch', err);
				});
		} catch (err) {
			console.error(err);
		}
	};

	const theme = createTheme();

	// get states after country selection
	useEffect(() => {
		set_states(
			State.getStatesOfCountry(
				Country?.getAllCountries()?.find((c) => {
					return c?.name === country;
				})?.isoCode
			)
		);
	}, [country]);

	// get cities after state selection
	useEffect(() => {
		let selectedState = states.find((c) => {
			return c?.name === state;
		});
		const cities = City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode);
		if (cities?.length === 0) return set_cities([{ name: state, isoCode: '0' }]);
		// {
		// 	"name": "Lower Austria",
		// 	"isoCode": "3",
		// 	"countryCode": "AT",
		// 	"latitude": "48.10807700",
		// 	"longitude": "15.80495580"
		// }
		set_cities(cities);
	}, [state]);

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} path={'/Provider'} />
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 2 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Toolbar>
						<Typography variant="h6" align="left" color="#1db14c">
							{getLangString('edit_provider')}
						</Typography>
					</Toolbar>
				</Paper>
			</Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
					<Paper
						variant="outlined"
						sx={{
							my: { xs: 3, md: 2 },
							p: { xs: 2, md: 3 },
							borderRadius: 2,
							boxShadow: 2,
							borderColor: '#1db14c'
						}}>
						<React.Fragment>
							<Typography variant="h6" gutterBottom>
								{getLangString('provider_info')}
							</Typography>

							<ProfileUpload
								profile_pic={profile_pic}
								new_profile_pic={new_profile_pic}
								set_new_profile_pic={set_new_profile_pic}
							/>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<TextField
										required
										id="firstName"
										name="firstName"
										label={getLangString('first_name')}
										fullWidth
										variant="outlined"
										value={fname}
										onChange={(e) => set_fname(e.target.value)}
									/>
									{errors.fname && <p>first name is required.</p>}
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id="MiddleName"
										name="MiddleName"
										label={getLangString('middle_name')}
										fullWidth
										variant="outlined"
										value={mname}
										onChange={(e) => set_mname(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										required
										id="lastName"
										name="lastName"
										label={getLangString('last_name')}
										fullWidth
										variant="outlined"
										value={lname}
										onChange={(e) => set_lname(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											required
											id="dob"
											name="dob"
											label={getLangString('date_of_birth')}
											openTo="year"
											views={['year', 'month', 'day']}
											onChange={(newValue) => {
												set_dob(newValue);
												console.log('newValue', newValue);
											}}
											inputFormat="MM-dd-yyyy"
											value={dob}
											renderInput={(params) => (
												<TextField required {...params} sx={{ width: '100%' }} />
											)}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={12} sm={3}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="Select Gender">{getLangString('gender')}</InputLabel>
										<Select
											id="gender"
											name="gender"
											input={<OutlinedInput label={getLangString('gender')} />}
											fullWidth
											variant="outlined"
											value={gender}
											onChange={(e) => {
												set_gender(e.target.value);
											}}>
											{genderArray.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={3}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="role">{getLangString('role')}</InputLabel>
										<Select
											id="role"
											name="role"
											input={<OutlinedInput label={getLangString('role')} />}
											fullWidth
											variant="outlined"
											value={role}
											onChange={(e) => {
												set_role(e.target.value);
											}}>
											{providerRole.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={3}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="Access Level">{getLangString('access_level')}</InputLabel>
										<Select
											id="accessLevel"
											name="accessLevel"
											input={<OutlinedInput label={getLangString('access_level')} />}
											fullWidth
											variant="outlined"
											value={access_level}
											disabled={isDoctor}
											onChange={(e) => {
												set_access_level(e.target.value);
											}}>
											{accessLevels.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={7}>
									<FormControl required fullWidth>
										<InputLabel htmlFor="clinic">{getLangString('clinic_hospital')}</InputLabel>
										<Select
											id="clinic"
											name="clinic"
											input={<OutlinedInput label={getLangString('clinic_hospital')} />}
											fullWidth
											variant="outlined"
											value={clinic_id}
											disabled={true}
											onChange={(e) => {
												isAdmin && set_clinic_id(e.target.value);
											}}>
											{clinic_Array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={5}>
									<TextField
										required
										id="department"
										name="department"
										label={getLangString('department')}
										fullWidth
										variant="outlined"
										value={department}
										onChange={(e) => set_department(e.target.value)}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										id="email"
										name="email"
										label={getLangString('contact_email')}
										fullWidth
										variant="outlined"
										value={email}
										onChange={(e) => set_email(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										required
										id="phone"
										name="phone"
										label={getLangString('phone')}
										fullWidth
										variant="outlined"
										value={phone}
										onChange={(e) => set_phone(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										required
										id="address"
										name="address"
										label={getLangString('first_address')}
										fullWidth
										variant="outlined"
										value={address}
										onChange={(e) => set_address(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="address2"
										name="address2"
										label={getLangString('second_address')}
										fullWidth
										variant="outlined"
										value={address2}
										onChange={(e) => set_address2(e.target.value)}
									/>
								</Grid>

								<AddressFields
									country={country}
									state={state}
									city={city}
									set_country={set_country}
									set_state={set_state}
									set_city={set_city}
								/>

								<Grid item xs={12} sm={3}>
									<TextField
										required
										id="zipcode"
										name="zipcode"
										label={getLangString('zip_code')}
										fullWidth
										variant="outlined"
										value={zip_code}
										onChange={(e) => set_zip_code(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} xl={12}>
									<TextField
										id="brief"
										name="brief"
										label={getLangString('note')}
										fullWidth
										multiline
										rows={3}
										value={brief}
										onChange={(e) => set_brief(e.target.value)}
										variant="outlined"
									/>
								</Grid>

								<Grid container item xs={12} xl={12} mt={2} display="flex" spacing={0.5}>
									<Grid item sm={0} md={8.4} lg={9.6} xl={10}></Grid>

									<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
										<Box textAlign="left">
											<Button
												variant="outlined"
												color="success"
												type="button"
												onClick={() => {
													navigate(-1);
												}}>
												{getLangString('cancel')}
											</Button>
										</Box>
									</Grid>
									<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
										<Box textAlign="left">
											<Button variant="contained" color="success" type="submit">
												{getLangString('update')}
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</React.Fragment>
					</Paper>
				</Container>
			</form>
		</ThemeProvider>
	);
};

export default EditProvider;
