import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLanguage } from '../../context/LanguageProvider';
import { Box, InputAdornment, MenuItem, Select } from '@mui/material';
import { create } from '../../services/services';
import { useForm } from 'react-hook-form';
import CustomizedAlertBar from '../customAlertBar';
import { COLORS } from './../../context/colors';

export default function AddCptCodeDailog({ open, setOpen, fetchCPTCodes }) {
	const [formData, setFormData] = useState({
		code_category: '',
		cpt_code: '',
		cpt_code_description: ''
	});

	const alertRef = useRef(null);

	const { getLangString } = useLanguage();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	// const [open, setOpen] = useState(false);

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };

	const handleChange = (field, value) => {
		setFormData((formData) => ({ ...formData, [field]: value }));
	};

	const handleClose = () => {
		setFormData({
			code_category: '',
			cpt_code: '',
			cpt_code_description: ''
		});
		setOpen(false);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			console.log('first');
			if (isNaN(formData.cpt_code)) return;
			const urlParams = { resource: 'v2/procedure', action: 'createCPTcode' };
			const response = await create(urlParams, formData);
			if (response.status === 200 || response.status === 201) {
				alertRef.current.handleClick({
					message: response?.data?.data?.message?.toUpperCase(),
					severity: 'success'
				});
				await fetchCPTCodes(response?.data?.data?.id);

				handleClose();
			}
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	return (
		<>
			<CustomizedAlertBar ref={alertRef} />
			{/* //{' '} */}
			{/* <form onSubmit={handleSubmit(onSubmit)}> */}
			<Dialog open={open} onClose={handleClose} fullWidth fullScreen={fullScreen}>
				<DialogTitle>{getLangString('add_new_cpt_code')}</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>Add new medicine</DialogContentText> */}
					<Box component="form" onSubmit={onSubmit} id="submitForm">
						<TextField
							autoFocus
							margin="dense"
							id="code_category"
							label={getLangString('code_category')}
							type="text"
							fullWidth
							required
							value={formData.code_category}
							onChange={(event) => {
								handleChange('code_category', event.target.value);
							}}
							variant="outlined"
							sx={{ mb: 3 }}
						/>
						<TextField
							autoFocus
							margin="dense"
							id="code_category"
							label={getLangString('cpt_code')}
							type="text"
							fullWidth
							required
							value={formData.cpt_code}
							onChange={(event) => {
								handleChange('cpt_code', event.target.value);
							}}
							variant="outlined"
							error={isNaN(formData.cpt_code)}
							helperText={isNaN(formData.cpt_code) && 'Only Numbers Are Allowed'}
							sx={{ mb: 3 }}
						/>
						<TextField
							autoFocus
							margin="dense"
							id="cpt_code_description"
							label={getLangString('cpt_code_description')}
							type="text"
							fullWidth
							required
							value={formData.cpt_code_description}
							onChange={(event) => {
								handleChange('cpt_code_description', event.target.value);
							}}
							variant="outlined"
							sx={{ mb: 3 }}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{getLangString('cancel')}</Button>
					<Button
						// onClick={onSubmit}
						form="submitForm"
						variant="contained"
						type="submit"
						sx={{ backgroundColor: COLORS.RaisinPurple }}>
						{getLangString('add')}
					</Button>
				</DialogActions>
			</Dialog>
			{/* //{' ' */}
			{/* </form> */}
		</>
	);
}
