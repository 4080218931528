import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormControl, FormHelperText, MenuItem, Typography } from '@mui/material';
import { styled, lighten, darken } from '@mui/system';

export default function InputAutoComplete({
	field,
	label,
	value,
	inputValue = undefined,
	options = [],
	onChange,
	groupBy = undefined,
	onInputChange = undefined,
	renderGroup = undefined,
	getOptionLabel = undefined,
	isOptionEqualToValue = undefined,
	errorText,
	sx = {},
	required = false,
	disabled = false
}) {
	const handleChange = (event, newValue, reason) => {
		// console.log('event, newValue', event, newValue);
		onChange && onChange(field, newValue, event);

		// onChangeActions && onChangeActions();
	};

	return (
		<FormControl
			fullWidth
			variant="outlined"
			error={!!errorText}
			size="small"
			sx={{
				...sx
			}}>
			<Autocomplete
				id={field}
				options={options}
				getOptionLabel={getOptionLabel ?? ((option) => (option?.display ? option?.display : ''))}
				isOptionEqualToValue={
					isOptionEqualToValue ??
					((option, value) => {
						// console.log('optionkey', option, value);
						return option?.key === value?.key;
					})
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						label={label}
						placeholder={field}
						required={required}
					/>
				)}
				renderGroup={
					renderGroup
						? (params) => (
								<div key={params.key}>
									<li key={params.key}>
										<GroupHeader>{params.group}</GroupHeader>
										<GroupItems>{params.children}</GroupItems>
									</li>
								</div>
						  )
						: null
				}
				renderOption={(props, option) => {
					// console.log('props option', props, option);
					return (
						<div key={option.key}>
							<li {...props} key={option.key}>
								{option.display}
							</li>
						</div>
					);
				}}
				groupBy={groupBy ?? null}
				noOptionsText={
					<div>
						<MenuItem disabled>
							<Typography sx={{ fontSize: '16px', color: 'black' }}>No matches</Typography>
						</MenuItem>
					</div>
				}
				value={value}
				onChange={handleChange}
				inputValue={inputValue}
				onInputChange={onInputChange}
				disabled={disabled}
			/>
			<FormHelperText>{errorText}</FormHelperText>
		</FormControl>
	);
}

const GroupHeader = styled('div')(({ theme }) => ({
	position: 'sticky',
	top: '-8px',
	padding: '4px 10px',
	fontWeight: 'bold',
	fontSize: '18px',
	backgroundColor:
		theme.palette.mode === 'light'
			? lighten(theme.palette.primary.light, 1)
			: darken(theme.palette.primary.main, 1)
}));

const GroupItems = styled('ul')({
	padding: 0
});
