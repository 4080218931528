import React, { useEffect, useState } from 'react';
import InputAutoComplete from './InputAutoComplete';
import { Grid } from '@mui/material';
import { Country, State, City } from 'country-state-city';
import { useLanguage } from '../context/LanguageProvider';

export default function AddressFields({ country, state, city, set_country, set_state, set_city }) {
	const { getLangString } = useLanguage();

	// country, state, city

	const [country_local, set_country_local] = useState({
		key: 'US',
		display: 'United States'
	});
	const [state_local, set_state_local] = useState(null);
	const [city_local, set_city_local] = useState(null);

	const [countries, set_countries] = useState(Country.getAllCountries());
	const [states, set_states] = useState([]);
	const [cities, set_cities] = useState([]);

	useEffect(() => {
		set_country(country_local?.display);
	}, [country_local, set_country]);
	useEffect(() => {
		set_state(state_local?.display);
	}, [set_state, state_local]);
	useEffect(() => {
		set_city(city_local?.display);
	}, [city_local, set_city]);

	useEffect(() => {
		let c, s;
		if (country)
			set_country_local(() => {
				c = Country?.getAllCountries()?.find((c) => {
					// console.log('c', c, country);
					return c?.name === country;
				});
				return { key: c?.isoCode, display: c?.name };
			});
		if (state)
			set_state_local(() => {
				s = State?.getStatesOfCountry(c?.isoCode).find((d) => {
					return d.name === state;
				});
				// console.log('s', s);
				return { key: s?.isoCode, display: s?.name };
			});
		if (city)
			set_city_local(() => {
				const ci = City?.getCitiesOfState(s?.countryCode, s?.isoCode).find((d) => {
					return d.name === city;
				});
				// console.log('ci', ci);
				return { key: ci?.stateCode, display: ci?.name };
			});
		// state;
		// city;
	}, []);

	// get states after country selection
	useEffect(() => {
		set_states(
			State.getStatesOfCountry(
				Country?.getAllCountries()?.find((c) => {
					return c?.name === country_local?.display;
				})?.isoCode
			)
		);
	}, [country_local]);

	// get cities after state selection
	useEffect(() => {
		let selectedState = states.find((c) => {
			return c?.name === state_local?.display;
		});
		const cities = City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode);
		// console.log('cities', cities, selectedState, state_local);
		if (cities?.length === 0 && state_local)
			return set_cities([{ name: state_local?.display, isoCode: '0' }]);
		// {
		// 	"name": "Lower Austria",
		// 	"isoCode": "3",
		// 	"countryCode": "AT",
		// 	"latitude": "48.10807700",
		// 	"longitude": "15.80495580"
		// }
		set_cities(cities);
	}, [state_local]);

	return (
		<>
			<Grid item xs={12} sm={3}>
				<InputAutoComplete
					value={country_local}
					field={'Country'}
					onChange={(field, value) => {
						set_state_local({});
						set_city_local({});
						set_country_local(value);
					}}
					label={getLangString('country')}
					options={countries.map((d) => {
						return {
							key: d?.isoCode,
							display: d?.name
						};
					})}
					isOptionEqualToValue={null}
					required={true}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<InputAutoComplete
					value={state_local}
					field={'State'}
					onChange={(field, value) => {
						set_city_local({});
						set_state_local(value);
					}}
					label={getLangString('state')}
					options={states.map((d) => {
						return {
							key: d.isoCode,
							display: d.name
						};
					})}
					required={true}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<InputAutoComplete
					value={city_local}
					field={'City'}
					onChange={(field, value) => {
						set_city_local(value);
					}}
					label={getLangString('city')}
					options={cities.map((d) => {
						// console.log('d cities', d)
						// {
						// 	"name": "Eudora",
						// 	"countryCode": "US",
						// 	"stateCode": "AR",
						// 	"latitude": "33.10957000",
						// 	"longitude": "-91.26206000"
						// }
						return {
							key: d.name,
							display: d.name
						};
					})}
					required={true}
				/>
			</Grid>
		</>
	);
}
