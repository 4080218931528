import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useLanguage } from '../context/LanguageProvider';
import { supportedLangauges } from '../context/locale';

export default function LanguageSelector() {
	const { getLangString, lang, changeLang } = useLanguage();

	if (supportedLangauges.length < 2) return <></>;

	return (
		<Box
			sx={{
				marginTop: '16px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: '8px'
			}}>
			<Typography>{getLangString('select_language')}</Typography>
			<Box display={'flex'} sx={{ gap: '8px' }}>
				{supportedLangauges.map((L) => (
					<Button
						key={L}
						sx={lang !== L ? { background: 'white' } : {}}
						variant={lang === L ? 'contained' : 'outlined'}
						onClick={() => {
							changeLang(L);
						}}>
						{L.toUpperCase()}
					</Button>
				))}
			</Box>
		</Box>
	);
}
