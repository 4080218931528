import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const SurgeryDialog = forwardRef((props, ref) => {
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [patient_info, set_patient_info] = useState({});
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	useImperativeHandle(ref, () => ({
		handleClickOpen(params) {
			setOpen(true);
			set_patient_info(params);
		}
	}));

	const handleClose = (answer, reason) => {
		if (reason && reason === 'backdropClick') return;
		if (answer === 'yes') {
			navigate('/NewPatientSurgery', { state: { params: { ...patient_info } } });
			setOpen(false);
		}
		setOpen(false);
		// navigate(-1);
	};

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				// disableBackdropClick
				aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">{'Create New Surgery?'}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you want to create surgery for patient <b>{patient_info.full_name}</b>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={() => {
							handleClose('no');
						}}>
						NO
					</Button>
					<Button
						onClick={() => {
							handleClose('yes');
						}}
						autoFocus>
						YES
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
});

export default SurgeryDialog;
