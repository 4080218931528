import React, { useState, createContext, useEffect } from 'react';
import locale, { supportedLangauges } from './locale';

const LanguageContext = createContext({
	lang: 'end',
	changeLang: (val) => {},
	getLangString: (key) => {}
});

export const LanguageProvider = ({ children }) => {
	const [lang, setLang] = useState('en');

	const changeLang = (val) => {
		console.log('val', val);
		localStorage.setItem('language', val);
		setLang(val);
	};

	useEffect(() => {
		let deviceLang =
			localStorage.getItem('language') ||
			window.navigator.userLanguage ||
			window.navigator.language;

		//make sure language is supported, revert to english
		if (!supportedLangauges.includes(deviceLang)) {
			deviceLang = 'en';
		}

		//get DB lang logic
		/* do something */

		console.log('deviceLang', deviceLang);
		localStorage.setItem('language', deviceLang);
		setLang(deviceLang);
	}, []);

	const getLangString = (key) => {
		return locale[lang][key];
	};

	return (
		<LanguageContext.Provider value={{ lang, changeLang, getLangString }}>
			{children}
		</LanguageContext.Provider>
	);
};

export const useLanguage = () => React.useContext(LanguageContext);
