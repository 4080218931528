import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
	Grid,
	Typography,
	TextField,
	Button,
	Stack,
	Box,
	Select,
	InputLabel,
	FormControl,
	OutlinedInput,
	MenuItem,
	CircularProgress,
	Divider
} from '@mui/material';
import _ from 'lodash';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper, FormControlLabel, Switch } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IOSSwitch } from '../../components/IosSwitchButton';
import { update, getList } from '../../services/services';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getTodayDate } from '../../helpers/customFunctions';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useLanguage } from '../../context/LanguageProvider';

const EditClinic = (params) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { getLangString } = useLanguage();
	const data = location.state.params;
	const alertRef = useRef(null);

	const [clinic_id, set_clinic_id] = useState(data.clinic_id);
	const [clinic_name, set_clinic_name] = useState(data.clinic_name);
	const [clinic_license_template_id, set_clinic_license_template_id] = useState(data.license_id);
	const [clinic_license_activation_time, set_clinic_license_activation_time] =
		useState(getTodayDate);
	const [clinic_license_num_users, set_clinic_license_num_users] = useState(
		data.clinic_license_num_users
	);
	const [clinic_location, set_clinic_location] = useState(data.clinic_location);
	const [clinic_email, set_clinic_email] = useState(data.clinic_email);
	const [clinic_phone, set_clinic_phone] = useState(data.clinic_phone);
	const [clinic_address, set_clinic_address] = useState(data.clinic_address);
	const [clinic_license_is_active, set_clinic_license_is_active] = useState(
		data.clinic_license_is_active === 1 ? true : false
	);
	const [clinic_brief, set_clinic_brief] = useState(data.clinic_brief);
	const [loading, set_loading] = useState(true);
	const [licence_types, set_licence_types] = useState([]);
	const [loaded, set_loaded] = useState(false);

	// Epic Config
	const [epic_organization_name, set_epic_organization_name] = useState(
		data.emr_organization_name || ''
	);
	const [epic_base_url, set_epic_base_url] = useState(data.emr_endpoint || '');
	const [epic_api_version, set_epic_api_version] = useState(data.emr_api_version || '');

	const [available_integrations, set_available_integrations] = useState([]);
	const [integration_id, set_integration_id] = useState(data.emr_type_id || '');

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const values = {
		clinic_id,
		clinic_name,
		clinic_address,
		clinic_phone,
		clinic_email,
		clinic_location,
		clinic_brief,
		license_id: clinic_license_template_id,
		number_of_users: clinic_license_num_users,
		license_active: clinic_license_is_active === true ? 1 : 0,
		clinic_license_activation_time
	};

	useEffect(() => {
		// logging on every change
		console.log('FROM useEffects');
		console.log(values);
	});

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'clinic', action: 'getLicenseTypes' };
			const licenceResult = await getList(urlParams);
			set_licence_types(licenceResult.data);

			const urlParams2 = { resource: 'integration', action: 'getIntegrationsType' };
			const integrationResult = await getList(urlParams2);
			set_available_integrations(integrationResult);

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	const onSubmit = async () => {
		try {
			let data = {};
			if (
				integration_id !== '' ||
				epic_organization_name !== '' ||
				epic_base_url !== '' ||
				epic_api_version !== ''
			) {
				if (
					integration_id !== '' &&
					epic_organization_name !== '' &&
					epic_base_url !== '' &&
					epic_api_version !== ''
				)
					data = {
						...values,
						integration_id,
						clinic_epic_resource: {
							epic_organization_name,
							epic_base_url,
							epic_api_version
						}
					};
				else {
					alertRef.current.handleClick({
						message: 'COMPLETE EPIC CONFIGURATION',
						severity: 'error'
					});
					return;
				}
			} else {
				data = values;
			}
			const urlParams = { resource: 'clinic', action: 'updateclinic' };
			const response = await update(urlParams, data);
			console.log(response);
			if (response.status === 200)
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} path={'/Clinic'} />
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 2 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Toolbar>
						<Typography variant="h6" align="left" color="#1db14c">
							{getLangString('edit_clinic_title')}
						</Typography>
					</Toolbar>
				</Paper>
			</Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
					<Box sx={{ mt: 1 }}>
						<Paper
							variant="outlined"
							sx={{
								borderRadius: 2,
								boxShadow: 2,
								borderColor: '#1db14c',
								my: { xs: 3, md: 2 },
								p: { xs: 2, md: 2 }
							}}>
							<React.Fragment>
								<Typography variant="h6" gutterBottom>
									{getLangString('clinic_info')}
								</Typography>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											fullWidth
											variant="outlined"
											value={clinic_name}
											onChange={(e) => set_clinic_name(e.target.value)}
										/>
										{errors.clinic_name && <p>Clinic name is required.</p>}
									</Grid>
									{/* <Grid item xs={12} sm={4}>
										<TextField
											required
											id="clinic_license_template_id"
											name="clinic_license_template_id"
											label="Licence Type"
											fullWidth
											value={clinic_license_template_id}
											variant="outlined"
											onChange={(e) => set_clinic_license_template_id(e.target.value)}
										/>
									</Grid> */}
									<Grid item xs={12} sm={4}>
										<FormControl fullWidth>
											<InputLabel htmlFor="licenceType">
												{getLangString('clinic_licence_type')}
											</InputLabel>
											<Select
												id="clinic_license_template_id"
												name="clinic_license_template_id"
												input={<OutlinedInput label={getLangString('clinic_licence_type')} />}
												fullWidth
												variant="outlined"
												value={clinic_license_template_id}
												onChange={(e) => {
													console.log('e.target', e.target);
													set_clinic_license_template_id(e.target.value);
													let obj = _.find(licence_types, {
														license_id: e.target.value
													});
													set_clinic_license_num_users(obj.license_num_users);
												}}>
												{licence_types.map((option) => (
													<MenuItem key={option.license_id} value={option.license_id}>
														{option.license_name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={4}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												id="licenceActiveDate"
												name="licenceActiveDate"
												label={getLangString('clinic_licence_active_date')}
												openTo="year"
												views={['year', 'month', 'day']}
												onChange={(newValue) => {
													set_clinic_license_activation_time(newValue);
												}}
												inputFormat="MM-dd-yyyy"
												value={clinic_license_activation_time}
												renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="numberOfUsers"
											name="numberOfUsers"
											label={getLangString('clinic_licence_users_nb')}
											fullWidth
											variant="outlined"
											value={clinic_license_num_users}
											onChange={(e) => set_clinic_license_num_users(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="location"
											name="location"
											label={getLangString('location')}
											fullWidth
											variant="outlined"
											value={clinic_location}
											onChange={(e) => set_clinic_location(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											id="email"
											name="email"
											label={getLangString('email')}
											fullWidth
											variant="outlined"
											value={clinic_email}
											onChange={(e) => set_clinic_email(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="phone"
											name="phone"
											label={getLangString('phone')}
											fullWidth
											variant="outlined"
											value={clinic_phone}
											onChange={(e) => set_clinic_phone(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											required
											id="address"
											name="address"
											label={getLangString('address')}
											fullWidth
											variant="outlined"
											value={clinic_address}
											onChange={(e) => set_clinic_address(e.target.value)}
										/>
									</Grid>
									<Grid item sx={{}} xs={12} sm={4}>
										<Stack spacing={1} alignItems="center">
											<Typography>{getLangString('active_deactivate')}</Typography>
											<FormControlLabel
												control={
													<IOSSwitch
														checked={clinic_license_is_active}
														onChange={(event) => {
															set_clinic_license_is_active(event.target.checked);
														}}
														inputProps={{ 'aria-label': 'ant design' }}
													/>
												}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} xl={12}>
										<TextField
											id="brief"
											name="brief"
											label={getLangString('brief')}
											fullWidth
											multiline
											rows={3}
											variant="outlined"
											value={clinic_brief}
											onChange={(e) => set_clinic_brief(e.target.value)}
										/>
									</Grid>

									<Grid item xs={12} sm={12}>
										<Divider sx={{ my: 3 }} variant="middle" />
									</Grid>

									<Grid item xs={12} sm={12}>
										<Typography variant="h6" gutterBottom>
											{getLangString('epic_title')}
										</Typography>
									</Grid>

									<Grid item xs={12} sm={12}>
										<FormControl fullWidth>
											<InputLabel htmlFor="integrationName">
												{getLangString('integreation_type')}
											</InputLabel>
											<Select
												id="integrationName"
												name="integrationName"
												input={<OutlinedInput label={getLangString('integreation_type')} />}
												fullWidth
												variant="outlined"
												value={integration_id}
												onChange={(e) => {
													set_integration_id(e.target.value);
												}}>
												{available_integrations.map((option) => (
													<MenuItem key={option.emr_type_id} value={option.emr_type_id}>
														{option.emr_type_name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											id="organizationName"
											name="organizationName"
											label={getLangString('organization_name')}
											fullWidth
											variant="outlined"
											value={epic_organization_name}
											onChange={(e) => set_epic_organization_name(e.target.value)}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<TextField
											id="epicBaseURL"
											name="epicBaseURL"
											label={getLangString('base_url')}
											fullWidth
											variant="outlined"
											value={epic_base_url}
											onChange={(e) => set_epic_base_url(e.target.value)}
										/>
									</Grid>

									<Grid item xs={12} sm={4}>
										<FormControl fullWidth>
											<InputLabel htmlFor="epicApiVersion">
												{getLangString('api_version')}
											</InputLabel>
											<Select
												id="epicApiVersion"
												name="epicApiVersion"
												input={<OutlinedInput label={getLangString('api_version')} />}
												fullWidth
												variant="outlined"
												value={epic_api_version}
												onChange={(e) => {
													set_epic_api_version(e.target.value);
												}}>
												{[
													{ label: 'DSTU2', value: 'api/FHIR/DSTU2' },
													{ label: 'STU3', value: 'api/FHIR/STU3' },
													{ label: 'R4', value: 'api/FHIR/R4' }
												].map((option) => (
													<MenuItem key={option.label} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid container item xs={12} xl={12} mt={2} display="flex" spacing={0.5}>
										<Grid item sm={0} md={8.4} lg={9.6} xl={10}></Grid>

										<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
											<Box textAlign="left">
												<Button
													variant="outlined"
													color="success"
													type="button"
													onClick={() => {
														navigate(-1);
													}}>
													{getLangString('cancel')}
												</Button>
											</Box>
										</Grid>
										<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
											<Box textAlign="left">
												<Button variant="contained" color="success" type="submit">
													{getLangString('update')}
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
						</Paper>
					</Box>
				</Container>
			</form>
		</ThemeProvider>
	);
};

export default EditClinic;
