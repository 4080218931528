import * as React from 'react';
import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {
	Avatar,
	Box,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	ListSubheader,
	MenuItem,
	Radio,
	Select,
	styled
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useEffect } from 'react';
import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { COLORS } from '../../context/colors';
import { useNavigate } from 'react-router-dom';
import { post } from '../../services/services';
import { useLanguage } from '../../context/LanguageProvider';

const NotificationList = ({ id, open, anchorEl, setAnchorEl, notificationList }) => {
	const navigate = useNavigate();
	const { getLangString } = useLanguage();

	const [loading, set_loading] = useState(true);
	const [selected_notifications, set_selected_notifications] = useState([]);
	const [displayed_notifications, set_displayed_notifications] = useState(1);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const UpdateNotificationSeen = () => {
		const Unseen = notificationList?.filter((item) => item.seen_status === '0');
		Unseen?.map(async (item) => {
			const urlParams = { resource: 'log', action: 'NotificationSeen' };
			await post(urlParams, { id: item.id });
		});
	};

	useEffect(() => {
		if (open === true) UpdateNotificationSeen();
	}, [open]);

	useEffect(() => {
		if (notificationList?.length > 0) {
			set_loading(false);
			set_selected_notifications(notificationList);
		} else {
			set_selected_notifications([]);
			set_loading(false);
		}
	}, [notificationList]);

	const StyledTypography = styled(Typography)({
		display: 'inline',
		fontFamily: 'Inter',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '20px'
	});

	const StyledCircle = styled('div')({
		boxSizing: 'border-box',
		width: 8,
		height: 8,
		background: '#90CDF4',
		border: '1px solid #4299E1',
		borderRadius: 4,
		margin: '8px 0px 0px 8px',
		order: 0
	});

	const TypographyStyles = {
		display: 'inline',
		fontFamily: 'Inter',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '20px'
	};

	return (
		<div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				// onBlur={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				sx={{
					'& .MuiPopover-paper': {
						variant: 'outlined',
						width: 440,
						height: 360,
						borderRadius: 2
					}
				}}>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							width: '100%'
						}}>
						<CircularProgress />
					</Box>
				) : (
					<List
						sx={{ width: '100%', bgcolor: 'background.paper' }}
						component="nav"
						aria-labelledby="nested-list-subheader"
						subheader={
							<>
								<ListSubheader
									component="div"
									id="nested-list-subheader"
									sx={{
										px: 2,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'start'
									}}>
									<Typography sx={{ ...TypographyStyles, color: 'black', marginRight: '10px' }}>
										{getLangString('notifications')}
									</Typography>
									<FormControl>
										<Select
											variant={'outlined'}
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={displayed_notifications}
											// label="Age"
											onChange={(e) => {
												set_displayed_notifications(e.target.value);
												if (e.target.value === 1)
													return set_selected_notifications(notificationList);

												const newArr = notificationList?.filter(
													(item) => item.seen_status === (e.target.value === 2 ? '1' : '0')
												);
												set_selected_notifications(newArr);
											}}
											sx={{
												boxShadow: 'none',
												'.MuiOutlinedInput-notchedOutline': { border: 0 }
											}}>
											{/* color of menu items should be sx={{ backgroundColor: COLORS.Gray_600 }} */}
											<MenuItem value={1}>
												<StyledTypography>{getLangString('all')}</StyledTypography>
											</MenuItem>
											<MenuItem value={2}>
												<StyledTypography>{getLangString('read')}</StyledTypography>
											</MenuItem>
											<MenuItem value={3}>
												<StyledTypography>{getLangString('unseen')}</StyledTypography>
											</MenuItem>
										</Select>
									</FormControl>
									{/* <FormControlLabel
										value={''}
										label={
											<Typography sx={{ color: 'black', fontSize: 15 }}>
												{getLangString('mark_all_as_read')}
											</Typography>
										}
										control={<Radio />}
										labelPlacement="start"
										sx={{ marginLeft: 'auto' }}
									/> */}
								</ListSubheader>
								{selected_notifications.length === 0 ? (
									<>
										<ListItem
											sx={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '100%',
												width: '100%'
											}}>
											<Typography>{getLangString('no_notifications')}</Typography>
										</ListItem>
									</>
								) : (
									selected_notifications?.map((item, index) => {
										const date = new Date(item.insert_date);
										const words = item.display_notification.split(' ');
										const text =
											words.includes('elevated') && words.includes('risk') ? (
												<Box>
													{words.map((word, index) =>
														word.toLowerCase() === 'elevated' || word.toLowerCase() === 'risk' ? (
															<Typography sx={{ ...TypographyStyles, color: 'red' }} key={index}>
																{word}{' '}
															</Typography>
														) : index === 0 || index === 1 ? (
															<Typography
																sx={{ ...TypographyStyles, fontWeight: '600' }}
																key={index}>
																{`${word}`}
															</Typography>
														) : (
															<Typography sx={{ ...TypographyStyles }} key={index}>
																{word}{' '}
															</Typography>
														)
													)}
												</Box>
											) : (
												<Box>
													{words.map((word, index) =>
														index === 0 || index === 1 ? (
															<Typography
																sx={{ ...TypographyStyles, fontWeight: '600' }}
																key={index}>
																{`${word}`}
															</Typography>
														) : (
															<Typography sx={{ ...TypographyStyles }} key={index}>
																{word}{' '}
															</Typography>
														)
													)}
												</Box>
											);
										const isUnseen = item.seen_status === '0';
										// const time = item.insert_date.split('T')[1].split('.')[0].slice(0, 5);
										const time = format(parseISO(item.insert_date), 'hh:mm a');
										return (
											<React.Fragment key={item.id}>
												<ListItemButton
													// onClick={() => {
													// 	navigate(`/patientHistory/${item.user_id}`);
													// }}
													sx={{
														height: '100px',
														position: 'relative',
														p: '8px 0px 16px 16px'
													}}>
													{isUnseen ? (
														<StyledCircle sx={{ position: 'absolute', top: 0, left: 0 }} />
													) : (
														<></>
													)}
													<ListItemAvatar
														sx={{
															minWidth: 50
														}}>
														{item.profile_pic ? (
															<Avatar src={item.profile_pic} />
														) : (
															<Avatar>
																<ImageIcon />
															</Avatar>
														)}
													</ListItemAvatar>
													<ListItemText
														primary={text}
														secondary={`${
															isToday(date)
																? getLangString('today')
																: isYesterday(date)
																? getLangString('yesterday')
																: format(date, 'eee dd-MM-yyyy')
														} at ${time}`}
														secondaryTypographyProps={{ fontFamily: 'Inter', mt: 2.5 }}
													/>
												</ListItemButton>
												{index === notificationList.length - 1 ? <></> : <Divider />}
											</React.Fragment>
										);
									})
								)}
							</>
						}></List>
				)}
			</Popover>
		</div>
	);
};

export default NotificationList;
