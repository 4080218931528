import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useNavigate } from 'react-router-dom';

const Copyright = (props) => {
	const navigate = useNavigate();

	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{/* {"Copyright "} */}
			<Link component="button" color="inherit" target="_blank" onClick={() => navigate('/Terms')}>
				Terms of use
			</Link>{' '}
			<CopyrightIcon fontSize="xsmall" />{' '}
			<Link color="inherit" href="https://predictivehealthcare.ai" target="_blank">
				Predictive Healthcare {new Date().getFullYear()}
			</Link>{' '}
			{}
		</Typography>
	);
};

export default Copyright;
