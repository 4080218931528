import React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { IOSSwitch } from '../../components/IosSwitchButton';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const prevMedicationColumns = [
	{ field: 'ma_id', headerName: 'ID', width: 40 },
	{
		field: 'medication_name',
		headerName: 'Medication Name',
		flex: 1,
		minWidth: 100
	},
	{ field: 'medication_dose', headerName: 'Dose', width: 130 },
	{
		field: 'ma_frequency',
		headerName: 'Daily Intake Frequency',
		type: 'number',
		flex: 1,
		minWidth: 50
	},
	{
		field: 'activation',
		headerName: 'Status',
		flex: 1,
		minWidth: 100,
		renderCell: (params) => <SwitchButton params={params} />
	},
	{
		field: 'ma_start_date',
		headerName: 'Start Date',
		type: 'date',
		flex: 1,
		minWidth: 100,
		valueGetter: (params) => {
			return params?.value?.split('T')[0];
		}
	},
	{
		field: 'ma_end_date',
		headerName: 'End Date',
		type: 'date',
		flex: 1,
		minWidth: 100,
		valueGetter: (params) => {
			return params?.value?.split('T')[0];
		}
	}
	// {
	// 	field: 'actions',
	// 	headerName: 'Actions',

	// 	type: 'actions',
	// 	minWidth: 120,
	// 	getActions: (params) => [
	// 		<GridActionsCellItem
	// 			icon={<DeleteIcon />}
	// 			label="Delete"
	// 			onClick={(params) => {
	// 				_.remove
	// 			}}
	// 		/>
	// 	]
	// }
	// {
	//   field: 'days',
	//   headerName: 'Days',
	//   width: 90,
	//   type: 'number'
	// },
	// {
	//   field: 'actions',
	//   headerName: 'Action',
	//   width: 130
	// }
	// {
	//   field: 'fullName',
	//   headerName: 'Full name',
	//   description:
	//     'This column has a value getter and is not sortable.',
	//   sortable: false,
	//   width: 160,
	//   valueGetter: (params) =>
	//     `${params.row.firstName || ''} ${
	//       params.row.lastName || ''
	//     }`
	// }
];

const newMedicationColumns = [
	{ field: 'ma_id', headerName: 'ID', width: 40 },
	{
		field: 'medication_name',
		headerName: 'Medication Name',
		flex: 1,
		minWidth: 100
	},
	{ field: 'medication_dose', headerName: 'Dose', width: 130 },
	{
		field: 'ma_frequency',
		headerName: 'Daily Intake Frequency',
		type: 'number',
		flex: 1,
		minWidth: 50
	},
	{
		field: 'ma_start_date',
		headerName: 'Start Date',
		type: 'date',
		flex: 1,
		minWidth: 100,
		valueGetter: (params) => {
			return params?.value?.split('T')[0];
		}
	},
	{
		field: 'ma_end_date',
		headerName: 'End Date',
		type: 'date',
		flex: 1,
		minWidth: 100,
		valueGetter: (params) => {
			return params?.value?.split('T')[0];
		}
	},
	{
		field: 'actions',
		headerName: 'Actions',

		type: 'actions',
		minWidth: 120,
		getActions: (params) => [
			<GridActionsCellItem
				icon={<ModeEditOutlineOutlinedIcon />}
				label="Edit"
				// onClick={editUser(params)}
			/>,
			<GridActionsCellItem
				icon={<DeleteIcon />}
				label="Delete"
				// onClick={deleteUser(params.id)}
			/>
		]
	}
];

const frequencies = [
	{
		value: '1',
		label: 'Enabled'
	},
	{
		value: '0',
		label: 'Disabled'
	}
];
const temperatureUnits = [
	{
		value: 'Celsius',
		label: '°C'
	},
	{
		value: 'Fahrenheit',
		label: '°F'
	}
];

const dailyIntakeFrequency = [
	{
		value: 'DAILY',
		label: 'DAILY'
	},
	{
		value: 'qAM',
		label: 'qAM'
	},
	{
		value: 'BID',
		label: 'BID'
	},
	{
		value: 'TID',
		label: 'TID'
	},
	{
		value: 'QID',
		label: 'QID'
	},
	{
		value: 'Before Meals',
		label: 'Before Meals'
	},
	{
		value: 'After Meals',
		label: 'After Meals'
	},
	{
		value: 'q1h',
		label: 'q1h'
	},
	{
		value: 'q2h',
		label: 'q2h'
	},
	{
		value: 'q3h',
		label: 'q3h'
	},
	{
		value: 'q4h',
		label: 'q4h'
	},
	{
		value: 'q6h',
		label: 'q6h'
	},
	{
		value: 'q8h',
		label: 'q8h'
	},
	{
		value: 'q12h',
		label: 'q12h'
	},
	{
		value: 'HS',
		label: 'HS'
	}
];

const SwitchButton = ({ params }) => {
	const [value, setValue] = React.useState(0);

	return (
		<IOSSwitch
			checked={params.row.ma_is_active ? true : false}
			onChange={(event) => {
				console.log('event.target.checked', event.target.checked);
				params.row.ma_is_active = event.target.checked ? 1 : 0;
			}}
			inputProps={{ 'aria-label': 'ant design' }}
		/>
	);
};

export {
	prevMedicationColumns,
	newMedicationColumns,
	frequencies,
	temperatureUnits,
	dailyIntakeFrequency
};
