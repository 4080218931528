import React, { useRef } from 'react';
import ListGrid from '../../components/customDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import { create } from './../../services/services';
import CustomizedAlertBar from './../../components/customAlertBar';
import { useLanguage } from '../../context/LanguageProvider';

const SurgeryTemplate = () => {
	const navigate = useNavigate();
	const { tokenData } = useAuth();
	const { getLangString } = useLanguage();

	const isAdmin = tokenData?.role === 'admin';
	const alertRef = useRef(null);

	const editSurgeryTemplate = (params) => () => {
		navigate('/Template', { state: { params: params.row } });
	};

	const deleteSurgeryTemplate = (params) => async () => {
		try {
			const urlParams = { resource: 'procedure', action: 'DeleteProcedureTemplate' };
			const response = await create(urlParams, {
				id: params.row.id
			});
			if (response.status === 200 || response.status === 201)
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
			// resetState();
			window.location.reload();
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	// const deleteUser = React.useCallback(id) => () => {
	// 		// setTimeout(() => {
	// 		// 	setRows((prevRows) => prevRows.filter((row) => row.id !== id));
	// 		// });
	// 	},
	// 	[]
	// 	();

	const columns = [
		{ field: 'id', headerName: getLangString('id'), flex: 1, minWidth: 40 },
		{ field: 'template_name', headerName: getLangString('template_name'), flex: 1, minWidth: 40 },
		{
			field: 'actions',
			headerName:  getLangString('action'),
			type: 'actions',
			flex: 1,
			minWidth: 150,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<ModeEditOutlineOutlinedIcon />}
					label="Edit"
					onClick={editSurgeryTemplate(params)}
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label="Delete"
					onClick={deleteSurgeryTemplate(params)}
				/>
			]
		}
	];
	const rowID = (row) => row.id;
	const urlParams = { resource: 'procedure', action: 'GetAllTemplates' };
	const createPage = {
		buttonText: `+ ${getLangString('add_new_template')}`,
		createPagePath: '/Template'
	};
	const columnVisibilityModel = {
		id: false
	};
	const page = 'templateList';
	return (
		<>
			<CustomizedAlertBar ref={alertRef} />
			<ListGrid
				rowID={rowID}
				columns={columns}
				urlParams={urlParams}
				createPage={createPage}
				pageName={page}
				columnVisibilityModel={columnVisibilityModel}
			/>
		</>
	);
};

export default SurgeryTemplate;
