export const getTodayDate = () => {
	var today = new Date();
	var dd = String(today.getDate()).padStart(2, '0');
	var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	var yyyy = today.getFullYear();

	return (today = yyyy + '-' + mm + '-' + dd);
};

export const getNowDateString = () => {
	const now = new Date().toISOString();
	const blocks = now.split(':');
	return [blocks[0], blocks[1]].join(':');
};

export const isPasswordValid = (password) => {
	// Minimum 8 characters long - the more, the better
	// At least one lowercase character
	// At least one number, symbol, or whitespace character
	const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|\W|\s).{8,}$/.test(password);
	return /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(password);
};
