import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { TextField, Box, FormControl } from '@mui/material';

const SurgeryTemplateDialog = forwardRef((props, ref) => {
	const navigate = useNavigate();

	const { submit, template_name, set_template_name } = props;

	const [open, setOpen] = useState(false);
	const [patient_info, set_patient_info] = useState({});
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	useImperativeHandle(ref, () => ({
		handleClickOpen(params) {
			setOpen(true);
			// set_patient_info(params);
		}
	}));

	const handleClose = (answer, reason) => {
		if (reason && reason === 'backdropClick') return;
		// if (answer === 'yes') {
		// 	navigate('/NewPatientSurgery', { state: { params: { ...patient_info } } });
		// 	setOpen(false);
		// }
		setOpen(false);
	};

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				fullWidth
				// maxWidth={'sm'}
			>
				<DialogTitle id="responsive-dialog-title">{'Save New Template '}</DialogTitle>
				<DialogContent>
					<FormControl fullWidth>
						<TextField
							component="span"
							required
							id="templateName"
							name="templateName2"
							label="Template Name"
							variant="outlined"
							fullWidth
							value={template_name ?? ''}
							onChange={(e) => set_template_name(e.target.value)}
							sx={{ m: 1 }}
						/>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Box textAlign="left" m={1}>
						<Button
							variant="outlined"
							color="success"
							type="button"
							onClick={() => {
								handleClose();
							}}>
							Cancel
						</Button>
					</Box>
					<Box textAlign="left" m={1}>
						<Button
							variant="contained"
							color="success"
							sx={{ width: 100 }}
							onClick={() => {
								if (template_name === '') return;
								submit();
								handleClose();
							}}>
							Save
						</Button>
					</Box>
					{/* <Button
						autoFocus
						onClick={() => {
							handleClose('no');
						}}>
						NO
					</Button>
					<Button
						onClick={() => {
							handleClose('yes');
						}}
						autoFocus>
						YES
					</Button> */}
				</DialogActions>
			</Dialog>
		</div>
	);
});

export default SurgeryTemplateDialog;
