import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import DoctorCard from './doctorCard';
import { useNavigate } from 'react-router-dom';
import PatientCard from './patientCard';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 2 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`
	};
}

const UsersTab = (params) => {
	const navigate = useNavigate();

	const { users, user_type, clinic_id } = params;
	const [value, setValue] = useState(0);
	const [selected_user, set_selected_user] = useState({});

	const handleChange = (event, newValue) => {
		setValue(newValue);
		set_selected_user(users[newValue].resource);
	};

	useEffect(() => {
		if (users.length > 0) {
			setValue(0);
			set_selected_user(users[0].resource);
		}
	}, []);

	return user_type === 'Doctor' ? (
		<>
			<Box
				sx={{
					justifyContent: 'center',
					bgcolor: 'background.paper',
					display: 'flex',
					height: 300
				}}>
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					onChange={handleChange}
					aria-label="Vertical tabs example"
					sx={{ borderRight: 1, borderColor: 'divider' }}>
					{users.map((element, index) => {
						return <Tab label={'Doctor ' + (parseInt(index) + 1)} {...a11yProps(index)} />;
					})}
				</Tabs>
				{users.map((element, index) => {
					return (
						<TabPanel value={value} index={index}>
							<DoctorCard doctor_details={element.resource} />
						</TabPanel>
					);
				})}
			</Box>
			<Box textAlign="center" sx={{ mt: 3 }}>
				<Button
					variant="contained"
					color="success"
					type="submit"
					onClick={() => {
						navigate('/NewProvider', {
							state: {
								params: { ...selected_user, clinic_id }
							}
						});
					}}>
					Import Doctor to MyHealthPal
				</Button>
			</Box>
		</>
	) : (
		<>
			<Box
				sx={{
					bgcolor: 'background.paper',
					display: 'flex',
					height: 300,
					justifyContent: 'center'
				}}>
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					onChange={handleChange}
					aria-label="Vertical tabs example"
					sx={{ borderRight: 1, borderColor: 'divider' }}>
					{users.map((element, index) => {
						return <Tab label={'Patient ' + (parseInt(index) + 1)} {...a11yProps(index)} />;
					})}
				</Tabs>
				{users.map((element, index) => {
					return (
						<TabPanel value={value} index={index} sx={{ width: 1 / 2 }}>
							<PatientCard patient_details={element.resource} />
						</TabPanel>
					);
				})}
			</Box>
			<Box textAlign="center" sx={{ mt: 3 }}>
				<Button
					variant="contained"
					color="success"
					type="submit"
					onClick={() => {
						navigate('/NewPatient', {
							state: {
								params: { ...selected_user, clinic_id }
							}
						});
					}}>
					Import Patient to MyHealthPal
				</Button>
			</Box>
		</>
	);
};

export default UsersTab;
