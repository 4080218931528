import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const ProcedureCard = (params) => {
	const { procedure_details } = params;
	console.log('procedure_details', procedure_details);
	return (
		<Box sx={{ minWidth: 275 }}>
			<Card
				variant="outlined"
				sx={{
					borderColor: '#1db14c',
					borderRadius: 2,
					boxShadow: 2
				}}>
				<CardContent>
					<Typography variant="body1" component="div" color="#1db14c">
						<b>Procedure Id</b>: {procedure_details.id}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Status</b> : {procedure_details?.status}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Subject</b> : {procedure_details?.subject?.display} -{' '}
						{procedure_details?.subject?.reference.split('DSTU2/')[1]}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Asserter</b>:{' '}
						{procedure_details?.asserter ? procedure_details?.asserter?.display : ''} -{' '}
						{procedure_details?.asserter ? procedure_details?.asserter?.reference : ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Category</b>: {procedure_details?.category ? procedure_details?.category?.text : ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Encounter</b>:{' '}
						{procedure_details?.encounter ? procedure_details?.encounter?.display : ''} -{' '}
						{procedure_details?.encounter ? procedure_details?.encounter.reference : ''}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Performed Date</b> : {procedure_details?.performedDateTime?.split('T')[0]}
					</Typography>
					<Typography variant="body1" component="div">
						<b>Recorder</b> :{' '}
						{procedure_details?.recorder ? procedure_details?.recorder?.display : ''} -{' '}
						{procedure_details?.recorder ? procedure_details?.recorder?.reference : ''}
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
};

export default ProcedureCard;
