import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Container,
	FormControl,
	IconButton,
	InputLabel,
	List,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLanguage } from '../../context/LanguageProvider';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect } from 'react';
import { COLORS } from '../../context/colors';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react';
import { get, post } from '../../services/services';
import { useAuth } from '../../context/AuthProvider';

const drawerWidth = 410;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		}),
		marginRight: drawerWidth
	})
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 0),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end'
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

const names = [
	'Oliver Hansen',
	'Van Henry',
	'April Tucker',
	'Ralph Hubbard',
	'Omar Alexander',
	'Carlos Abbott',
	'Miriam Wagner',
	'Bradley Wilkerson',
	'Virginia Andrews',
	'Kelly Snyder'
];

const Prediction = () => {
	const theme = useTheme();

	const { tokenData } = useAuth();
	const isAdmin = tokenData?.role === 'admin';

	const { getLangString } = useLanguage();
	const [open, setOpen] = useState(false);
	// const [all_surgeries, set_all_surgeries] = useState([]);

	const [loading, set_loading] = useState(true);

	const [clinics, set_clinics] = useState([]);
	const [doctors, set_doctors] = useState([]);
	const [surgeries, set_surgeries] = useState([]);

	const [clinic_id, set_clinic_id] = useState(isAdmin ? '1' : tokenData?.clinic_id);
	const [doctor_id, set_doctor_id] = useState('');
	const [surgery_id, set_surgery_id] = useState('');

	const [score, setScore] = useState('');

	const [prediction, set_prediction] = useState({});

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleClearDoctorClick = () => {
		set_doctor_id('');
		set_surgery_id('');
	};

	const handleClearSurgeryClick = () => {
		set_surgery_id('');
	};

	const handleApplyFilter = async () => {
		const urlParams = isAdmin
			? { resource: 'admin', action: 'getPredictionsReport' }
			: { resource: 'reporting', action: 'getPredictionsReport' };
		console.log('filters', {
			clinicId: clinic_id,
			...(doctor_id ? { assignedDoctor: doctor_id } : {}),
			...(surgery_id ? { procedureId: surgery_id } : {})
			// ...(doctor_id && surgery_id ? { procedureId: surgery_id } : {})
		});
		let data = await get(urlParams, {
			clinicId: clinic_id,
			...(doctor_id ? { assignedDoctor: doctor_id } : {}),
			...(surgery_id ? { procedureId: surgery_id } : {})
		});
		console.log('analytics', data.data.analytics);
		set_prediction(data.data);
		setOpen(false);
	};

	useEffect(() => {
		// on mount get data
		const fetchData = async () => {
			const urlParams = { resource: 'reporting', action: 'getPredictionsReport' };
			let data = await get(urlParams, {
				clinicId: clinic_id
			});
			set_prediction(data.data);

			const urlParams2 = { resource: 'clinic', action: 'getclinics' };
			let data2 = await post(urlParams2);
			set_clinics(data2.data);

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	useEffect(() => {
		// get procedures based on doctor id or clinic id
		const fetchProceduresByDoctor = async () => {
			const urlParams = { resource: 'procedure', action: 'getProceduresByDoctor' };
			let data = await get(urlParams, { doctor_id });
			set_surgeries(data.data);
		};

		const fetchProceduresByClinic = async () => {
			const urlParams = { resource: 'procedure', action: 'getProceduresByClinic' };
			let data = await get(urlParams, {
				clinic_id
			});
			set_surgeries(data.data);
		};

		if (doctor_id) fetchProceduresByDoctor().catch(console.error);
		else fetchProceduresByClinic().catch(console.error);
	}, [doctor_id]);

	useEffect(() => {
		// get doctors and procedures based on clinic id
		const fetchData = async () => {
			const urlParams = { resource: 'user', action: 'getDoctorsByClinic' };
			let data = await post(urlParams, { clinic_id, profile_pic: false });
			set_doctors(data.data);

			const urlParams2 = { resource: 'procedure', action: 'getProceduresByClinic' };
			let data2 = await get(urlParams2, {
				clinic_id
			});
			set_surgeries(data2.data);
		};
		fetchData().catch(console.error);
	}, [clinic_id]);

	return (
		<>
			<Container
				component="main"
				maxWidth="xl"
				sx={{
					mt: 2,
					height: '86.6vh',
					display: 'flex',
					flexDirection: 'column',
					position: 'relative'
				}}>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth
						}
					}}
					variant="persistent"
					anchor="right"
					open={open}>
					<DrawerHeader
						sx={{
							mt: 8
						}}>
						<Button
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '43px',
								width: '62px',
								padding: '0 10px',
								backgroundColor: COLORS.Green_100
							}}
							onClick={handleDrawerClose}>
							<CloseOutlinedIcon style={{ color: 'white' }} />
						</Button>
					</DrawerHeader>
					<Box
						sx={{
							m: 2,
							mt: 5,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignContent: 'center',
							gap: 5
						}}>
						{loading ? (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									height: '100%',
									width: '100%'
								}}>
								<CircularProgress />
							</Box>
						) : (
							<>
								{isAdmin && (
									<TextField
										select
										id="clinic"
										name="clinic"
										label={getLangString('clinic')}
										fullWidth
										variant="outlined"
										value={clinic_id}
										onChange={(e) => {
											set_clinic_id(e.target.value);
											set_doctor_id('');
										}}>
										{clinics.map((clinic) => (
											<MenuItem key={clinic.clinic_id} value={clinic.clinic_id}>
												<ListItemText primary={clinic.clinic_name} />
											</MenuItem>
										))}
									</TextField>
								)}
								<FormControl>
									<InputLabel id="demo-multiple-name-label">{getLangString('doctor')}</InputLabel>
									<Select
										id="doctor"
										name="doctor"
										label={getLangString('doctor')}
										fullWidth
										variant="outlined"
										value={doctor_id}
										onChange={(e) => {
											set_doctor_id(e.target.value);
											set_surgery_id('');
										}}
										endAdornment={
											doctor_id ? (
												<IconButton
													sx={{ display: doctor_id ? '' : 'none', mr: 3 }}
													onClick={handleClearDoctorClick}>
													<ClearIcon />
												</IconButton>
											) : null
										}>
										{doctors.length === 0 ? (
											<MenuItem disabled>{getLangString('no_options_available')}</MenuItem>
										) : (
											doctors.map((doctor) => (
												<MenuItem key={doctor.doctor_id} value={doctor.doctor_id}>
													<ListItemText primary={doctor.doctor_fullName} />
												</MenuItem>
											))
										)}
									</Select>
								</FormControl>
								{/* <Autocomplete
									id="doctor"
									name="doctor"
									label={getLangString('doctor')}
									fullWidth
									variant="outlined"
									options={doctors}
									getOptionLabel={(option) => {
										console.log('option', option);
										return option.doctor_fullName;
									}}
									value={doctor_id}
									renderInput={(params) => (
										<TextField fullWidth {...params} label={getLangString('doctor')} />
									)}
									isOptionEqualToValue={(option, value) => {
										return option?.doctor_id === value?.id;
									}}
									onChange={(event, newValue, reason) => set_doctor_id(newValue)}
									noOptionsText={
										<div>
											<MenuItem disabled>
												<Typography sx={{ fontSize: '16px', color: 'black' }}>
													No matches
												</Typography>
											</MenuItem>
										</div>
									}
								/> */}
								<FormControl>
									<InputLabel id="demo-multiple-name-label">{getLangString('surgery')}</InputLabel>
									<Select
										id="surgery"
										name="surgery"
										label={getLangString('surgery')}
										fullWidth
										variant="outlined"
										value={surgery_id}
										onChange={(e) => set_surgery_id(e.target.value)}
										endAdornment={
											surgery_id ? (
												<IconButton
													sx={{ display: surgery_id ? '' : 'none', mr: 3 }}
													onClick={handleClearSurgeryClick}>
													<ClearIcon />
												</IconButton>
											) : null
										}>
										{surgeries.length === 0 ? (
											<MenuItem disabled>{getLangString('no_options_available')}</MenuItem>
										) : (
											surgeries.map((surgery) => (
												<MenuItem key={surgery.procedure_id} value={surgery.procedure_id}>
													<ListItemText
														primary={
															surgery.procedure_id +
															' - ' +
															surgery.procedure_type +
															' - ' +
															surgery.patient_full_name
														}
													/>
												</MenuItem>
											))
										)}
									</Select>
								</FormControl>
								{/* <TextField
							select
							id="surgery"
							name="surgery"
							label={getLangString('surgery')}
							fullWidth
							variant="outlined"
							value={surgery}
							onChange={(e) => {
								const {
									target: { value }
								} = e;
								console.log('value', value)
								set_surgery(
									// On autofill we get a stringified value.
									typeof value === 'string' ? value.split(',') : value
								);
							}}
							// input={<OutlinedInput label="Tag" />}
							// renderValue={(selected)=> selected.join(', ')}
							MenuProps={MenuProps}>
							{names.map((name) => (
								<MenuItem key={name} value={name}>
									<Checkbox checked={surgery.indexOf(name) > -1} />
									<ListItemText primary={name} />
								</MenuItem>
							))}
						</TextField> */}
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignContent: 'center'
									}}>
									<Button
										onClick={handleDrawerClose}
										sx={{
											width: '54px',
											height: '45px',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 600,
											fontSize: '16px',
											lineHeight: '19px',
											color: '#9BB5CE',
											borderRadius: '4px',
											mr: '28px',
											mb: '50px'
										}}>
										{getLangString('cancel')}
									</Button>
									<Button
										sx={{
											// position: 'absolute',
											width: '142px',
											height: '40.37px',
											background: COLORS.RaisinPurple,
											borderRadius: '4px',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 600,
											fontSize: '16px',
											lineHeight: '19px'
										}}
										onClick={handleApplyFilter}
										variant="contained">
										{getLangString('apply')}
									</Button>
								</Box>
							</>
						)}
					</Box>
				</Drawer>
				<Paper
					sx={{
						p: 4,
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						overflow: 'hidden'
					}}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							sx={{
								fontFamily: 'Inter',
								fontStyle: 'normal',
								fontWeight: 600,
								fontSize: '26px',
								lineHeight: '31px',
								flexGrow: 1
							}}>
							{getLangString('prediction')}
						</Typography>

						<Button
							variant="contained"
							color="success"
							type="submit"
							sx={{ width: '109px', height: '43px', backgroundColor: COLORS.Green_100 }}
							onClick={handleDrawerOpen}>
							{getLangString('filter')}
						</Button>
					</Box>
					<Box
						sx={{ display: 'flex', flexDirection: 'row', margin: 'auto', ml: open ? 30 : 'auto' }}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'flex-start',
								justifyContent: 'center',
								position: 'relative',
								flexDirection: 'column'
							}}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: '428px'
								}}>
								<Typography
									component="h1"
									variant="h6"
									color="inherit"
									noWrap
									sx={{
										alignItems: 'center',
										justifyContent: 'center',
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontWeight: 400,
										fontSize: '23.2968px',
										mb: 3,
										lineHeight: '28px'
									}}>
									{getLangString('doctors_feedback')}
								</Typography>
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'space-around',
									flexDirection: 'row',
									justifyContent: 'space-around',
									width: '428px'
								}}>
								<Typography
									component="h1"
									variant="h6"
									color="inherit"
									noWrap
									sx={{
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontWeight: 400,
										fontSize: '18.7877px',
										lineHeight: '23px',
										justifyContent: 'center',
										color: COLORS.Emerald
									}}>

									{getLangString('agree')}
								</Typography>
								<Typography
									component="h1"
									variant="h6"
									color="inherit"
									noWrap
									sx={{
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontWeight: 400,
										fontSize: '18.7877px',
										lineHeight: '23px',
										justifyContent: 'center',
										color: COLORS.EarthYellow
									}}>
									{getLangString('disagree')}
								</Typography>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row'
								}}>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: 428,
										height: 428,
										position: 'relative',
										margin: 'auto',
										// flex: '0 0 auto',
										flexDirection: 'column'
									}}>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											width: 214,
											height: 214,
											backgroundColor: COLORS.Green_200,
											opacity: 0.3,
											position: 'absolute',
											top: 0,
											left: 0
										}}>
										<Typography
											variant="h6"
											sx={{
												color: 'white',
												textAlign: 'center'
											}}>
											{/* Box 1 */}
											{prediction?.analytics?.truePositivePercent}
										</Typography>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
											width: 214,
											height: 214,
											backgroundColor: COLORS.Firebrick,
											opacity: 0.3,
											position: 'absolute',
											top: 0,
											right: 0
										}}>
										<Typography
											variant="h6"
											sx={{
												color: 'white',
												textAlign: 'center'
											}}>
											{/* Box 2 */}
											{prediction?.analytics?.falsePositivePercent}
										</Typography>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
											width: 214,
											height: 214,
											backgroundColor: COLORS.Firebrick,
											opacity: 0.3,
											position: 'absolute',
											bottom: 0,
											left: 0
										}}>
										<Typography
											variant="h6"
											sx={{
												color: 'white',
												textAlign: 'center'
											}}>
											{/* Box 3 */}
											{prediction?.analytics?.trueNegativePercent}
										</Typography>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
											width: 214,
											height: 214,
											backgroundColor: COLORS.Green_200,
											opacity: 0.3,
											position: 'absolute',
											bottom: 0,
											right: 0
										}}>
										<Typography
											variant="h6"
											sx={{
												color: 'white',
												textAlign: 'center'
											}}>
											{/* Box 4 */}
											{prediction?.analytics?.falseNegativePercent}
										</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'space-around',
										flexDirection: 'column',
										justifyContent: 'space-around',
										height: '428px'
									}}>
									<Typography
										component="h1"
										variant="h6"
										color="inherit"
										noWrap
										sx={{
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 400,
											fontSize: '18.7877px',
											lineHeight: '23px',
											justifyContent: 'center',
											color: COLORS.Emerald,
											writingMode: 'vertical-rl',
											textOrientation: 'mixed'
										}}>
										{getLangString('standard_risk')}
									</Typography>
									<Typography
										component="h1"
										variant="h6"
										color="inherit"
										noWrap
										sx={{
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 400,
											fontSize: '18.7877px',
											lineHeight: '23px',
											justifyContent: 'center',
											color: COLORS.EarthYellow,
											writingMode: 'vertical-rl',
											textOrientation: 'mixed'
										}}>
										{getLangString('elevated_risk')}
									</Typography>
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}>
									<Typography
										component="h1"
										variant="h6"
										color="inherit"
										noWrap
										sx={{
											alignItems: 'center',
											justifyContent: 'center',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 400,
											fontSize: '23.2968px',
											lineHeight: '28px',
											ml: 3,
											writingMode: 'vertical-rl',
											textOrientation: 'mixed'
										}}>
										{getLangString('phc_predictions')}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default Prediction;
