import { Backdrop, CircularProgress } from '@mui/material';
import React, { useState, createContext, useEffect } from 'react';
import { checkToken, getProfile } from '../helpers/API';
import LocalStorageService from '../helpers/LocalStorageService';
import SignIn from '../components/SignIn';
import { API_ENDPOINT } from '../helpers/constants';
import API from '../helpers/API';

const AuthContext = createContext({
	profile: {},
	tokenData: {},
	tokens: {},
	login: async () => {},
	logout: () => {}
});

export const AuthProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);

	const [profile, setProfile] = React.useState(null);
	const [tokenData, setTokenData] = React.useState(null);
	const [tokens, setTokens] = React.useState(null);

	console.log({ profile, tokens, tokenData });

	// entry point, on page load
	useEffect(() => {
		//check local storage for data
		const storage_tokens = {
			access_token: LocalStorageService.getAccessToken(),
			refresh_token: LocalStorageService.getRefreshToken()
		};
		const email = LocalStorageService.getEmail();

		//if any missing data, clear all
		if (!storage_tokens.access_token || !storage_tokens.refresh_token || !email) {
			LocalStorageService.clearAll();
			setProfile(null);
			setTokenData(null);
			setTokens(null);
			setIsLoading(false);

			return;
		}

		//get new tokens
		checkToken()
			.then((tokenRes) => {
				setTokenData(tokenRes);

				if (tokenRes?.role === 'admin') return;

				return getProfile();
			})
			.then((profileData) => {
				setProfile(profileData ?? {});
				localStorage.setItem(`profile`, JSON.stringify(profileData.profile));
				setTokens(storage_tokens);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				LocalStorageService.clearAll();
				setProfile(null);
				setTokenData(null);
				setTokens(null);
				setIsLoading(false);
			});
	}, []);

	const callLoginApi = async (data) => {
		return new Promise((resolve, reject) => {
			try {
				// var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
				const { email, password, newPassword } = data;
				let path;
				// if (username.match(validRegex)) {
				// 	path = `${API_ENDPOINT}/api/user/login`;
				// 	data = { email: username, password };
				// } else {
				// 	path = `${API_ENDPOINT}/api/user/loginPhone`;
				// 	data = { phoneNumber: username, password };
				// }
				path = `${API_ENDPOINT}/api/user/login`;
				data = { email, password, newPassword };
				API.post(path, data)
					.then((result) => {
						if (result.data.data.role_name === 'patient') {
							return reject({
								fail: { code: 'unauthorized', message: 'Unauthorized' }
							});
						}
						resolve(result.data.data);
					})
					.catch((err) => {
						if (err.response?.data?.fail?.code === 'newPasswordRequired') {
							reject('newPasswordRequired');
						}
						if (err.response?.data) {
							reject(err.response?.data);
						} else {
							reject('something wrong happened' + err);
						}
					});
			} catch (err) {
				if (err.response?.data) reject(err.response?.data);
				else reject('something wrong happened' + err);
			}
		});
	};

	const login = async ({ email, password, newPassword = null }) => {
		return new Promise(async (resolve, reject) => {
			try {
				console.log('email, password', email, password);
				const loginData = await callLoginApi({
					email,
					password,
					newPassword
				});
				localStorage.setItem(`profile`, JSON.stringify(loginData.profile));
				setProfile(loginData.profile);
				LocalStorageService.setToken({
					access_token: loginData.accessToken,
					refresh_token: loginData.refreshToken
				});
				setTokens({ access_token: loginData.accessToken, refresh_token: loginData.refreshToken });
				setTokenData({
					...(loginData.role_name !== 'admin' && { clinic_id: loginData?.profile?.clinic_id }),
					email,
					id: loginData.userId,
					role: loginData.role_name
				});

				LocalStorageService.setEmail(email);
			} catch (e) {
				reject(e === 'newPasswordRequired' ? e : 'Invalid login');
			}
		});
	};

	const logout = () => {
		LocalStorageService.clearAll();
		setProfile(null);
		setTokenData(null);
		setTokens(null);
		setIsLoading(false);
	};

	if (isLoading)
		return (
			<Backdrop sx={{ color: '#fff' }} open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
		);

	if (!tokenData)
		return (
			<AuthContext.Provider value={{ profile, tokenData, tokens, login, logout }}>
				<SignIn />
			</AuthContext.Provider>
		);

	return (
		<AuthContext.Provider value={{ profile, tokenData, tokens, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => React.useContext(AuthContext);
