import { Avatar, Divider, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { IconButton, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import EditIcon from '@mui/icons-material/Edit';

const ExpandingImg = ({ image_thumb, image_url, width, height, onChange, result }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [zoomLevel, setZoomLevel] = useState(1);

	const handleClose = () => {
		setIsExpanded(false);
		setZoomLevel(1);
	};
	const handleOpen = () => {
		setIsExpanded(true);
	};

	const [imageDims, setImageDims] = useState([0, 0]);

	// zoom handle
	const toggleZoom = (val) => {
		let newZoom = zoomLevel + val;
		newZoom = newZoom > 5 ? 5 : newZoom;
		newZoom = newZoom < 1 ? 1 : newZoom;
		setZoomLevel(newZoom);
		setMouseMove([50, 50]);
	};

	const boxStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		padding: '40px 8px 8px 8px',

		width: '90vw',
		height: '90vh',

		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	};
	const checkImageSize = (event) => {
		const image = event.target;
		const imgHeight = image.naturalHeight;
		const imgWidth = image.naturalWidth;

		setImageDims([imgWidth, imgHeight]);
	};

	const boxRef = useRef(null);
	const [mouseMove, setMouseMove] = useState([50, 50]);
	const handleMouseMove = (e) => {
		if (zoomLevel === 1) {
			return;
		}
		const x = e.nativeEvent.offsetX;
		const y = e.nativeEvent.offsetY;

		const { offsetWidth, offsetHeight } = boxRef.current;
		const px = Math.round(100 - (100 * x) / offsetWidth);
		const py = Math.round(100 - (100 * y) / offsetHeight);

		setMouseMove([px, py]);
	};

	return (
		<>
			<Box>
				<Button
					aria-label="image"
					onClick={handleOpen}
					sx={{
						padding: 0,
						margin: 0,
						height: imageDims[1] > 250 ? '150px' : 'auto',
						width: width ?? '100%'
					}}>
					<img
						src={image_thumb}
						onLoad={checkImageSize}
						style={{
							height: '100%',
							width: '100%',
							objectFit: 'cover',
							borderTop:
								result === 'complicated'
									? '6px solid red'
									: result === 'face_not_found'
									? '6px solid orange'
									: '6px solid green'
						}}
						alt="Surgery"
					/>
				</Button>
			</Box>
			{isExpanded && (
				<Modal open={true} onClose={handleClose}>
					<Box sx={boxStyle}>
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								position: 'absolute',
								right: 0,
								top: 0,
								color: (theme) => theme.palette.grey[500]
							}}>
							<CloseIcon />
						</IconButton>
						<Box
							ref={boxRef}
							sx={{
								flex: 1,
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								overflow: 'hidden',
								position: 'relative'
							}}
							onMouseMove={handleMouseMove}
							onMouseLeave={() => setMouseMove([50, 50])}>
							<img
								src={image_url}
								alt="wound"
								style={{
									height: `${zoomLevel * 100}%`,
									width: `${zoomLevel * 100}%`,
									objectFit: 'contain',
									position: 'absolute',

									left: `${(1 - zoomLevel) * 50 - (50 - mouseMove[0]) * (zoomLevel - 1)}%`,
									top: `${(1 - zoomLevel) * 50 - (50 - mouseMove[1]) * (zoomLevel - 1)}%`,

									transition: 'height width .2s',
									pointerEvents: 'none'
								}}
							/>
						</Box>

						<Box
							display="flex"
							justifyContent="center"
							alignItems={'center'}
							sx={{ maxHeight: '54px' }}>
							Hover to Zoom
							<IconButton
								aria-label="zoom-out"
								onClick={() => toggleZoom(-1)}
								sx={{
									color: (theme) => theme.palette.grey[500]
								}}>
								<ZoomOutIcon />
							</IconButton>
							{Math.round(zoomLevel * 10) / 10}X
							<IconButton
								aria-label="zoom-in"
								onClick={() => toggleZoom(1)}
								sx={{
									color: (theme) => theme.palette.grey[500]
								}}>
								<ZoomInIcon />
							</IconButton>
							{onChange && (
								<>
									<input
										accept="image/*"
										style={{ display: 'none' }}
										id="raised-button-file"
										multiple
										type="file"
										onChange={onChange}
									/>
									<label htmlFor="raised-button-file">
										<Button
											variant="raised"
											component="span"
											sx={{
												color: (theme) => theme.palette.grey[700]
											}}>
											<EditIcon /> Change
										</Button>
									</label>
								</>
							)}
						</Box>
					</Box>
				</Modal>
			)}
		</>
	);
};
export default ExpandingImg;
