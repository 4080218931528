import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getList, getListPost, post } from '../../services/services';
import {
	Paper,
	Box,
	Typography,
	Container,
	MenuItem,
	CircularProgress,
	Select,
	FormControl,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Button,
	styled
} from '@mui/material';
import ExpandingAvatar from '../../components/ExpandingAvatar';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandingImg from '../../components/ExpandingImg';
import axios from 'axios';
import CustomizedAlertBar from '../../components/customAlertBar';
import { COLORS } from '../../context/colors';
import ImageGallery from '../../components/dailogs/imageGallery';
import { PatientDiagnosticReport } from './PatientDiagnosticReport';
// import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import LogoIcon from '../../assets/logo_icon.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const days_name = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const PatientTableDayHeadCell = ({ date }) => {
	return (
		<TableCell
			sx={{
				background: date.toDateString() === new Date().toDateString() ? '#EEE1EB' : ''
			}}
			align="right">
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '48px',
					alignItems: 'center'
				}}>
				<Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
					{days_name[date.getDay()]}
				</Typography>
				<Typography sx={{ fontSize: '10px', fontWeight: '300' }}>
					{date.getMonth() + 1} - {date.getDate()} - {date.getFullYear()}
				</Typography>
			</Box>
		</TableCell>
	);
};

const pillsToEnglish = (count) => {
	const engCount = [
		'',
		'One',
		'Two',
		'Three',
		'Four',
		'Five',
		'Six',
		'Seven',
		'Eight',
		'Nine',
		'Ten'
	];
	return `${engCount[count] ?? count} Pill${count > 1 ? 's' : ''}`;
};

// rowTitle for Display Name in Table
// order for which order the row appear in table
// cell FN for using custom cell instead of DefaultCell Typography, does no override "no entry"
// isRed FN for DefaultCell red color
// hide boolean true is dont  calculate and show row in table

// data from kpi_list_data in backend
const KPIsListOfObjs = {
	image_url: {
		rowTitle: 'Wound History',
		order: 1,
		cell: (value) => (
			<ExpandingImg
				result={value.image_prediction_result}
				image_thumb={value.image_thumb}
				image_url={value.image_url ?? value.image_thumb}
			/>
		)
	},
	report: {
		rowTitle: 'Report',
		order: 1.5,
		cell: (value) => (
			<IconButton color="success" size="large" onClick={() => value.setShowDiagnosticReport(value)}>
				<PictureAsPdfOutlinedIcon sx={{ height: '32px', width: '32px' }} />
			</IconButton>
		)
	},
	blood_pressure: {
		rowTitle: 'Blood pressure',
		order: 1.6,
		isRed: (value) => {
			const [high, low] = value.split('/');
			if (!high || !low) return false;

			// hypertension (high)
			if (Number(high) > 130 || Number(low) > 80) return true;
			// hypotension (low)
			if (Number(high) < 90 || Number(low) < 60) return true;

			return false;
		}
	},
	pain: {
		rowTitle: 'Wound pain',
		order: 1.7,
		isRed: (value) => {
			return Number(value) > 5;
		}
	},
	medication: {
		rowTitle: 'Medication',
		order: 2,
		cell: (value) => <Typography>{pillsToEnglish(value.pl_log_value)}</Typography>,
		hide: true
	},

	odor: {
		rowTitle: 'Wound Odor',
		order: 3,
		isRed: (value) => Number(value) > 0
	},
	wound_temperature: {
		rowTitle: 'Wound Temperature',
		order: 4,
		isRed: (value) => Number(value) > 0
	},
	wound_swelling: {
		rowTitle: 'Wound Swelling',
		order: 5,
		isRed: (value) => Number(value) > 0
	},
	bleeding: {
		rowTitle: 'Bleeding',
		order: 6,
		isRed: (value) => Number(value) > 0
	},
	temperature: {
		rowTitle: 'Temperature',
		order: 7,
		isRed: (value) => {
			return Number(value) > 38 || Number(value) < 35;
		}
	},
	pulse: {
		rowTitle: 'Pulse',
		order: 8,
		isRed: (value) => {
			return Number(value) > 100 || Number(value) < 60;
		}
	},
	oxygen_saturation: {
		rowTitle: 'Oxygen Saturation',
		order: 9,
		isRed: (value) => Number(value) < 95
	},
	respiratory_rate: {
		rowTitle: 'Respiratory Rate',
		order: 10,
		isRed: (value) => {
			return Number(value) > 20 || Number(value) < 12;
		}
	},
	sleep_issues: {
		rowTitle: 'Sleep Issues',
		order: 12,
		isRed: (value) => Number(value) > 0
	},
	breathing_issues: {
		rowTitle: 'Breathing Issues',
		order: 13,
		isRed: (value) => Number(value) > 0
	},
	dizziness: {
		rowTitle: 'Dizziness',
		order: 14,
		isRed: (value) => Number(value) > 0
	},
	balance: {
		rowTitle: 'Balance',
		order: 15,
		isRed: (value) => Number(value) > 0
	},
	nausea: {
		rowTitle: 'Nausea',
		order: 16,
		isRed: (value) => Number(value) > 0
	},
	vomiting: {
		rowTitle: 'Vomiting',
		order: 17,
		isRed: (value) => Number(value) > 0
	},
	chills: {
		rowTitle: 'Chills',
		order: 18,
		isRed: (value) => Number(value) > 0
	},
	appetite: {
		rowTitle: 'Appetite',
		order: 19,
		isRed: (value) => Number(value) > 0
	},
	urination: {
		rowTitle: 'Urination',
		order: 20,
		isRed: (value) => Number(value) > 0
	},
	defecation: {
		rowTitle: 'Defecation',
		order: 21,
		isRed: (value) => Number(value) > 0
	},
	physical_activity: {
		rowTitle: 'Physical Activity',
		order: 22,
		isRed: (value) => Number(value) > 0
	},
	weight_changes: {
		rowTitle: 'Weight Changes',
		order: 23,
		isRed: (value) => Number(value) > 0
	},
	irritability: {
		rowTitle: 'Irritability',
		order: 24,
		isRed: (value) => Number(value) > 0
	},
	anxiety: {
		rowTitle: 'Anxiety',
		order: 25,
		isRed: (value) => Number(value) > 0
	},
	depression: {
		rowTitle: 'Depression',
		order: 26,
		isRed: (value) => Number(value) > 0
	},
	fatigue: {
		rowTitle: 'Fatigue',
		order: 27,
		isRed: (value) => Number(value) > 0
	},
	restlessness: {
		rowTitle: 'Restlessness',
		order: 28,
		isRed: (value) => Number(value) > 0
	},
	memory_problems: {
		rowTitle: 'Memory Problems',
		order: 29,
		isRed: (value) => Number(value) > 0
	},
	mental_fog: {
		rowTitle: 'Mental Fog',
		order: 30,
		isRed: (value) => Number(value) > 0
	}
};

const KPIsList = Object.keys(KPIsListOfObjs);

const ImageCompareButton = styled(Button)(({ theme }) => ({
	color: theme.palette.getContrastText(COLORS.RaisinPurple),
	backgroundColor: COLORS.RaisinPurple,
	'&:hover': {
		backgroundColor: COLORS.RaisinPurple
	}
}));

const DefaultCell = ({ kpi, value }) => {
	// no value display
	if (!value || value.pl_log_value === undefined)
		return <Typography variant="caption">-</Typography>;

	// custom cell display
	const hasKpiCell = KPIsListOfObjs[kpi]?.cell !== undefined;
	if (hasKpiCell) return KPIsListOfObjs[kpi]?.cell(value);

	// default cell display
	const isRed =
		value.pl_log_value !== undefined && KPIsListOfObjs[kpi]?.isRed !== undefined
			? KPIsListOfObjs[kpi].isRed(value.pl_log_value)
			: false;

	return (
		<Typography color={isRed ? 'error' : 'default'} fontWeight={isRed ? 'bold' : 'regular'}>
			{value.pl_log_value}
		</Typography>
	);
};

const PatientTableRow = ({ kpi, values, dates, setOpenGallery, setShowDiagnosticReport }) => {
	// [] of 7 log objs by day
	const displayValues = useMemo(() => {
		const array = [];
		dates.forEach((day) => {
			//get day value
			const dayValues = values.filter((item) => {
				const logDate = new Date(item.pl_update_ts);
				return logDate.toDateString() === day.toDateString();
			});
			const dayValue = dayValues.length > 0 ? dayValues[dayValues.length - 1] : {};
			dayValue.key = `${kpi}-${day}`;

			//special case for report values
			if (kpi === 'report') {
				return array.push({
					predictionId: dayValue?.image_prediction_id ?? '',
					thumb: dayValue?.image_thumb ?? '',
					url: dayValue?.image_url ?? '',
					key: `${kpi}-${day}`,
					imgKey: dayValue?.pl_log_value ?? '',
					result: dayValue?.image_prediction_result ?? '',
					day,
					pl_log_value: true,
					setShowDiagnosticReport
				});
			}

			array.push(dayValue);
		});
		return array;
	}, [dates, values, kpi, setShowDiagnosticReport]);

	return (
		<TableRow
			sx={{
				height: '155px',
				border: '1px solid rgba(224, 224, 224, 1)'
			}}>
			<TableCell
				component="th"
				scope="row"
				style={{ textAlign: 'center', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
				{/* ROW TITLE */}
				<Typography style={{ marginBottom: '10px' }}>{KPIsListOfObjs[kpi].rowTitle}</Typography>
				{kpi === 'image_url' && (
					<ImageCompareButton
						disableRipple={true}
						variant="contained"
						sx={{ color: 'white', backgroundColor: COLORS.RaisinPurple }}
						onClick={() => {
							setOpenGallery(true);
						}}>
						Compare
					</ImageCompareButton>
				)}
			</TableCell>
			{/* ROW 7-day VALUES */}
			{dates.map((day, index) => (
				<TableCell align="center" key={displayValues[index].key} sx={{ padding: '2px' }}>
					<DefaultCell kpi={kpi} value={displayValues[index]} />
				</TableCell>
			))}
		</TableRow>
	);
};

const MedicationRow = ({ assigned, logs, dates }) => {
	// [{ medName: ass.medication_name, values: array }]
	const displayValues = useMemo(() => {
		const allMeds = [];
		assigned.forEach((ass) => {
			// check is assigned in dates period
			if (
				Date.parse(dates[0]) > Date.parse(ass.ma_end_date) ||
				Date.parse(dates[6]) < Date.parse(ass.ma_start_date)
			) {
				return;
			}

			const array = [];
			dates.forEach((day) => {
				//get day value
				const dayValues = logs.filter((item) => {
					const logDate = new Date(item.ml_update_ts);
					return (
						logDate.toDateString() === day.toDateString() &&
						item.ml_medication_id === ass.medication_id
					);
				});
				const dayValue = {
					pl_log_value: dayValues.length > 0 ? dayValues.length : undefined,
					key: `med-${ass.medication_name}-${day}`
				};

				array.push(dayValue);
			});
			allMeds.push({ medName: ass.medication_name, values: array });
		});
		return allMeds;
	}, [assigned, dates, logs]);

	return (
		<TableRow
			sx={{
				minHeight: '155px',
				'&:last-child td, &:last-child th': { border: 0 },
				borderTop: '1px solid rgba(224, 224, 224, 1)'
			}}>
			<TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
				<Typography style={{ marginBottom: '10px' }}>Medication</Typography>
			</TableCell>

			{dates.map((day, index) => (
				<TableCell align="center" key={`med-day-${index}`} sx={{ padding: '2px' }}>
					<Box sx={{ width: '100%' }}>
						{displayValues.map((med, index2) => (
							<Box
								sx={{
									height: '70px',
									flex: 1,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'relative'
								}}>
								{index === 0 && (
									<Typography variant="body2" sx={{ position: 'absolute', top: 0, left: '-50%' }}>
										{med.medName}
									</Typography>
								)}
								<DefaultCell kpi={'medication'} value={med.values[index]} />
							</Box>
						))}
					</Box>
				</TableCell>
			))}
		</TableRow>
	);
};

const PatientTable = ({ activeProcedure, setOpenGallery, setShowDiagnosticReport }) => {
	const [weekStartDate, setWeekStartDate] = useState(new Date());
	const [isExpandedView, setIsExpandedView] = useState(false);

	// Record <kpiName,logObj>
	const allLogsByKpi = useMemo(() => {
		const list = {};
		KPIsList.forEach((kpi) => {
			if (kpi === 'medication') return;
			list[kpi] =
				activeProcedure?.loggingData?.loggingHistoryResult?.filter(
					(log) =>
						log.pl_log_type === kpi ||
						// special case copy image_url to report values
						(kpi === 'report' && log.pl_log_type === 'image_url')
				) ?? [];
		});
		return list;
	}, [activeProcedure]);

	//set inital Date values on mount
	useEffect(() => {
		//set last monday as week start
		const prevMonday = new Date();
		prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
		setWeekStartDate(prevMonday);
	}, []);

	//update weekDays list when week start date changes
	const weekDays = useMemo(() => {
		const newDays = [];
		for (let i = 0; i < 7; i++) {
			const date = new Date(weekStartDate);
			date.setDate(date.getDate() + i);
			newDays.push(date);
		}
		return newDays;
	}, [weekStartDate]);

	//set last monday as week start
	const shiftDate = (dir) => {
		var monday = new Date(weekStartDate);
		monday.setDate(monday.getDate() + 7 * dir);
		setWeekStartDate(monday);
	};

	const allRows = useMemo(() => {
		const rows = [];

		KPIsList.forEach((kpi) => {
			if (KPIsListOfObjs[kpi].hide) return;

			// make sure KPI is assigned
			if (
				kpi !== 'medication' &&
				kpi !== 'report' &&
				allLogsByKpi[kpi]?.length === 0 &&
				!activeProcedure?.assignedKPIs?.monitoring?.find((m) => m.pm_metric_name === kpi)
			) {
				return;
			}

			// gererate row
			rows.push({
				kpi: kpi,
				order: KPIsListOfObjs[kpi].order,
				row:
					kpi === 'medication' ? (
						<MedicationRow
							key="medication"
							assigned={activeProcedure?.loggingData?.medicationHistoryResult ?? []}
							logs={activeProcedure?.loggingData?.medicationLoggingResult ?? []}
							dates={weekDays}
						/>
					) : (
						<PatientTableRow
							kpi={kpi}
							key={kpi}
							values={allLogsByKpi[kpi]}
							dates={weekDays}
							setOpenGallery={setOpenGallery}
							setShowDiagnosticReport={setShowDiagnosticReport}
						/>
					)
			});
		});

		return rows.sort((a, b) => a.order - b.order);
	}, [
		activeProcedure?.assignedKPIs?.monitoring,
		activeProcedure?.loggingData?.medicationHistoryResult,
		activeProcedure?.loggingData?.medicationLoggingResult,
		allLogsByKpi,
		setOpenGallery,
		setShowDiagnosticReport,
		weekDays
	]);

	const rowsToShow = useMemo(() => {
		return isExpandedView ? allRows : allRows.slice(0, 4);
	}, [isExpandedView, allRows]);

	if (weekDays.length !== 7) return <></>;

	return (
		<TableContainer component={Paper} sx={{ marginTop: '16px', paddingTop: '32px', width: '100%' }}>
			<Table sx={{ minWidth: 650 }} size="large" style={{ tableLayout: 'fixed' }}>
				{/* DAYS HEADER ROW */}
				<TableHead>
					<TableRow>
						<TableCell sx={{ position: 'relative', width: '240px' }}>
							<IconButton
								onClick={() => shiftDate(-1)}
								sx={{
									position: 'absolute',
									right: '0',
									top: '50%',
									transform: 'translateY(-50%)'
								}}>
								<ArrowBackIosIcon />
							</IconButton>
						</TableCell>

						{weekDays.map((d) => (
							<PatientTableDayHeadCell key={`days-${d}`} date={d} />
						))}

						<TableCell onClick={() => shiftDate(1)} sx={{ position: 'relative', width: '48px' }}>
							<IconButton
								sx={{
									position: 'absolute',
									left: '0',
									top: '50%',
									transform: 'translateY(-50%)'
								}}>
								<ArrowForwardIosIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{rowsToShow.map((r) => r.row)}</TableBody>
			</Table>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					padding: 2
				}}>
				<Button
					sx={{
						color: '#1db14c',
						'&:hover': {
							color: '#409955'
						}
					}}
					variant="text"
					onClick={() => setIsExpandedView((v) => !v)}>
					{isExpandedView ? (
						<>
							Collapse <ExpandLessIcon />
						</>
					) : (
						<>
							Expand <ExpandMoreIcon />
						</>
					)}
				</Button>
			</Box>
		</TableContainer>
	);
};

const headBoxesStyle = {
	height: '56px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between'
};

const PatientHistory = () => {
	const [isLoadingPatient, setIsLoadingPatient] = useState(true);
	const [isLoadingProcedure, setIsLoadingProcedure] = useState(true);
	const [userProfile, setUserProfile] = useState({});
	const [patientHistory, setpatientHistory] = useState([]);
	const [selectedProcedure, setSelectedProcedure] = useState(-1);
	const [selectedProcedureData, setSelectedProcedureData] = useState({});
	const [userProfilePic, setUserProfilePic] = useState('');

	const [showDiagnosticReport, setShowDiagnosticReport] = useState(undefined);

	const [openGallery, setOpenGallery] = useState(false);
	const [images, setImages] = useState([]);

	const params = useParams();
	const childRef = useRef(null);
	const alertRef = useRef(null);

	const selectedProcedureName = useMemo(() => {
		const activeProcedure = patientHistory.find((item) => item.procedure_id === selectedProcedure);
		return activeProcedure?.procedure_type ?? 'none';
	}, [patientHistory, selectedProcedure]);

	const selectedProcedureDate = useMemo(() => {
		const activeProcedure = patientHistory.find((item) => item.procedure_id === selectedProcedure);
		if (!activeProcedure || !activeProcedure.procedure_date_time) return 'N/A';

		const activeProcedureDate = new Date(activeProcedure.procedure_date_time);
		return `${
			activeProcedureDate.getMonth() + 1
		} - ${activeProcedureDate.getDate()} -  ${activeProcedureDate.getFullYear()}`;
	}, [patientHistory, selectedProcedure]);

	const getPatientName = useMemo(
		() => (userProfile ? `${userProfile.fname} ${userProfile.mname} ${userProfile.lname}` : '...'),
		[userProfile]
	);

	const getPatientPhoneNumber = useMemo(
		() => (userProfile ? `${userProfile.phone}` : '...'),
		[userProfile]
	);

	const getPatientDob = useMemo(() => {
		if (!userProfile || !userProfile.dob) return 'xx - xx - xxxx';
		const dob = new Date(userProfile.dob);
		return `${dob.getMonth() + 1} - ${dob.getDate()}  - ${dob.getFullYear()}`;
	}, [userProfile]);

	const getSelectedProcedureMainDoctor = useMemo(() => {
		const activeProcedure = patientHistory.find((item) => item.procedure_id === selectedProcedure);
		if (!activeProcedure || !activeProcedure.doctorData) return 'N/A';

		return `${activeProcedure.doctorData.fname} ${activeProcedure.doctorData.mname} ${activeProcedure.doctorData.lname}`;
	}, [patientHistory, selectedProcedure]);

	//on mount:
	useEffect(() => {
		const controller = new AbortController();

		const getProfile = async () => {
			const controller = new AbortController();
			const getProfileParams = { resource: 'user', action: 'getProfileByUserId' };
			const getProfileData = await getList(
				getProfileParams,
				{
					userId: params.userId,
					role: 'patient'
				},
				controller.signal
			);
			setUserProfile(getProfileData.data);
			setUserProfilePic(getProfileData.data?.profile_pic);
			return getProfileData;
		};

		const fetchData = async () => {
			//request patient profile info by User ID
			const getProfileData = await getProfile();

			//get patient history by patient id
			const getHistoryParams = { resource: 'procedure', action: 'getPatientHistory' };
			const getHistoryData = await getListPost(
				getHistoryParams,
				{ patient_id: getProfileData.data.profile_id },
				controller.signal
			);
			setpatientHistory(getHistoryData.data);

			//set active procedure and default selected
			const selected =
				getHistoryData.data.find((item) => item.procedure_is_active === 1)?.procedure_id ?? -1;
			setSelectedProcedure(selected);

			setIsLoadingPatient(false);
		};
		fetchData();

		return () => {
			controller.abort();
		};
	}, [params.userId]);

	//when selection changes
	useEffect(() => {
		const controller = new AbortController();
		const getLogging = async () => {
			if (selectedProcedure === -1) {
				return;
			}
			setIsLoadingProcedure(true);

			const tempHistory = [...patientHistory];
			const procedure = tempHistory.find((item) => item.procedure_id === selectedProcedure);

			//get logging data
			if (!procedure.loggingData) {
				const getLoggingParams = { resource: 'procedure', action: 'getLogging' };
				const getLoggingData = await getListPost(
					getLoggingParams,
					{
						procedure_id: selectedProcedure
					},
					controller.signal
				);
				procedure.loggingData = getLoggingData.data;
			}

			if (!procedure.assignedKPIs) {
				const getKPIsParams = { resource: 'procedure', action: 'getCurrentAssignmnetsBySurgery' };
				const getKPIsData = await getListPost(
					getKPIsParams,
					{
						procedure_id: selectedProcedure
					},
					controller.signal
				);
				procedure.assignedKPIs = getKPIsData.data;
			}

			//get doctor data
			if (!procedure.doctorData) {
				//find doctor ID
				const doctorId =
					procedure?.loggingData?.procedureSurgeonsResult?.find(
						(item) => item.pdt_interaction_type === 'main'
					)?.pdt_doctor_id ?? -1;
				const getProfileParams = { resource: 'user', action: 'getProfileByProfileId' };
				const getProfileData = await getList(
					getProfileParams,
					{
						profileId: doctorId,
						role: 'doctor'
					},
					controller.signal
				);
				procedure.doctorData = getProfileData.data;
			}

			setSelectedProcedureData(procedure);
			setImages(
				procedure.loggingData.loggingHistoryResult
					.filter((item) => {
						return item.pl_log_type === 'image_url';
					})
					.sort((a, b) => new Date(b.pl_update_ts) - new Date(a.pl_update_ts))
			);
			if (JSON.stringify(tempHistory) !== JSON.stringify(patientHistory))
				setpatientHistory(tempHistory);
			setIsLoadingProcedure(false);
		};
		getLogging();

		return () => {
			try {
				controller.abort();
			} catch (e) {}
		};
	}, [selectedProcedure, patientHistory]);

	const navigate = useNavigate();
	const handleAddProcedureClick = () => {
		navigate('/NewPatientSurgery', {
			// /NewSurgery
			state: {
				patient_id: userProfile.profile_id,
				patient_clinic_id: userProfile.clinic_id
			}
		});
	};
	const handleEditProcedureClick = () => {
		navigate('/EditSurgery', {
			// /EditSurgery
			state: {
				params: {
					patient_id: userProfile.profile_id,
					patient_clinic_id: userProfile.clinic_id,
					procedure_id: selectedProcedure,
					procedure_description: selectedProcedureData.procedure_description,
					procedure_code: selectedProcedureData.procedure_code,
					procedure_type: selectedProcedureData.procedure_type,
					procedure_date_time: selectedProcedureData.procedure_date_time,
					procedure_is_active: selectedProcedureData.procedure_is_active
				}
			}
		});
	};

	if (isLoadingPatient) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					height: '100%',
					width: '100%'
				}}>
				<CircularProgress />
			</Box>
		);
	}

	const handleChangeProfilePic = async (ev) => {
		try {
			const urlParams = { resource: 'user', action: 'getProfileUploadUrlGeneric' };
			const res = await post(urlParams, {
				user_id: params.userId
			});

			const url = res.data;
			const file = ev.target.files[0];

			if (!file) return;

			var instance = axios.create();
			instance
				.put(url, file)
				.then(async function (result) {
					if (result.status === 200) {
						childRef.current.handleClose();
						alertRef.current.handleClick({
							message: 'UPDATED! PAGE WILL REFRESH SOON!',
							severity: 'success'
						});
						setTimeout(function () {
							window.location.reload(false);
						}, 10000);
					}
				})
				.catch(function (err) {
					alertRef.current.handleClick({
						message: 'PHOTO NOT UPDATED!',
						severity: 'error'
					});
					console.log('catch', err);
				});
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Container maxWidth="xl" sx={{ minHeight: 'calc(100vh - 160px)', position: 'relative' }}>
			{/* WATERMARK */}
			{/* <Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundImage: `url(${LogoIcon})`,
					opacity: 0.1,
					backgroundRepeat: 'no-repeat',
					zIndex: 0,
					filter: 'grayscale(100%)',
					// backgroundSize: 'cover',

					backgroundSize: '70%',
					backgroundPosition: 'center'
				}}></Box> */}

			<CustomizedAlertBar ref={alertRef} />
			<ImageGallery
				open={openGallery}
				setOpen={setOpenGallery}
				images={images}
				surgeryInfo={{
					surgery_name: selectedProcedureName,
					surgery_date: selectedProcedureDate
				}}
			/>

			{showDiagnosticReport && (
				<PatientDiagnosticReport
					closeReport={() => setShowDiagnosticReport(undefined)}
					day={showDiagnosticReport.day}
					predictionId={showDiagnosticReport.predictionId}
					result={showDiagnosticReport.result}
					imgKey={showDiagnosticReport.imgKey}
					procedureId={selectedProcedure}
				/>
			)}

			{/* HEAD */}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginTop: '16px'
				}}>
				<Box sx={headBoxesStyle}>
					<ExpandingAvatar
						ref={childRef}
						height={56}
						width={56}
						image_thumb={userProfilePic}
						image_url={userProfilePic}
						onChange={handleChangeProfilePic}
					/>
				</Box>

				<Box sx={headBoxesStyle}>
					<Typography sx={{ fontSize: '16px', fontWeight: '500' }}>{getPatientName}</Typography>
					<Typography sx={{ fontSize: '14px', fontWeight: '300' }}>{getPatientDob}</Typography>
				</Box>

				<Box sx={headBoxesStyle}>
					<Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Phone Number</Typography>
					<Typography sx={{ fontSize: '14px', fontWeight: '300' }}>
						{getPatientPhoneNumber}
					</Typography>
				</Box>

				<Box sx={headBoxesStyle}>
					<Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
						{selectedProcedureName}
					</Typography>
					<Typography sx={{ fontSize: '14px', fontWeight: '300' }}>
						{selectedProcedureDate}
					</Typography>
				</Box>

				<Box sx={headBoxesStyle}>
					<Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Surgeon / Staff</Typography>
					<Typography sx={{ fontSize: '14px', fontWeight: '300' }}>
						{getSelectedProcedureMainDoctor}
					</Typography>
				</Box>

				{/* <Box sx={headBoxesStyle}>
					<Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Temperature unit</Typography>
					<Typography sx={{ fontSize: '14px', fontWeight: '300' }}>Celsius</Typography>
				</Box> */}

				<Box sx={{ display: 'flex', flexDirection: 'row!important', height: '56px' }}>
					<Box sx={headBoxesStyle}>
						<Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Surgeries</Typography>

						<FormControl variant="standard" sx={{ minWidth: 100 }}>
							<Select
								value={selectedProcedure}
								onChange={(e) => setSelectedProcedure(e.target.value)}>
								{patientHistory.length === 0 ? (
									<MenuItem value={-1}>
										<em>None</em>
									</MenuItem>
								) : null}
								{patientHistory.map((item, index) => (
									<MenuItem value={item.procedure_id} key={item.procedure_id}>
										Surgery {index + 1} {item.procedure_is_active === 1 ? '(active)' : ''}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</Box>
				<Box>
					<Box>
						<IconButton
							size="small"
							sx={{
								color: 'white',
								backgroundColor: '#1db14c',
								'&:hover': {
									backgroundColor: '#409955'
								}
							}}
							onClick={handleEditProcedureClick}
							disabled={selectedProcedure === -1 ? true : false}>
							<EditIcon fontSize="large" />
						</IconButton>
					</Box>
				</Box>
				<Box>
					<Box>
						<IconButton
							size="small"
							sx={{
								color: 'white',
								backgroundColor: '#1db14c',
								'&:hover': {
									backgroundColor: '#409955'
								}
							}}
							onClick={handleAddProcedureClick}>
							<AddIcon fontSize="large" />
						</IconButton>
					</Box>
				</Box>
			</Box>

			{/* TABLE */}
			{!patientHistory || !patientHistory.length || selectedProcedure === -1 ? (
				''
			) : isLoadingProcedure ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						height: '100%',
						width: '100%'
					}}>
					<CircularProgress />
				</Box>
			) : (
				<PatientTable
					setShowDiagnosticReport={setShowDiagnosticReport}
					setOpenGallery={setOpenGallery}
					activeProcedure={patientHistory.find((item) => item.procedure_id === selectedProcedure)}
				/>
			)}
		</Container>
	);
};

export default PatientHistory;
