import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { InputAdornment, TextField } from '@mui/material';
import MuiPhoneNumber from 'mui-phone-number';
// import VisibilityIcon from '@mui/icons-material/Visibility';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const SignInTabPanel = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={
					{
						// borderBottom: 1,
						// borderColor: 'divider'
					}
				}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
					<Tab label="Email" {...a11yProps(0)} />
					<Tab label="Phone Number" {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					autoFocus
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					id="emailPassword"
					name="emailPassword"
					label="Password"
					type="password"
					autoComplete="current-email-password"
					// InputProps={{
					// 	endAdornment: <InputAdornment position="end">{showPassword ? <Visibility /> : <VisibilityOff />}</InputAdornment>,
					//   }}
				/>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<MuiPhoneNumber
					margin="normal"
					required
					fullWidth
					id="phoneNumber"
					label="Phone Number"
					name="phoneNumber"
					autoComplete="phone-number"
					defaultCountry={'lb'}
					variant="outlined"
					autoFocus
					// onChange={handleOnChange}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					name="phonePassword"
					label="Password"
					type="password"
					id="phonePassword"
					autoComplete="current-phone-password"
				/>
			</TabPanel>
		</Box>
	);
};

export default SignInTabPanel;
