import React from 'react';
import ListGrid from '../../components/customDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import { useLanguage } from '../../context/LanguageProvider';

const Surgery = () => {
	const navigate = useNavigate();
	const { tokenData } = useAuth();
	const { getLangString } = useLanguage();
	const isAdmin = tokenData?.role === 'admin';

	const editUser = (params) => () => {
		navigate('/EditSurgery', { state: { params: params.row } });
	};

	// const deleteUser = React.useCallback(id) => () => {
	// 		// setTimeout(() => {
	// 		// 	setRows((prevRows) => prevRows.filter((row) => row.id !== id));
	// 		// });
	// 	},
	// 	[]
	// 	();

	const columns = [
		{ field: 'procedure_id', headerName: getLangString('id'), flex: 1, minWidth: 40 },
		{ field: 'patient_clinic_id', headerName: getLangString('clinic_id'), flex: 1, minWidth: 40 },
		{
			field: 'patient_full_name',
			headerName: getLangString('patient_name'),
			flex: 2,
			minWidth: 230
		},
		{
			field: 'procedure_type',
			headerName: getLangString('procedure_type'),
			flex: 2,
			minWidth: 200
		},
		{
			field: 'procedure_model_type',
			headerName: getLangString('model_type'),
			flex: 2,
			minWidth: 200
		},
		{
			field: 'procedure_date_time',
			headerName: getLangString('procedure_date'),
			description: 'Date of Procedure',
			flex: 2,
			minWidth: 160,
			valueGetter: (params) => {
				const dateParts = params.value.split('T')[0].split('-');
				const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
				return formattedDate;
			}
		},
		{
			field: 'procedure_insert_ts',
			headerName: getLangString('procedure_creation'),
			description: 'Creation of Procedure',
			flex: 2,
			minWidth: 160,
			valueGetter: (params) => {
				const dateParts = params.value.split('T')[0].split('-');
				const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
				return formattedDate;
			}
		},
		{
			field: 'actions',
			headerName: getLangString('action'),
			type: 'actions',
			minWidth: 150,
			getActions: (params) => {
				return [
					<GridActionsCellItem
						icon={<ModeEditOutlineOutlinedIcon />}
						label="Edit"
						onClick={editUser(params)}
					/>,
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Delete"
						// onClick={deleteUser(params.id)}
					/>
				];
			}
		}
	];
	const rowID = (row) => row.procedure_id;
	const urlParams = { resource: 'procedure', action: 'getAllProcedures' };
	const createPage = {
		buttonText: `+ ${getLangString('add_new_procedure')}`,
		createPagePath: '/NewPatientSurgery'
	};
	const columnVisibilityModel = {
		patient_clinic_id: false,
		procedure_model_type: false,
		procedure_id: false,
		doctor_clinic_id: false,
		doctor_user_id: false,
		doctor_mname: false,
		doctor_dob: false,
		doctor_address: false
	};
	const page = 'surgeryList';
	return (
		<>
			<ListGrid
				rowID={rowID}
				columns={columns}
				urlParams={urlParams}
				createPage={createPage}
				pageName={page}
				columnVisibilityModel={columnVisibilityModel}
			/>
		</>
	);
};

export default Surgery;
