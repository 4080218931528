import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Typography
} from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { getArrayBuffer, post } from '../../services/services';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const PatientDiagnosticReport = ({
	closeReport,
	procedureId,
	day,
	predictionId,
	imgKey,
	result
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [comment, setComment] = useState('');

	const date = useMemo(() => {
		const d = new Date(day);
		return d.toDateString();
	}, [day]);

	const [report, setReport] = useState(undefined);
	const [oldFeedback, setOldFeedback] = useState(undefined);
	const [feedbackReloader, setFeedbackReloader] = useState(0);

	useEffect(() => {
		//get patient report
		(async () => {
			setIsLoading(true);
			const response = await getArrayBuffer(
				{
					resource: 'reporting',
					action: 'generatePatientReport__NEWTABLE'
				},
				{
					procedureId,
					date: Math.round(Date.parse(day) / 1000)
				}
			);
			const blob = new Blob([response.data], { type: 'application/pdf' });
			const url = URL.createObjectURL(blob);

			if (imgKey) {
				//get current predictions
				const urlParams = { resource: 'procedure', action: 'getPredictionFeedbackByDoctorId' };
				const preds = await post(urlParams, {
					imageKey: imgKey
				});
				preds.data.length && setOldFeedback(preds.data[0]);
			}

			setReport(url);
			setIsLoading(false);
			return;
		})();
	}, [day, procedureId, imgKey, feedbackReloader]);

	const handleFeedbackReport = async (feedback) => {
		setIsLoading(true);
		const urlParams = { resource: 'log', action: 'predictionfeedback' };
		await post(urlParams, {
			prediction_id: predictionId,
			feedback,
			comment
		});
		setFeedbackReloader((p) => p + 1);
		setComment('');
		setIsLoading(false);
	};

	if (isLoading)
		return (
			<Backdrop sx={{ color: '#fff', zIndex: 999 }} open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
		);

	return (
		<Dialog fullWidth maxWidth="lg" open={true} onClose={closeReport}>
			<DialogTitle>Prediction Report for: {date} </DialogTitle>
			<IconButton
				aria-label="close"
				onClick={closeReport}
				sx={{
					position: 'absolute',
					right: '16px',
					top: '16px',
					color: (theme) => theme.palette.grey[500]
				}}>
				<CloseIcon />
			</IconButton>
			<DialogContent
				sx={{ minHeight: '500px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
				{result === 'face_not_found' && <Typography color="error">Image is unclear</Typography>}

				{!!report && <iframe title="PDF Viewer" src={report} width="100%" height="500px" />}

				{oldFeedback !== undefined && (
					<Typography sx={{ textAlign: 'center' }}>
						Your feedback:{' '}
						<span style={{ fontWeight: 600 }}>
							{oldFeedback.feedback}{' '}
							{oldFeedback.comment ? ` - ${oldFeedback.comment}` : ' - no comment'}
						</span>
					</Typography>
				)}

				{/* dont show feeedback form if no prediction, already has feedback, or unclear image */}
				{!!predictionId && !oldFeedback && result !== 'face_not_found' && (
					<>
						<Typography>
							Do you agree or disagree with MyHealthPal's prediction? Share your thoughts on
							surgical incision erythema, exudate, swelling, opening, or other characteristic that
							you notice.
						</Typography>
						<TextField
							fullWidth
							label="Comment"
							variant="outlined"
							value={comment}
							onChange={(e) => setComment(e.target.value)}
						/>

						<Box sx={{ display: 'flex', gap: '32px', justifyContent: 'center' }}>
							<Button
								variant="contained"
								color="success"
								onClick={() => handleFeedbackReport('agree')}>
								Agree
							</Button>
							<Button
								variant="contained"
								color="error"
								onClick={() => handleFeedbackReport('disagree')}>
								Disagree
							</Button>
						</Box>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};
