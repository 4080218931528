import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { MenuListItems } from './MenuListItems';
import AppBar from './AppBar';
import Drawer from './Drawer';
import Copyright from './CopyRight';
import logo from '../assets/logo.png';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useAuth } from '../context/AuthProvider';
import LanguageSelector from '../components/LanguageSelector';
import NotificationList from './../components/dailogs/notifications';
import { create } from '../services/services';
import { Avatar, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const mdTheme = createTheme();

function Dashboard({ children }) {
	const navigate = useNavigate();
	const { profile, tokenData, logout } = useAuth();
	const isAdmin = tokenData?.role === 'admin';
	const [openDrawer, setOpenDrawer] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const [notifications, setNotifications] = useState(null);
	const [invisible, setInvisible] = useState(true);
	const [profilePic, setProfilePic] = useState('');
	const [profileData, setProfileData] = useState({});

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : '';

	const fetchNotifications = async () => {
		const urlParams = { resource: 'log', action: 'GetNotificationsByUser' };
		let response = await create(urlParams, {
			page: 1,
			limit: 30
		});
		setNotifications(response.data.data);
	};

	useEffect(() => {
		setProfileData(JSON.parse(localStorage.getItem('profile')));
	}, []);

	useEffect(() => {
		if (Boolean(anchorEl) === true) fetchNotifications();
	}, [anchorEl]);

	useEffect(() => {
		const fetchData = async () => {
			if (!isAdmin) {
				const urlParams = { resource: 'user', action: 'getProfileDownloadUrl' };
				let response = await create(urlParams);
				setProfilePic(response.data.data[0].profile_pic);
			}

			const urlParams = { resource: 'log', action: 'CheckUnseenNotification' };
			let response = await create(urlParams, {
				user_id: profile.profile?.user_id
			});

			if (response.data.data > 0) {
				setInvisible(false);
				fetchNotifications();
			}
		};
		fetchData().catch(console.error);
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setInvisible(true);
	};

	const toggleDrawer = () => {
		setOpenDrawer(!openDrawer);
	};

	return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar position="absolute" open={openDrawer}>
					<Toolbar
						sx={{
							pr: '24px', // keep right padding when drawer closed
							backgroundColor: 'white',
							color: '#1db14c'
						}}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer}
							sx={{
								marginRight: '36px',
								...(openDrawer && { display: 'none' })
							}}>
							<MenuIcon />
						</IconButton>
						<Box display="flex" flex="1">
							<TextField
								size="small"
								placeholder="Search"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<IconButton>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
							<Box flex="1"></Box>
							{!isAdmin && (
								<Button
									sentencecase="true"
									onClick={() => {
										navigate('/EditProvider', {
											// state: { params: JSON.parse(localStorage.getItem('profile')) }
											state: { params: { isEditMyProfile: true, profile_pic: profilePic } }
										});
									}}
									sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'black' }}>
									<Avatar
										alt={profile.profile?.fname}
										src={profilePic}
										sx={{ width: 40, height: 40 }}></Avatar>

									<Typography sx={{ textTransform: 'none' }}>
										{profileData?.fname?.charAt(0)?.toUpperCase() +
											profileData?.fname?.slice(1)?.toLowerCase()}{' '}
										{profileData?.lname?.charAt(0)?.toUpperCase() +
											profileData?.lname?.slice(1)?.toLowerCase()}
									</Typography>
								</Button>
							)}
							<IconButton aria-describedby={id} color="inherit" onClick={handleClick}>
								<Badge variant={'dot'} color="secondary" invisible={invisible}>
									<NotificationsIcon sx={{ fontSize: 27 }} />
								</Badge>
							</IconButton>
							<NotificationList
								id={id}
								open={open}
								anchorEl={anchorEl}
								setAnchorEl={setAnchorEl}
								notificationList={notifications}
							/>
							<IconButton onClick={logout} color="inherit">
								<Badge color="secondary">
									<LogoutIcon sx={{ fontSize: 27 }} />
								</Badge>
							</IconButton>
						</Box>
					</Toolbar>
				</AppBar>
				<Drawer variant="permanent" sx={{ backgroundColor: '#1db14c' }} open={openDrawer}>
					<Toolbar
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							px: [1],
							backgroundColor: 'white'
						}}>
						<img height={30} src={logo} alt="My Health Pal" />
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider />
					<MenuListItems />
					<Divider />
					<LanguageSelector />
				</Drawer>
				<Box
					component="main"
					sx={{
						backgroundColor: (theme) =>
							theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto'
					}}>
					<Toolbar />
					<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
						<Grid container spacing={3}>
							{children}
						</Grid>
						<Copyright align="center" sx={{ pt: 4 }} />
					</Container>
				</Box>
			</Box>
		</ThemeProvider>
	);
}

export default Dashboard;
