import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import BloodtypeOutlinedIcon from '@mui/icons-material/BloodtypeOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../context/LanguageProvider';

export const MenuListItems = (props) => {
	const { tokenData } = useAuth();
	const location = useLocation();
	const { getLangString } = useLanguage();

	const isAdmin = tokenData?.role === 'admin';

	const ListItemButtonSx = {
		'& .MuiButtonBase-root.Mui-selected': {
			backgroundColor: 'rgb(9, 217, 64)'
		},
		'& .MuiButtonBase-root:hover': {
			backgroundColor: '#409955'
		},
		height: 'calc(100vh - 70px);',
		overflow: 'auto'
	};

	return (
		<List component="nav" aria-label="main menu" sx={ListItemButtonSx}>
			<ListItemButton component={Link} to="/Patient" selected={location.pathname === '/Patient'}>
				<ListItemIcon>
					<BloodtypeOutlinedIcon />
				</ListItemIcon>
				<ListItemText primary={getLangString('menu_list')?.patient} />
			</ListItemButton>
			<ListItemButton component={Link} to="/Surgery" selected={location.pathname === '/Surgery'}>
				<ListItemIcon>
					<VaccinesOutlinedIcon />
				</ListItemIcon>
				<ListItemText primary={getLangString('menu_list')?.surgery} />
			</ListItemButton>
			<ListItemButton
				component={Link}
				to="/SurgeryTemplate"
				selected={location.pathname === '/SurgeryTemplate'}>
				<ListItemIcon>
					<MedicalInformationOutlinedIcon />
				</ListItemIcon>
				<ListItemText primary={getLangString('menu_list')?.surgery_template} />
			</ListItemButton>
			<ListItemButton component={Link} to="/Provider" selected={location.pathname === '/Provider'}>
				<ListItemIcon>
					<PeopleOutlineOutlinedIcon />
				</ListItemIcon>
				<ListItemText primary={getLangString('menu_list')?.provider} />
			</ListItemButton>
			{/* <ListItemButton
				component={Link}
				to="/Prediction"
				selected={location.pathname === '/Prediction'}>
				<ListItemIcon>
					<BarChartOutlinedIcon />
				</ListItemIcon>
				<ListItemText primary={getLangString('menu_list')?.prediction} />
			</ListItemButton> */}
			<ListItemButton
				component={Link}
				to="/Reporting"
				selected={location.pathname === '/Reporting'}>
				<ListItemIcon>
					<QueryStatsOutlinedIcon />
				</ListItemIcon>
				<ListItemText primary={getLangString('menu_list')?.reporting} />
			</ListItemButton>

			{isAdmin && (
				<ListItemButton component={Link} to="/Clinic" selected={location.pathname === '/Clinic'}>
					<ListItemIcon>
						<LocalHospitalOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={getLangString('menu_list')?.clinic} />
				</ListItemButton>
			)}
			{isAdmin && (
				<ListItemButton
					component={Link}
					to="/BulkDoctors"
					selected={location.pathname === '/BulkDoctors'}>
					<ListItemIcon>
						<CreateNewFolderOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={getLangString('menu_list')?.add_bulk_doctors} />
				</ListItemButton>
			)}
			{isAdmin && (
				<ListItemButton
					component={Link}
					to="/BulkPatients"
					selected={location.pathname === '/BulkPatients'}>
					<ListItemIcon>
						<CreateNewFolderOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={getLangString('menu_list')?.add_bulk_patients} />
				</ListItemButton>
			)}
			{isAdmin && (
				<ListItemButton component={Link} to="/Epic" selected={location.pathname === '/Epic'}>
					<ListItemIcon>
						<ArticleIcon />
					</ListItemIcon>
					<ListItemText primary={getLangString('menu_list')?.emr} />
				</ListItemButton>
			)}
			{isAdmin && (
				<ListItemButton
					component={Link}
					to="/Integration"
					selected={location.pathname === '/Integration'}>
					<ListItemIcon>
						<IntegrationInstructionsOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={getLangString('menu_list')?.integration} />
				</ListItemButton>
			)}
			<ListItemButton
				component={Link}
				to="/ContactUs"
				selected={location.pathname === '/ContactUs'}>
				<ListItemIcon>
					<ForumOutlinedIcon />
				</ListItemIcon>
				<ListItemText primary={getLangString('menu_list')?.contact_us} />
			</ListItemButton>
		</List>
	);
};
