import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/logo.png';

import { useAuth } from '../context/AuthProvider';
import { Paper } from '@mui/material';
import { useLanguage } from '../context/LanguageProvider';
import SignInTabPanel from './SignInTabPanel';
import { isPasswordValid } from '../helpers/customFunctions';
import { post } from '../services/services';
import PasswordRequirements from './passwordRequirements';

const theme = createTheme();

export default function SignIn() {
	const { getLangString } = useLanguage();

	let { login } = useAuth();
	const [error, setError] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const [isNewPasswordRequired, setIsNewPasswordRequired] = React.useState(false);

	// Password for Login
	const [username, set_username] = React.useState('');
	const [password_form, set_password_form] = React.useState('');

	// Passwords for first login
	const [password, set_password] = React.useState('');
	const [confirm_password, set_confirm_password] = React.useState('');

	const setErrorMsg = (msg) => {
		console.log(msg);
		setError(msg);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setErrorMsg('');
		const data = new FormData(event.currentTarget);
		const email = data.get('email');
		const emailPassword = data.get('emailPassword');
		const phoneNumber = data.get('phoneNumber');
		const phonePassword = data.get('phonePassword');
		set_username(email ?? phoneNumber);
		set_password_form(emailPassword ?? phonePassword);

		setIsLoading(true);
		try {
			if (email) var emailSignIn = await login({ email, password: emailPassword });
			else var phoneSignIn = await login({ email: phoneNumber, password: phonePassword });
		} catch (e) {
			if (e === 'newPasswordRequired') setIsNewPasswordRequired(true);
			else setErrorMsg(e);
		}
		setIsLoading(false);
	};

	const handleSubmitNewPasssword = async (event) => {
		event.preventDefault();
		setErrorMsg('');
		const data = new FormData(event.currentTarget);

		const new_password = data.get('password');
		const confirmpassword = data.get('confirmpassword');

		if (!isPasswordValid(new_password)) return setErrorMsg(getLangString('password_policy'));
		if (new_password !== confirmpassword) return setErrorMsg(`Passwords did't match`);

		setIsLoading(true);
		try {
			const response = await login({
				email: username,
				password: password_form,
				newPassword: password
			});
		} catch (e) {
			if (e === 'newPasswordRequired') setIsNewPasswordRequired(true);
			else setErrorMsg(e);
		}
		setIsLoading(false);
	};

	// {
	// 	"email": " hadiw.ehbi@gmail.com",
	// 	"password": "Test1234!"
	// }
	// {"fail":{"code":"newPasswordRequired","message":"your password has expired"}}
	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					height: '100vh',
					backgroundColor: (theme) =>
						theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]
				}}>
				<CssBaseline />
				<img height={70} src={logo} alt="My Health Pal" />
				<Container component="main" maxWidth="sm">
					<Paper>
						<Box
							sx={{
								marginTop: 2,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								padding: '30px'
							}}>
							{!isNewPasswordRequired ? (
								<>
									<Typography component="h1" variant="h6">
										{getLangString('sign_in_title')}
									</Typography>

									<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
										<SignInTabPanel />
										{/* <TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address / Phone Number"
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="current-password"
								/> */}

										<Box display="flex" alignItems="center" justifyContent="space-between">
											<FormControlLabel
												control={<Checkbox value="remember" color="primary" />}
												label={getLangString('remember_me')}
											/>
											<Link href="#" variant="body1">
												{getLangString('forgot_password')}?
											</Link>
										</Box>
										<Button
											disabled={isLoading}
											type="submit"
											fullWidth
											variant="contained"
											size="large"
											sx={{ mt: 3, mb: 2, backgroundColor: '#1db14c' }}>
											{getLangString('sign_in')}
										</Button>

										<Typography component="h5" variant="h5" color={'error'}>
											{error}
										</Typography>
									</Box>
								</>
							) : (
								<Box
									id="newPassForm"
									component="form"
									onSubmit={handleSubmitNewPasssword}
									noValidate
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										gap: 3,
										minWidth: 400
									}}>
									<Typography component="h1" variant="h6" sx={{ alignSelf: 'center' }}>
										{/* {getLangString('sign_in_title')}  */}
										Set New Password
									</Typography>
									<TextField
										required
										id="password"
										name="password"
										label={getLangString('password')}
										fullWidth
										variant="outlined"
										value={password}
										onChange={(e) => set_password(e.target.value)}
									/>
									<TextField
										required
										id="confirmpassword"
										name="confirmpassword"
										label={getLangString('confirm_password')}
										fullWidth
										variant="outlined"
										value={confirm_password}
										onChange={(e) => set_confirm_password(e.target.value)}
									/>

									<PasswordRequirements />

									<Button
										disabled={isLoading}
										form="newPassForm"
										type="submit"
										fullWidth
										variant="contained"
										size="large"
										sx={{ mt: 3, mb: 2, backgroundColor: '#1db14c' }}>
										{/* {getLangString('sign_in')} */}
										Submit
									</Button>

									<Typography variant="subtitle1" color={'error'} sx={{ alignSelf: 'center' }}>
										{error}
									</Typography>
								</Box>
							)}
						</Box>
					</Paper>
				</Container>
			</Box>
		</ThemeProvider>
	);
}
