import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Container, Paper } from '@mui/material';
import { getListPost } from '../../services/services';
import FileHandlerForm from '../../components/CsvFileHandler';
import { patient_columns } from './BulkDataArrays';

const BulkPatients = () => {
	const [loading, set_loading] = useState(true);
	const [clinics_Array, set_clinics_Array] = useState([]);

	const rowID_patient = (row) => row.patient_username;

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'clinic', action: 'getclinics' };
			let data = await getListPost(urlParams);
			set_clinics_Array(data.data);
			set_loading(false);
		};
		fetchData().catch(console.error);
	}, [clinics_Array]);

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c',
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 3 }
					}}>
					<FileHandlerForm
						title={'Add Bulk Patient'}
						columns={patient_columns}
						urlParams={{ resource: 'user', action: 'addBulkPatients' }}
						csv_columns={[
							'patient_username_isPhone',
							'patient_username', // username
							'patient_password',
							'patient_fname',
							'patient_mname',
							'patient_lname',
							'patient_dob',
							'patient_gender',
							'patient_phone',
							'patient_contact_email',
							'patient_city',
							'patient_state',
							'patient_country',
							'patient_zip_code',
							'patient_address2',
							'patient_brief'
						]}
						tb_user_columns={patient_columns}
						clinics_Array={clinics_Array}
						rowID={rowID_patient}
						tb_result_columns={[
							{
								field: 'patient_username',
								headerName: 'Username',
								width: 220
							},
							{ field: 'created', headerName: 'Created', type: 'boolean', width: 120 },
							{
								field: 'message',
								headerName: 'Message',
								flex: 1,
								minWidth: 200
							}
						]}
					/>
				</Paper>
			</Container>
		</>
	);
};

export default BulkPatients;
