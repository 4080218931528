import axios from '../helpers/API';
import { buildErrorMessage } from '../helpers/errorHandler';
import { API_ENDPOINT } from '../helpers/constants';

export const create = async (urlParams, params, queryParams) => {
	let { resource, action } = urlParams;
	try {
		const response = await axios.post(`${API_ENDPOINT}/api/${resource}/${action}`, params, {
			params: queryParams
		});
		return response;
	} catch (error) {
		throw new Error(buildErrorMessage(error));
	}
};

export const get = async (urlParams, params = {}) => {
	let { resource, action } = urlParams;
	try {
		const response = await axios.get(`${API_ENDPOINT}/api/${resource}/${action}`, {
			params: params
		});
		return response.data;
	} catch (error) {
		throw new Error(buildErrorMessage(error));
	}
};
export const getArrayBuffer = async (urlParams, params = {}) => {
	let { resource, action } = urlParams;
	try {
		const response = await axios.get(`${API_ENDPOINT}/api/${resource}/${action}`, {
			// responseType: 'blob',
			responseType: 'arraybuffer',
			params: params
		});
		return response;
	} catch (error) {
		throw new Error(buildErrorMessage(error));
	}
};

export const post = async (urlParams, params = {}) => {
	let { resource, action } = urlParams;
	try {
		const response = await axios.post(`${API_ENDPOINT}/api/${resource}/${action}`, params);
		return response.data;
	} catch (error) {
		throw new Error(buildErrorMessage(error));
	}
};

export const getList = async (urlParams, params = {}, signal) => {
	let { resource, action } = urlParams;
	try {
		const response = await axios.get(`${API_ENDPOINT}/api/${resource}/${action}`, {
			params,
			signal
		});
		return response.data;
	} catch (error) {
		throw new Error(buildErrorMessage(error));
	}
};

export const getListPost = async (urlParams, params = {}, signal = null) => {
	let { resource, action } = urlParams;
	try {
		const response = await axios.post(`${API_ENDPOINT}/api/${resource}/${action}`, params, {
			signal
		});
		return response.data;
	} catch (error) {
		throw new Error(buildErrorMessage(error));
	}
};

export const update = async (urlParams, params) => {
	let { resource, action } = urlParams;
	try {
		const response = await axios.put(`${API_ENDPOINT}/api/${resource}/${action}`, params);
		console.log('-----> response', response);
		return response;
	} catch (error) {
		throw new Error(buildErrorMessage(error));
	}
};
